<template>
    <div class="grid grid-cols-12 m-2">
        <div class="lg:col-span-4 col-span-12 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg ">
            
            <div class="mt-4">
              <label class="block text-TFL-dashText1 text-right font-iranSans">کیف پول </label>
              <input  v-model="wallet"   class="font-iranSans text-white text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 mt-2   focus:outline-none" required />
              <span class="text-xs text-justify font-normal leading-tight text-TFL-red1 ">آدرس وارد شده میبایست مطابق آدرس ثبت شده در بخش پروفایل باشد.</span>
            </div>
            <div class="mt-4">
              <label class="block text-TFL-dashText1 text-right font-iranSans">حساب معاملاتی  </label>
              <div class="relative  text-right">
                  
                  <select v-model="selectedAccount" class="font-iranSans text-xs w-full text-white px-4 py-3 text-right  rounded-lg bg-gray-600 bg-opacity-50 mt-2   focus:outline-none" id="grid-state">
                      <option  v-for="account in filteredAccounts" :key="account.id" :value="account.uId" class="text-white">{{ formatNumber(account.accountId) }}</option>
                  </select>
              </div>
                            
            </div>
            <div class="mt-4">
                <label class="block text-TFL-dashText1 text-right font-iranSans">سود کلی حساب </label>
                <div v-if="selectedAccount" class="font-iranSans  w-full px-4 py-3 text-center rounded-lg bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-purple-200 via-purple-400 to-TFL-base2  mt-2  text-white "> {{ formatNumber(balanceAfterDeposit.toFixed(2))}} $  </div>
                <div v-if="!selectedAccount" class="font-iranSans  w-full px-4 py-3 text-center rounded-lg bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-purple-200 via-purple-400 to-TFL-base2  mt-2  text-white ">{{ formatNumber(0) }}  $ </div>
            </div>
            <div class="mt-4">
                <label class="block text-TFL-dashText1 text-right font-iranSans">سود مجاز  </label>
                <div v-if="selectedAccount" class="font-iranSans  w-full px-4 py-3 text-center rounded-lg bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-purple-200 via-purple-400 to-TFL-base2  mt-2  text-white "> {{ formatNumber(allowedProfit.toFixed(2))}} $  </div>
                <div v-if="!selectedAccount" class="font-iranSans  w-full px-4 py-3 text-center rounded-lg bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-purple-200 via-purple-400 to-TFL-base2  mt-2  text-white ">{{ formatNumber(0) }}  $ </div>
            </div>
            
        </div>

        <div class="lg:col-span-8 text-justify col-span-12  bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
           
            <div class="flex items-center text-justify text-TFL-dashText2 space-x-4 my-2 mb-6 ">
                کاربر گرامی، در صورت وجود شرایط زیر برداشت سود از حساب امکان‌پذیر خواهد بود:
            </div>
            <ul role="list" class="space-y-5 my-3 mt-12">
                <li class="flex space-x-3 items-center">
                    <svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                    <span class="text-base text-justify font-normal leading-tight text-gray-300 "> حساب ریل شما در سود باشد.</span>
                </li>
                <li class="flex space-x-3 items-center">
                    <svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                    <span class="text-base text-justify font-normal leading-tight text-gray-300 "> حداقل {{formatNumber(30)}} روز از دریافت اولین حساب ریل  گذشته باشد (فقط در پلن چالش دو مرحله‌ای). </span>
                </li>
                <li class="flex space-x-3 items-center">
                    <svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                    <span class="text-base text-justify font-normal leading-tight text-gray-300 ">حداقل {{formatNumber(7)}} روز معاملاتی در پلن چالش دو مرحله‌ای و حداقل {{formatNumber(12)}} روز معاملاتی در پلن مستقیم ثبت شده باشد.</span>
                </li>
                <li class="flex space-x-3 items-center">
                    <svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                    <span class="text-base text-justify font-normal leading-tight text-gray-300 ">در {{formatNumber(14)}} و  {{formatNumber(28)}} ماه میلادی امکان ثبت درخواست وجود خواهد داشت.</span>
                </li>
                <li class="flex space-x-3 items-center">
                    <svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                    <span class="text-base text-justify font-normal leading-tight text-gray-300 ">احراز هویت شما تائید شده باشد.</span>
                </li>
                <li class="flex space-x-3 items-center">
                    <svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>
                    <span class="text-base text-justify font-normal leading-tight text-gray-300 ">آدرس ولت تتر بر روی شبکه ترون (USDTTRC20) در بخش پروفایل ثبت شده باشد.</span>
                </li>
            </ul>
            <div   class="font-iranSans text-justify  w-full space-x-4 my-6  px-4 py-2 text-right rounded-lg bg-TFL-base1 text-TFL-dashText2 bg-opacity-80   " >
                پس از واریز سود، حساب جدید به صورت خودکار به لیست حساب‌ها اضافه خواهدشد.
            </div>

        </div>
        <div  class="col-span-12 grid grid-cols-12 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
            <div class="lg:col-span-9 col-span-12">
                <div class="flex items-center text-justify space-x-4 my-2 mt-5 align-middle text-gray-300 ">
                    <div class="flex items-center mx-2">
                        <input id="default-checkbox" type="checkbox" v-model="isChecked" class="w-4 h-4  text-TFL-base1 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    
                    </div>
                    آدرس کیف پول وارد شده را تائید می‌کنم و در صورت اشتباه وارد کردن کیف پول مسئولیت تمامی عواقب را می‌پذیرم
                </div>          
            </div>
            <div  class="lg:col-span-3 col-span-12">
                <button v-if="!canWithdrawal" :disabled="!canWithdrawal" class="font-iranSans  duration-300 text-center w-full block   bg-gray-600  text-TFL-dashText1 text-white font-semibold rounded-lg px-4 py-2 my-2">
                             ثبت درخواست برداشت
                            
                </button>
                <button v-if="loadingResponse && canWithdrawal" :disabled="isButtonDisabled" @click.prevent="withdrawalRequest" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-TFL-base1 bg-TFL-base1  text-TFL-dashText2 text-white font-semibold rounded-lg px-4 py-2 my-2">
                             ثبت درخواست برداشت
                            
                </button>
                <button v-if="!loadingResponse"  class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-TFL-base1 bg-TFL-base1 focus:bg-TFL-base1 text-TFL-dashText1 text-white font-semibold rounded-lg px-4 py-3 my-2">
                    <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                            
                </button>

            </div>

        </div>
        
        
        <div class="col-span-12  overflow-x-auto bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
            <p class="mb-4 mt-2 text-TFL-dashText1">تاریخچه برداشت سود</p>
            <table class="w-full  text-xs xl:text-xs text-right text-gray-300 round-10 table-auto">
            <thead  class="text-sm text-gray-300 uppercase bg-gray-700  rounded-lg ">
              <tr>
                 <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                 <th scope="col" class="px-6 py-3 font-iranSans">تاریخ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">شماره حساب</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">کیف پول</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">وضعیت</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">توضیحات</th> 
                 
              </tr>
            </thead> 
            <tbody v-if="historyLoaded"  class="bg-gray-700 ">
              <tr  v-for="(item, index) in withdrawalList" :key="index" class="text-gray-300  hover:bg-gray-600">
                <td class="px-6 py-4">{{ formatNumber(index + 1 )}}</td>
                <td class="px-6 py-4">{{ formatNumber(item.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10)) }}</td>
                <td class="px-6 py-4">{{ formatNumber(item.accountId).replace(/,/g,'')	 }}</td>
                <td class="px-6 py-4">{{ formatNumber(item.payAddress) }}</td>
                <td class="px-6 py-4">{{ item.status }}</td>
                <td v-if="item.status == 'Finished'" class="px-6 py-4">{{ formatNumber(item.payHash) }}</td>
                
              </tr>
            </tbody>
          </table>
          <div v-if="!historyLoaded"  class="bg-gray-700 text-center border border-rounded-lg ">
                <tr class="text-gray-300  ">
                    <td class="px-6 py-2 text-sm " >در حال دریافت اطلاعات...</td>
                </tr>
            </div> 
            <div v-if="noHistory && historyLoaded" class="font-iranSans text-sm   space-x-4 my-2   px-2 py-2 text-right rounded-lg bg-gray-500 text-blue-200 bg-opacity-10  " >
               کاربر گرامی، در حال حاضر شما هیچ برداشت سودی ندارید.
            </div> 

        </div>
         
    </div>
    
    <div v-if="ErrorRequestModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card2  rounded-lg shadow sm:p-8 ">
                  
                              <div class="flex items-center text-TFL-red1 text-center space-x-4 my-2 ">  کاربر گرامی درخواست شما با خطا مواجه شده است</div>
                              
                                  
                  </div>
                                  
              <div class="bg-TFL-card2 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="showErrorRequestModal" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-800 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    
    
    </div>
    
    
    <div v-if="SuccessRequestModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card2  rounded-lg shadow sm:p-8 ">
                  
                              <div class="flex items-center text-TFL-green1 text-center space-x-4 my-2 ">  کاربر گرامی درخواست شما با موفقیت انجام شد و درخواست شما به لیست درخواست‌ها اضافه گردید.   </div>
                              
                                  
                  </div>
                                  
              <div class="bg-TFL-card2 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="showSuccessRequestModal" type="button" class="inline-flex w-full  justify-center rounded-md bg-TFL-base1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    
    
    </div>
    
</template>

<script>
import axios from 'axios';
import { digitsEnToFa } from 'persian-tools';
import URL from "../URL";
export default {
    data(){
        return{
            wallet: '',
            selectedAccount: '',
            accounts: [],
            isChecked: false,
            withdrawalList: [],
            SuccessRequestModal: false,
            ErrorRequestModal: false,

            loadingResponse: true,
            canWithdrawal: false,
            historyLoaded: false,
            noHistory: false,

            allowedProfit: 0,

        }
    },
    watch: {
    selectedAccount(newValue) {
      this.searchAndSaveCanWithdrawal(newValue);
      this.getProfit(newValue);
    }
  },
    mounted() {
    // Fetch the accounts data from the API and populate the accounts array
    this.fetchAccounts();
    this.fetchWithdrawalList();
    },
    methods: {
        getProfit(newValue) {
            const url = URL.baseUrl + `Meta/withdraw/limit/${newValue}`;
            axios.get(url, {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
        // Add the show variable to each account object
                this.allowedProfit = response.data;
            })
            .catch(error => {
                
            });
        },

        
            searchAndSaveCanWithdrawal(selectedAccount) {
          for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].uId === selectedAccount) {
              this.canWithdrawal = this.accounts[i].canWithdrawal;
              // Perform any actions you want with the canWithdrawal value
              
              break;
            }
          }
        },
        fetchAccounts(){
            const url = URL.baseUrl + `Meta/accounts`;
            axios.get(url, {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
        // Add the show variable to each account object
                this.accounts = response.data.map(account => {
                     return {
                    ...account,
                    showDetails: false,
                    showReset: false,
                    showExtend: false,
                    showNextPhase: false,
                    };
                });
            })
            .catch(error => {
                
            });
        },
        fetchWithdrawalList(){
            const url = URL.baseUrl + `Meta/Withdraw`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.withdrawalList = response.data;
                this.historyLoaded = true;
            })
            .catch(error => {
            this.errorMessage = error.message;
            this.historyLoaded = true;
            this.noHistory = true;
            
            });

        },
        withdrawalRequest(){
            this.loadingResponse = false;
            const url = URL.baseUrl + `Meta/Withdraw`;
            axios.post(url,
        { 
           
            
            amount: this.allowedProfit,
            payAddress: this.wallet,
            currency: 'usd',
            tradingAccountId: this.selectedAccount 
            },
                  {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }
            
        
        )
        .then(response => {
            // Handle successful response here
            
            this.showSuccessRequestModal();
            this.fetchWithdrawalList();
            this.noHistory = false;
            this.loadingResponse = true;
            
            
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            this.showErrorRequestModal();
            this.loadingResponse = true;
            });

        },
        showSuccessRequestModal(){
            this.SuccessRequestModal = !this.SuccessRequestModal;
        },
        showErrorRequestModal(){
            this.ErrorRequestModal = !this.ErrorRequestModal;
        },
        formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },



    },
    computed: {
  filteredAccounts() {
    return this.accounts.filter(account => ((account.type === 'Real' && account.state ==='Active') || (account.plan == 'Multiple')) );
  },
  balanceAfterDeposit() {
      const selectedAccount = this.accounts.find(account => account.uId === this.selectedAccount);
      if (selectedAccount) {
        return selectedAccount.balance - selectedAccount.actualDeposit ;
      }
      return null;
    },
    isButtonDisabled() {
        
      return !this.isChecked;
    },

}
}
</script>