<template>
    <div class="grid grid-cols-12 m-2 ">
        <div class="col-span-12 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg"> 
            <div>
  <h2 class="sr-only">Steps</h2>

  <div>
    <div v-if="data" class="overflow-hidden rounded-full bg-gray-600">
      <div v-if="!data.verified" class="h-2 w-1/2 rounded-full bg-TFL-base1"></div>
      <div v-if="data.verified" class="h-2 w-full rounded-full bg-TFL-base1"></div>
    </div>

    <ol class="mt-4 grid grid-cols-3 text-sm font-medium text-gray-500">
      <li class="flex items-center justify-start text-TFL-base1 sm:gap-1.5">
        <span class="hidden sm:inline"> ثبت نام </span>

        <svg
          class="h-6 w-6 sm:h-5 sm:w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
          />
        </svg>
      </li>

      <li class="flex items-center justify-center text-TFL-base1 sm:gap-1.5">
        <span class="hidden sm:inline"> تائید ایمیل </span>

        <svg
          class="h-6 w-6 sm:h-5 sm:w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </li>
     

      <li v-if="!data.verified" class="flex items-center justify-end sm:gap-1.5">
        <span class="hidden sm:inline">  احراز هویت </span>

        <svg
          class="h-6 w-6 sm:h-5 sm:w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
          />
        </svg>
      </li>
      <li v-if="data.verified" class="flex items-center justify-end text-TFL-base1 sm:gap-1.5">
        <span class="hidden sm:inline">  احراز هویت </span>

        <svg
          class="h-6 w-6 sm:h-5 sm:w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
          />
        </svg>
      </li>
    </ol>
  </div>
</div>
        </div>
       
        <div class="col-span-12 lg:col-span-4">
        <div v-if="showLastAccount && account" class=" grid grid-cols-5 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
            <p class="col-span-5 text-TFL-dashText1">آخرین اکانت </p>
            <div class="col-span-5 px-2">
                <div class="flex items-center space-x-4 my-2  ">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-TFL-dashText2 mx-2 truncate ">
                                         شماره حساب
                                    </p>
                                
                                </div>
                                <div v-if="account.accountId" class="inline-flex items-center  text-TFL-dashText2 ">
                                    {{formatNumber(this.account.accountId)}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                    </svg>


                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-TFL-dashText2 mx-2 truncate ">
                                          موجودی اولیه
                                    </p>
                                
                                </div>
                                <div v-if="account.deposit" class="inline-flex items-center  text-TFL-dashText2 ">
                                    {{formatNumber(this.account.deposit)}}
                                </div>
                            </div>
                            <hr class="h-px bg-gray-600 border-0 ">
                            <div class="flex items-center space-x-4 my-2">
                                <div class="flex-shrink-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-TFL-base1">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                    </svg>

                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm text-TFL-dashText2 mx-2 truncate ">
                                           وضعیت
                                    </p>
                                
                                </div>
                                <div v-if="account.state === 'Active'" class="inline-flex items-center bg-green-200 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded  ">
                                    
                                     فعال
                                </div>
                                <div v-if="account.state === 'Failed'" class="inline-flex items-center bg-red-100 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    مردود
                                </div>
                                <div v-if="account.state === 'ReviewNeeded'" class="inline-flex items-center bg-orange-300 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    بررسی کارشناس
                                </div>
                                <div v-if="account.state === 'Reseted'" class="inline-flex items-center bg-gray-400 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    ریست شده
                                </div>
                                <div v-if="account.state === 'Expired'" class="inline-flex items-center bg-red-100 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    منقضی شده
                                </div>
                                <div v-if="account.state === 'SucceedMinProfit'" class="inline-flex items-center bg-purple-500 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    تارگت سود
                                </div>
                                <div v-if="account.state === 'PendingNextPhase'" class="inline-flex items-center bg-purple-500 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    تارگت سود
                                </div>
                                <div v-if="account.state === 'Passed'" class="inline-flex items-center bg-blue-500 text-TFL-dashText3 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                    پاس
                                </div>
                                
                            </div>
    
            </div>
            <div  class="col-span-5 px-2">
                <router-link :to="`/Dashboard/Analysis/${this.goUID}`"   class="relative w-full inline-flex items-center justify-center px-2 py-2 my-2 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-TFL-base1 rounded-lg shadow-md group">
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 translate-x-full bg-TFL-base1 group-hover:translate-x-0 ease">
                                    <svg class="w-6 h-6 flip-right-to-left" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </span>
                                <span class="absolute flex items-center justify-center w-full h-full text-TFL-base1 transition-all duration-300 transform group-hover:translate-x-full ease"> آنالیز حساب</span>
                                <span class="relative invisible">Button Text</span>
                </router-link>
                
    
            </div>
        </div>
        <div class=" grid grid-cols-5  m-2 mt-4 ">
            <router-link to="/Dashboard/NewChallenge" class=" col-span-5 relative rounded px-10 py-5 text-center overflow-hidden shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg group bg-TFL-base1 relative hover:bg-gradient-to-r hover:from-TFL-base1 hover:to-TFL-base2 text-white hover:ring-2 hover:ring-offset-2 hover:ring-TFL-base2 transition-all ease-out duration-300">
                <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                <span class="relative"> شرکت در چالش جدید</span>
                </router-link>
        </div>
    </div>
    <div class="col-span-12 lg:col-span-8">

        <div class="h-80 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
  <div v-if="newsLoaded" class="h-64 ">
    <p class="text-TFL-dashText1">اخبار مهم</p>
    
    <div class="grid grid-cols-12 mt-2 bg-TFL-back1 text-sm text-TFL-dashText2 rounded-lg py-1">
      <div class="text-center self-center col-span-2">ساعت</div>
      <div class="text-center self-center col-span-2">ارز</div>
      <div class="text-center self-center col-span-4">عنوان</div>
      <div class="text-center self-center col-span-2">پیشبینی</div>
      <div class="text-center self-center col-span-2">قبلی</div>
    </div>
    <div class="overflow-y-auto max-h-[calc(100%-80px)]">

        <div v-if="news == ''"  class="font-iranSans text-sm  w-full space-x-4 my-1  px-2 py-1 text-right rounded-lg bg-gray-500 text-blue-200 bg-opacity-10   " >
           در این تاریخ اخبار با درجه اهمیت بالا وجود ندارد.
        </div>
        <div v-for="newsItem in news" :key="newsItem.id" class="grid grid-cols-12 text-sm text-TFL-dashText2 bg-gray-600 bg-opacity-40 hover:bg-gray-600 m-1  p-1  rounded-lg">
          <div class="text-center self-center col-span-2">{{ formatNumber(newsItem.date.split("at")[1]) }}</div>
          <div class="text-center self-center col-span-2">{{ newsItem.country }}</div>
          <div class="text-center self-center col-span-4">{{ newsItem.title }}</div>
          <div class="text-center self-center col-span-2">{{ formatNumber(newsItem.forecast) }}</div>
          <div class="text-center self-center col-span-2">{{ formatNumber(newsItem.previous) }}</div>
        </div>
    </div>
    
  </div>
  <div v-if="!newsLoaded" class="h-64 ">
    <p class="text-TFL-dashText1">اخبار مهم</p>
    <div role="status" class="max-w-sm animate-pulse">
    <div class="h-2.5 bg-gray-600 bg-opacity-80 rounded-full  w-48 mt-4 mb-4"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[360px] mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[330px] mb-2.5"></div>

    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[330px] mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[300px] mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[360px] mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[330px] mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[300px] mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  max-w-[360px]"></div>
    
</div>
  </div>
  <div class="flex justify-center  mt-auto">
    
   
    <nav aria-label="Page justify-self-center navigation example">
  <ul class="list-style-none flex">
    <li>
      <button @click="goToNextDay"  
        class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-TFL-base1 transition-all duration-300 hover:scale-110  "
        
        aria-label="Previous">
        <span aria-hidden="true">&laquo;</span>
    </button>
    </li>
    <li>
      <p
        class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-TFL-dashText1 transition-all duration-300  "
        href="#"
        >{{ formatNumber(selectedDate) }}</p
      >
    </li>
    
    <li>
      <button @click="goToPreviousDay"
        class="relative block rounded bg-transparent px-3 py-1.5 text-sm text-TFL-base1 transition-all duration-300 hover:scale-110   "
       
        aria-label="Next"
        ><span aria-hidden="true">&raquo;</span>
      </button>
    </li>
  </ul>
</nav>
  </div>
</div>
    </div>
    </div>
    
    
</template>

<script>
import axios from 'axios';
import URL from "../URL";
export default {
    data(){
        return{
            account:[],
            goUID:'',
            news: [],
            selectedDate: new Date().toISOString().slice(0, 10),
            showLastAccount: false,
            newsLoaded: false,
            data:[],
        }
    },
    computed: {
        currentDayNews() {
      const currentDate = this.selectedDate || new Date().toISOString().slice(0, 10);
      return this.news.filter(newsItem => newsItem.day === currentDate);
    }
  },
    mounted(){
        const currentDate = new Date().toISOString().slice(0, 10);
        this.fetchAccountsData();
        this.fetchNews(currentDate);
        this.checkToken();
    },
    methods: {
        formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
        fetchAccountsData(){
            const url = URL.baseUrl + 'Meta/accounts';
            axios.get(url, {
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => {
        if(response.data){

            this.account = response.data[0];
            this.goUID= this.account.uId;
            this.showLastAccount = true;
        }
        // Add the show variable to each account object
        
    })
    .catch(error => {
        
    });

        },
        fetchNews(date) {
            const encodedDate = date.replace(/\//g, '%2F');
            
            
            const url = URL.baseUrl + `Meta/news?date=${encodedDate}`;
            

      axios
        .get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => {

      this.newsLoaded = true;
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userTimezoneNews = response.data.map(item => {
    const utcDate = new Date(item.date);
    const options = {
      timeZone: userTimezone,
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    const localDate = utcDate.toLocaleString(undefined, options);
    return { ...item, date: localDate };
  });
  this.news = userTimezoneNews.reverse();
})
        .catch(error => {
          this.newsLoaded = true;
        });
    },
    goToNextDay() {
  const nextDay = new Date(this.selectedDate);
  nextDay.setDate(nextDay.getDate() + 1);
  const nextDayFormatted = nextDay.toISOString().slice(0, 10);
  this.fetchNews(nextDayFormatted);
  this.selectedDate = nextDayFormatted;
},
goToPreviousDay() {
  const previousDay = new Date(this.selectedDate);
  previousDay.setDate(previousDay.getDate() - 1);
  const previousDayFormatted = previousDay.toISOString().slice(0, 10);
  this.fetchNews(previousDayFormatted);
  this.selectedDate = previousDayFormatted;
},
checkToken(){
  const url = URL.baseUrl + `users/whoami`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         
         if(response.data.emailConfirmed == false){
            window.location.href = '/confirmEmail';

         }
         this.fullLoad = true;
         this.data = response.data;
          
          
        })
        .catch(error => {
          
          window.location.href = '/login';


        });
      },
    },

}
</script>



<style scoped>

</style>