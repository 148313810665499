import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vue from 'vue'

import VueNumber from 'vue-number-animation'
import RecaptchaEnterpriseClient from '@google-cloud/recaptcha-enterprise';
import dotenv from 'dotenv';







import './assets/tailwind.css'




createApp(App).use(router).use(VueNumber).mount('#app');

