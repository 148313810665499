<template>
  <div class="grid grid-cols-12 ">
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>مدیریت اکانت‌ها </p>
    </div>
    <div class="col-span-12 grid grid-cols-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">PageSize</label>
        <select v-model="PageSize" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value=10>10</option>
          <option value=20>20</option>
          <option value=30>30</option>
          <option value=50>50</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12 mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> UserId</label>
        <input v-model="UserId" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12 mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> AccountId</label>
        <input v-model="AccountId" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> Uid</label>
        <input v-model="Uid" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <!-- <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">Type</label>
        <select v-model="Type" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="DemoPhase1">DemoPhase1</option>
          <option value="DemoPhase2">DemoPhase2</option>
          <option value="Test">Test</option>
          <option value="Real">Real</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">Broker</label>
        <select v-model="Broker" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="Roboforex">Roboforex</option>
          <option value="Fxtm">Fxtm</option>
          <option value="Robomarkets">Robomarkets</option>
          <option value="ICMarkets">ICMarkets</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">Deposit</label>
        <select v-model="Deposit" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="K5">K5</option>
          <option value="K10">K10</option>
          <option value="K25">K25</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ActualDeposit</label>
        <input v-model="ActualDeposit" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">FailedBy</label>
        <select v-model="FailedBy" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="FailedByMaxDrawDown">FailedByMaxDrawDown</option>
          <option value="FailedByMinEquityDaily">FailedByMinEquityDaily</option>
          <option value="FailedByPositionDraWDown">FailedByPositionDraWDown</option>
          <option value="FailedByNotTradingTillExpired">FailedByNotTradingTillExpired</option>
          <option value="FailedByTradeOnWeekends">FailedByTradeOnWeekends</option>
          <option value="FailedByInvalidAccount">FailedByInvalidAccount</option>
          <option value="FailedByAdmin">FailedByAdmin</option>
          <option value="FailedByPositionHedge">FailedByPositionHedge</option>
          <option value="FailedByTradesOnNews">FailedByTradesOnNews</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">State</label>
        <select v-model="State" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="Active">Active</option>
          <option value="Failed">Failed</option>
          <option value="ReviewNeeded">ReviewNeeded</option>
          <option value="Reseted">Reseted</option>
          <option value="Expired">Expired</option>
          <option value="SucceedMinProfit">SucceedMinProfit</option>
          <option value="PendingNextPhase">PendingNextPhase</option>
          <option value="Passed">Passed</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">TotalTradingDates</label>
        <input v-model="TotalTradingDates" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ApprovedTradingDays</label>
        <input v-model="ApprovedTradingDays" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">CanExtend</label>
        <select v-model="CanExtend" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="True">True</option>
          <option value="False">False</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">CanReset</label>
        <select v-model="CanReset" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="True">True</option>
          <option value="False">False</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">CanWithdrawal</label>
        <select v-model="CanWithdrawal" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="True">True</option>
          <option value="False">False</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">IsWithdrew</label>
        <select v-model="IsWithdrew" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="True">True</option>
          <option value="False">False</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">FinishedWithdrawsCountFromLastScale</label>
        <input v-model="FinishedWithdrawsCountFromLastScale" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">TotalProfitsMadeFromLastScale</label>
        <input v-model="TotalProfitsMadeFromLastScale" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">Extended</label>
        <select v-model="Extended" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value="True">True</option>
          <option value="False">False</option>
          <option value="">--</option>
        </select>
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">FromFirstTradeDateTime</label>
        <input v-model="FromFirstTradeDateTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ToFirstTradeDateTime</label>
        <input v-model="ToFirstTradeDateTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">FromExpireTime</label>
        <input v-model="FromExpireTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ToExpireTime</label>
        <input v-model="ToExpireTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">PreviousAccountId</label>
        <input v-model="PreviousAccountId" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">PassedAccountId</label>
        <input v-model="PassedAccountId" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">FromCreateDateTime</label>
        <input v-model="FromCreateDateTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ToCreateDateTime</label>
        <input v-model="ToCreateDateTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Symbol</label>
        <input v-model="Symbol" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Page</label>
        <input v-model="Page" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div> -->
      <div class="sm:col-span-4 col-span-12  mx-1 mt-6">
        
        <button @click="fetchTradingAccounts" class="block w-full p-1  text-white border border-gray-300 rounded-lg bg-green-100 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">فیلتر</button>
      </div>

    </div>


    <div class="col-span-12 overflow-auto bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <table class="w-full overflow-auto  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto">
            <thead  class="text-xs text-gray-700 uppercase bg-gray-50 round-10 ">
              <tr>
                 <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                 
                 <th scope="col" class="px-6 py-3 font-iranSans">is connected</th>
                 <th scope="col" class="px-6 py-3 font-iranSans">accountId</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">state</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">deposit</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">balance</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">type</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">plan</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">broker</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">failedBy</th> 
                 
              </tr>
            </thead> 
            <tbody  class="bg-white border-b  ">
              <tr v-for="(account, index) in tradingAccounts" :key="index" class="text-gray-900   hover:bg-gray-200">
                <td class="px-6 py-2">{{ index }}</td>
                <td v-if="account.isConnected" class="flex px-6 py-2 justify-center"><span class= " justify-self-center relative flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-100 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-green-200"></span>
                  </span>
                </td>
                <td v-if="!account.isConnected" class="flex px-6 py-2 justify-center"><span class="relative justify-self-center flex h-3 w-3">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                  </span>
                </td>
                
                <td class="px-6 py-2">{{ account.accountId }}</td>
                <td class="px-6 py-2">{{ account.state }}</td>
                <td class="px-6 py-2">{{ account.deposit }}</td>
                <td class="px-6 py-2">{{ account.balance }}</td>
                <td class="px-6 py-2">{{ account.type }}</td>
                <td class="px-6 py-2">{{ account.plan }}</td>
                <td class="px-6 py-2">{{ account.broker }}</td>
                <td class="px-6 py-2">{{ account.failedBy }}</td>
                <td class="px-6 py-2"><router-link :to="{params: {id:account.userId }, name:'DAdminUserDetails' }"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-2">
                              اطلاعات یوزر
                             
        </router-link></td>
                
              </tr>
            </tbody>
          </table>
          <div class="col-span-12  m-3 mt-10 grid grid-cols-9">
        <button @click.prevent="nextPage" class="md:col-span-1 md:col-start-4 col-span-3 font-iranSans  hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              بعدی
                             
        </button>
        <div class=" md:col-span-1 md:col-start-5 col-span-3 font-iranSans  duration-300 text-center w-full block border-2 border-green-100 bg-gray-100 focus:bg-green-100 text-indigo-900 text-indigo-900 font-semibold rounded-lg px-4 py-2 mt-2">
                              {{ this.Page }}
                             
        </div>
        <button v-if="this.Page > 1" @click.prevent="prePage" class=" md:col-span-1 md:col-start-6 col-span-3  font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              قبلی
                             
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import URL from "../URL";
export default {
  data(){
    return{
      UserId: '',
      AccountId: '',
      Uid: '',
      Type: '',
      Broker: '',
      Deposit: '',
      ActualDeposit: '',
      FailedBy: '',
      State: '',
      TotalTradingDates: '',
      ApprovedTradingDays: '',
      CanExtend: '',
      CanReset: '',
      CanWithdrawal: '',
      IsWithdrew: '',
      FinishedWithdrawsCountFromLastScale: '',
      TotalProfitsMadeFromLastScale: '',
      Extended: '',
      FromFirstTradeDateTime: '',
      ToFirstTradeDateTime: '',
      FromExpireTime: '',
      ToExpireTime: '',
      PreviousAccountId: '',
      PassedAccountId: '',
      FromCreateDateTime: '',
      ToCreateDateTime: '',
      Symbol: '',
      Page: 1,
      PageSize: 20,
      


      tradingAccounts:[],
    }
  },
  mounted(){
    this.fetchTradingAccounts();
    
  },
  methods:{
    nextPage(){
        this.Page = this.Page + 1;
        this.fetchTradingAccounts();
        
      },
      prePage(){
        this.Page = this.Page - 1;
        this.fetchTradingAccounts();
      },
    fetchTradingAccounts(){
      const url = URL.baseUrl + `Admin/accounts?UserId=${this.UserId}&AccountId=${this.AccountId}&Uid=${this.Uid}&Type=${this.Type}&Broker=${this.Broker}&Deposit=${this.Deposit}&ActualDeposit=${this.ActualDeposit}&FailedBy=${this.FailedBy}&State=${this.State}&TotalTradingDates=${this.TotalTradingDates}&ApprovedTradingDays=${this.ApprovedTradingDays}&CanExtend=${this.CanExtend}&CanReset=${this.CanReset}&CanWithdrawal=${this.CanWithdrawal}&IsWithdrew=${this.IsWithdrew}&FinishedWithdrawsCountFromLastScale=${this.FinishedWithdrawsCountFromLastScale}&TotalProfitsMadeFromLastScale=${this.TotalProfitsMadeFromLastScale}&Extended=${this.Extended}&FromFirstTradeDateTime=${this.FromFirstTradeDateTime}&ToFirstTradeDateTime=${this.ToFirstTradeDateTime}&FromExpireTime=${this.FromExpireTime}&ToExpireTime=${this.ToExpireTime}&PreviousAccountId=${this.PreviousAccountId}&PassedAccountId=${this.PassedAccountId}&FromCreateDateTime=${this.FromCreateDateTime}&ToCreateDateTime=${this.ToCreateDateTime}&Symbol=${this.Symbol}&Page=${this.Page}&PageSize=${this.PageSize}`;
      axios.get(url,
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
              
              this.tradingAccounts = response.data.map(account => {
        return {
            ...account,
            isConnected: false,
            
        };
        });
        this.checkConnection();
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
      
    },
    checkConnection(){
      for (let i = 0; i < this.tradingAccounts.length; i++) {
        const acc = this.tradingAccounts[i];
        const url = URL.baseUrl + `Admin/Connection/${acc.uId}`;
        
        axios.get(url,
        
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }        
        )
        .then(response => {
          // Handle successful response here
          console.log(response.data);
          this.tradingAccounts[i].isConnected = response.data;
          
        })
        
        
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
      }
    }
      
    }
  }
</script>