<template>
  <div v-if="showSuccessToken"  class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-center justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2 w-full   text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card2  rounded-lg shadow sm:p-8 ">
                                
                                  <ul class="grid  grid-cols-1">
                                    <li class="text-right text-TFL-green1" >شماره همراه شما با موفقیت تائید شد. </li>
                                    <li class="text-right text-gray-300" > برای ادامه ابتدا از پنل کاربری خارج و مجددا وارد شوید</li>
                                    <button @click="logout" class="text-center self-center bg-TFL-base1 mt-4  text-white px-2 py-1 rounded-xl">اینجا کلیک کنید</button>
                                  </ul>
                                            
                                                
                                </div>
                                                
                            
                            
                        </div>
                        </div> 
                    </div>
                    </div>
  <div class="grid grid-cols-12 mx-2 my-2">
        <div class="  lg:col-span-8 col-span-12 bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg m-2 ">
          <div class="flex justify-between">

            <p class="text-TFL-dashText1">مشخصات کاربر</p>
            <div class=" col-span-4 flex justify-self-end ">
                        <button v-if="!isEditing" @click="changeEdit" class="text-xs p-2 rounded-lg 	 text-purple-600 bg-purple-300  mx-2 truncate ">
                              ویرایش
                        </button>
                        <button v-if="isEditing" @click="changeEdit" class="text-xs p-2 rounded-lg 	 text-orange-600 bg-orange-300  mx-2 truncate ">
                              عدم ویرایش
                        </button>
                      
                    </div>
          </div>
          <div>
            <div v-if="!detailLoaded" class="     mt-4 p-2 pb-1 ">
              <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div v-if="detailLoaded" class=" grid grid-rows-11    mt-4 p-2 pb-1 ">

              <div class="grid grid-cols-12 items-center space-x-4 my-2  ">
                  
                  <div class=" col-span-6">
                      <p class="text-sm text-gray-300 mx-2 truncate ">
                            نام:
                      </p>
                    
                  </div>
                  <div v-if="!isEditing" class=" col-span-6">
                      <p class="text-sm text-gray-300 bg-opacity-70 mx-2 truncate ">
                        {{persianFirstName }}
                      </p>
                    
                  </div>
                  <div v-if="isEditing" class=" col-span-6">
                    <input v-model="persianFirstName" type="text" id="small-input" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 text-white focus:border-TFL-base1  focus:outline-none ">
                    
                  </div>
                  
                  
              </div>
              
              <div class="grid grid-cols-12 items-center space-x-4 my-4  ">
                  
                  <div 
                   class=" col-span-6">
                      <p class="text-sm text-gray-300 mx-2 truncate ">
                           نام خانوادگی:
                      </p>
                    
                  </div>
                  <div v-if="!isEditing" class=" col-span-6">
                      <p class="text-sm text-gray-300 bg-opacity-70 mx-2 truncate ">
                            {{persianLastName }}
                      </p>
                    
                  </div>
                  <div v-if="isEditing" class=" col-span-6">
                    <input v-model="persianLastName" type="text" id="small-input" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 text-white focus:border-TFL-base1  focus:outline-none ">
                    
                  </div>
                  
                  
              </div>
              
              <div class="grid grid-cols-12 items-center space-x-4 my-4  ">
                  
                  <div class=" col-span-6">
                      <p class="text-sm text-gray-300 mx-2 truncate ">
                        نام (انگلیسی):
                      </p>
                    
                  </div>
                  <div v-if="!isEditing" class=" col-span-6">
                      <p class="text-sm text-gray-300 bg-opacity-70 mx-2 truncate ">
                        {{englishFirstName }}
                      </p>
                    
                  </div>
                  <div v-if="isEditing" class=" col-span-6">
                    <input dir="ltr" v-model="englishFirstName" type="text" id="small-input" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 text-white focus:border-TFL-base1  focus:outline-none ">
                    
                  </div>
                  
                  
              </div>
              
              <div class="grid grid-cols-12 items-center space-x-4 my-4  ">
                  
                  <div class=" col-span-6">
                      <p class="text-sm text-gray-300 mx-2 truncate ">
                        نام خانوادگی (انگلیسی):
                      </p>
                    
                  </div>
                  <div v-if="!isEditing" class=" col-span-6">
                      <p class="text-sm text-gray-300 bg-opacity-70 mx-2 truncate ">
                        {{englishLastName }}
                      </p>
                    
                  </div>
                  <div v-if="isEditing" class=" col-span-6">
                    <input dir="ltr" v-model="englishLastName" type="text" id="small-input" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 text-white focus:border-TFL-base1  focus:outline-none ">
                    
                  </div>
                  
                  
              </div>
              <div class="grid grid-cols-12 items-center space-x-4 my-4  ">
                  
                  <div class=" col-span-6">
                      <p class="text-sm text-gray-300 mx-2 truncate ">
                       آدرس ولت:
                      </p>
                    
                  </div>
                  <div v-if="!isEditing" class=" col-span-6">
                      <p class="text-sm text-gray-300 bg-opacity-70 mx-2 truncate ">
                        {{walletAdress }}
                      </p>
                    
                  </div>
                  <div v-if="isEditing" class=" col-span-6">
                    <input dir="ltr" v-model="walletAdress" type="text" id="small-input" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 text-white focus:border-TFL-base1  focus:outline-none ">
                    
                  </div>
                  
                  
              </div>
              
              <div class="grid grid-cols-12 items-center space-x-4 my-4  ">
                  
                  <div  class=" col-span-6">
                      <p class="text-sm text-gray-300 mx-2 truncate ">
                        شماره همراه:
                      </p>
                    
                  </div>
                  <div  class=" col-span-6">
                      <p v-if="userData.phoneNumber" dir="ltr" class="text-sm text-right text-gray-300 bg-opacity-70 mx-2 truncate ">
                            {{ formatNumber(phoneNumber)  }}
                      </p>
                    
                  </div>
                  
                  
                  
              </div>
              
              <div class="grid grid-cols-12 items-center space-x-4   ">
                  
                  <div  class=" lg:col-span-4 col-span-12 ">
                    <button @click="UpdateUser" v-if="loadingResponse2 && isEditing"  class="font-iranSans w-full block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold text-sm rounded-lg px-4 py-2 mt-6">ثبت تغییرات   </button>
                      <button v-if="!loadingResponse2" class="font-iranSans w-full block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold rounded-lg px-4 py-2 mt-6">
                          <div role="status justify-self-center">
                              <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                              <span class="sr-only">Loading...</span>
                          </div>
                      </button>
                  </div>
                  <div v-if="showSuccessChange" class=" col-span-8">
                      <p class="text-TFL-green1 text-xs mx-4 self-center">تغییرات ایجاد شده با موفقیت ثبت شد.</p>
                    
                  </div>
                  <div v-if="showFailedChange" class=" col-span-8">
                      <p class="text-TFL-red1 text-xs mx-4 self-center"> خطا در ثبت تغییرات</p>
                    
                  </div>
                  
                  
                  
              </div>
              

          </div>
        </div>
        </div>
        <div class="lg:col-span-4 col-span-12 bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg m-2 ">
          <p class="text-TFL-dashText1"> تغییر رمز عبور</p>
          <div class="mt-6">
          
          <div class="mt-4">
            <label for="small-input" class="block mb-1 text-sm font-medium text-gray-300 ">رمز عبور فعلی:</label>
            <input dir="ltr" v-model="oldPassword"   placeholder=" رمز عبور فعلی   " type="password" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 mt-2 mb-2  focus:border-TFL-base1 text-white focus:outline-none" required />
            
            <label for="small-input" class="block mb-1 text-sm font-medium text-gray-300 ">رمز عبور جدید:</label>
            <input dir="ltr" v-model="newPassword"   placeholder=" رمز عبور جدید   " type="password" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 mt-2 mb-2  focus:border-TFL-base1 text-white focus:outline-none" required />
            <div class="m-1 text-xs text-TFL-red1" v-if="!isValidPassword && newPassword">رمز عبور باید دارای حرف کوچک، حرف بزرگ، عدد و علامت باشد. </div>
            <label for="small-input" class="block mb-1 text-sm font-medium text-gray-300 "> تکرار رمز عبور جدید:</label>
            <input dir="ltr" v-model="checkNewPassword"   placeholder=" تائید رمز عبور جدید" type="password" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 mt-2  focus:border-TFL-base1 text-white focus:outline-none" required />
            <div class="m-1 text-xs text-TFL-red1" v-if="!isPasswordMatch && checkNewPassword  ">تگرار رمز عبور صحیح نمی‌باشد </div>
            
            
          </div>
          <button @click="changePassword" :disabled=" !isValidPassword || !isPasswordMatch || !allInputsFilled " v-if="loadingResponse1"  class="font-iranSans w-full block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold text-sm rounded-lg px-4 py-2 mt-6">تائید   </button>
          <button v-if="!loadingResponse1" class="font-iranSans w-full block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold rounded-lg px-4 py-2` mt-6">
              <div role="status justify-self-center">
                  <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
          </button>
          <div v-if="showSuccessChangePass" class=" col-span-8">
                      <p class="text-TFL-green1 text-xs m-4 self-center">تغییر رمز عبور با موفقیت انجام شد</p>
                    
                  </div>
                  <div v-if="showFailedChangePass" class=" col-span-8">
                      <p class="text-TFL-red1 text-xs m-4 self-center"> تغییر رمز عبور با خطا مواجه شد.   </p>
                    
                  </div>
          

            

            
            
        </div>
        
        </div>
        <div v-if="detailLoaded" class=" col-span-12 bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg m-2 ">
          <div class="flex justify-between items-center">
            <p class="text-TFL-dashText1"> احراز شماره تلفن</p>
            <p v-if="!phoneNumberVerified" class="bg-gray-500 text-gray-600 rounded rounded-lg px-2 py-1 text-xs ">احراز نشده</p>

            <p v-if="phoneNumberVerified " class="bg-TFL-green1 text-green-600 rounded rounded-lg px-2 py-1 text-xs ">تائید احراز</p>

          </div>
          <div v-if="phoneNumberVerified" class="bg-green-600 bg-opacity-70 col-span-12 border-1 mt-2 border-green-700 p-2  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
            <p  class="text-white text-sm">   کاربرگرامی، شماره تلفن شما احراز گردیده‌است.</p>
            

          </div>
          <div v-if="step1">
            <div>
                <p class="text-sm text-gray-300 mx-2 mt-4 truncate ">
                  شماره همراه:
                </p>
              
            </div>
            
            <div class="flex" >
              <input  v-model="phoneNumber" dir="ltr" type="text" id="small-input" class="font-iranSans text-xs text-white px-4 py-2 text-left rounded-lg bg-gray-600  bg-opacity-50  focus:border-TFL-base1 focus:outline-none ">
              <button @click="EditPhone"  v-if="loadingResponse4"  class="font-iranSans  block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold text-sm rounded-lg px-4 py-2 mx-2">ارسال کد تائید   </button>
              <button v-if="!loadingResponse4" class="font-iranSans  block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold rounded-lg px-4 py-2 mx-2">
                  <div role="status justify-self-center">
                      <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                      </svg>
                      <span class="sr-only">Loading...</span>
                  </div>
              </button>
              <div v-if="showSuccessEdit" class=" col-span-8">
                <p class="text-TFL-green1 text-xs mt-2 self-center">کد تائید به شماره همراه شما ارسال شد.</p>
              
            </div>
            <div v-if="showFailedEdit" class=" col-span-8">
                <p class="text-TFL-red1 text-xs mt-2 self-center"> خطا در ارسال کد تائید. </p>
              
          </div>
            </div>

          </div>
          <div v-if="showSuccessEdit" class=" col-span-8">
                <p class="text-TFL-green1 text-xs mt-2 self-center">کد تائید به شماره همراه شما ارسال شد.</p>
              
            </div>
          <div v-if="step2">
                  <div>
                      <p class="text-sm text-gray-300 mx-2 mt-4 truncate ">
                        کد تائید:
                      </p>
                    
                  </div>
                  
                  <div class="flex" >
                    <input  v-model="CheckPhoneToken" dir="ltr" type="text" id="small-input" class="font-iranSans text-xs  px-4 py-2 text-left rounded-lg bg-gray-600 text-white bg-opacity-50  focus:border-TFL-base1  focus:outline-none ">
                    <button @click="checkTokenPhone"  v-if="loadingResponse5"  class="font-iranSans  block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold text-sm rounded-lg px-4 py-2 mx-2">  ثبت کد تائید   </button>
                    <button v-if="!loadingResponse5" class="font-iranSans  block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold rounded-lg px-4 py-2 mx-2">
                        <div role="status justify-self-center">
                            <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                    <div v-if="showSuccessToken" class=" col-span-8">
                      <p class="text-TFL-green1 text-xs mt-2 self-center">شماره همراه شما با موفقیت تائید شد.</p>
                    
                    </div>
                    <div v-if="showFailedToken" class=" col-span-8">
                      <p class="text-TFL-red1 text-xs mt-2 self-center"> خطا در تائید شماره همراه. </p>
                    
                    </div>
                  </div>
            </div>

        </div>
        <div v-if="!detailLoaded" class=" col-span-12 bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg m-2 ">
          <p class="text-TFL-dashText1"> احراز شماره تلفن</p>
          <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    
                    </div>
        
        </div>
        <div v-if="!detailLoaded" class=" col-span-12 bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg m-2 ">
          <p class="text-TFL-dashText1"> احراز هویت</p>
          <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-500 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-500 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-500 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center gap-x-2 justify-between">
                       
                            <div class="h-64 bg-gray-500 rounded-lg  w-1/3 mb-2.5"></div>
                            <div class="h-64 bg-gray-500 rounded-lg  w-1/3 mb-2.5"></div>
                            <div class="h-64 bg-gray-500 rounded-lg  w-1/3 mb-2.5"></div>
                            
                        
                    </div>
                    </div>
        
        </div>
        <div v-if="detailLoaded" class=" col-span-12 bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg m-2 ">
          <div class="flex justify-between items-center">
            <p class="text-TFL-dashText1"> احراز هویت</p>
            <p v-if="filledForBadge ==''" class="bg-gray-500 text-gray-600 rounded rounded-lg px-2 py-1 text-xs ">احراز نشده</p>
            <p v-if="filledForBadge && !verificationChecked " class="bg-orange-300 text-orange-600 rounded rounded-lg px-2 py-1 text-xs ">در حال بررسی</p>
            <p v-if="filledForBadge && verificationChecked && !verified " class="bg-red-300 text-TFL-red1 rounded rounded-lg px-2 py-1 text-xs ">عدم تائید مدارک</p>
            <p v-if="filledForBadge && verificationChecked && verified " class="bg-TFL-green1 text-green-600 rounded rounded-lg px-2 py-1 text-xs ">تائید احراز</p>

          </div>
          <div v-if="verified" class="bg-green-600 bg-opacity-70 col-span-12 border-1 mt-2 border-green-700 p-2  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
            <p  class="text-white text-sm">   کاربرگرامی، احراز هویت شما تکمیل شده‌است.</p>
            

          </div>
          <div class="grid grid-cols-3 gap-x-2 mt-4">

            <div class=" col-span-3 items-center justify-start w-full">
              <p class="text-gray-300"> کد ملی: </p>
              <input v-if="(!filledForBadge || !verificationChecked || !verified)" dir="ltr" v-model="nationalCode"   placeholder="کد ملی" type="text" class="font-iranSans text-xs  px-4 py-3 text-right rounded-lg bg-gray-600 bg-opacity-50 text-white mt-2 mb-2  focus:border-TFL-base1  focus:outline-none" required />
              <p v-if="filledForBadge && verificationChecked && verified" class="text-xs text-gray-300" >{{ formatNumber(nationalCode) }}</p>
            </div>
            <div class="lg:col-span-1 col-span-3 items-center justify-center w-full">
              <p class="text-gray-300"> تصویر روی کارت ملی </p>
              <input id="upload1" type="file" @change="uploadImageNational" accept="image/*" class="hidden">
              <label v-if="!imageUrl1" for="upload1" class="mt-2 flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 ">
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 "><span class="font-semibold">برای آپلود کلیک کنید</span></p>
                      <p class="text-xs text-gray-500 "> PNG, JPG</p>
                  </div>
    
                </label>
                <label v-if="imageUrl1 && !filled" for="upload1" class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 ">

                  <img v-if="imageUrl1 && !filled" :src="imageUrl1" alt="Uploaded Image" class="max-w-full max-h-full p-2" >
                  
                </label>
                <label v-if="imageUrl1 && filled"  class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg  bg-gray-700 hover:bg-gray-600 ">

                  
                  <img v-if="imageUrl1 && filled" :src="'data:image/jpeg;base64,' + imageUrl1" alt="Image" class="max-w-full max-h-full p-2">
                </label>
           </div> 
            <div class="lg:col-span-1 col-span-3  items-center justify-center w-full">
              <p class="text-gray-300"> تصویر پشت کارت ملی </p>
              <input id="upload2" type="file" @change="uploadImageBackNational" accept="image/*" class="hidden ">
              <label v-if="!imageUrl2" for="upload2" class=" mt-2 flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 ">
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 "><span class="font-semibold">برای آپلود کلیک کنید</span></p>
                      <p class="text-xs text-gray-500 "> PNG, JPG</p>
                  </div>
    
                </label>
                <label v-if="imageUrl2  && !filled" for="upload2" class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 ">

                  <img v-if="imageUrl2 && !filled" :src="imageUrl2" alt="Uploaded Image" class="max-w-full max-h-full p-2" >
                  
                  
                </label>
                <label v-if="imageUrl2 && filled"  class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg bg-gray-700 hover:bg-gray-600 ">

                  
                  <img v-if="imageUrl2 && filled" :src="'data:image/jpeg;base64,' + imageUrl2" alt="Image" class="max-w-full max-h-full p-2">
                  
                </label>
                
           </div> 
            <div class="lg:col-span-1 col-span-3  items-center justify-center w-full">
              <p class="text-gray-300"> تصویر کاربر به همراه کارت ملی </p>
              <input id="upload3" type="file" @change="uploadImageUserWithNational" accept="image/*" class="hidden">
              <label v-if="!imageUrl3" for="upload3" class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 ">
                  <div class="flex flex-col items-center justify-center pt-5 pb-6">
                      <svg class="w-8 h-8 mb-4 text-gray-500 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                      </svg>
                      <p class="mb-2 text-sm text-gray-500 "><span class="font-semibold">برای آپلود کلیک کنید</span></p>
                      <p class="text-xs text-gray-500 "> PNG, JPG</p>
                  </div>
    
                </label>
                <label v-if="imageUrl3 && !filled" for="upload3" class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-700 hover:bg-gray-600 ">

                  <img v-if="imageUrl3 && !filled" :src="imageUrl3" alt="Uploaded Image" class="max-w-full max-h-full p-2" >
                  
                  
                </label>
                <label v-if="imageUrl3 && filled"  class="flex flex-col mt-2 items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg  bg-gray-700 hover:bg-gray-600 ">

                  
                  <img v-if="imageUrl3 && filled" :src="'data:image/jpeg;base64,' + imageUrl3" alt="Image" class="max-w-full max-h-full p-2">
                  
                </label>
           </div>
           <button v-if="!filledForBadge || !verificationChecked || !verified " @click="deleteDocs"  class="font-iranSans col-span-3  block bg-gray-500 hover:bg-gray-500  text-gray-100  font-semibold text-xs rounded-lg px-4 py-1 mt-2">حذف اطلاعات و بارگزاری مجدد </button>
           <div v-if="!filledForBadge || !verificationChecked || !verified " class="col-span-3 flex items-center space-x-4 my-2    align-middle text-gray-500 ">
                    <div class="flex items-center mx-2">
                        <input id="default-checkbox" type="checkbox" v-model="isChecked" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded ">
    
                    </div>
                    <div>

                        صحت اطلاعات وارد شده را می‌پذیرم.
                    </div>
                  </div>  
                  
                </div>
                <div>
            <button v-if="(!filledForBadge || !verificationChecked || !verified) && loadingResponse3 " @click="sendDocs" :disabled="  !isChecked || !base64Data1 || !base64Data2 || !base64Data3 || !nationalCode "   class="font-iranSans  block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold text-sm rounded-lg px-4 py-2 mt-6">ارسال اطلاعات </button>
            <button v-if="!loadingResponse3" class="font-iranSans  block bg-TFL-base1 hover:bg-TFL-base1 focus:bg-TFL-base1 text-white font-semibold text-sm rounded-lg px-4 py-2 mt-6">
                <div role="status justify-self-center">
                    <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
            </button>
            <div v-if="showSuccessSendVerif"  class=" col-span-8">
                      <p class="text-TFL-green1 text-xs mt-2 self-center">مدارک شما جهت بررسی کارشناس ارسال شد.</p>
                    
                    </div>
                    <div v-if="showFailedSendVerif" class=" col-span-8">
                      <p class="text-TFL-red1 text-xs mt-2 self-center">خطا در ارسال مدارک</p>
                    
                    </div>
            
             
   
  </div>
        </div>
  </div>
</template>

<script>
import axios from 'axios';
import URL from "../URL";

export default { 
  data(){
    return{
      userData: [],
      loadingResponse1: true,
      loadingResponse2: true,
      loadingResponse3: true,
      loadingResponse4: true,
      loadingResponse5: true,

      oldPassword:'',
      newPassword: '',
      checkNewPassword: '',
      
      persianFirstName: '',
      persianLastName: '',
      englishFirstName: '',
      englishLastName: '',
      phoneNumber: '',
      isEditing: false,
      showSuccessChange : false,
      showFailedChange : false,
      showSuccessChangePass : false,
      showFailedChangePass : false,
      detailLoaded: false,

      imageUrl1: null,
      imageUrl2: null,
      imageUrl3: null,

      base64Data1: null,
      base64Data2: null,
      base64Data3: null,
      nationalCode: '',
      verificationChecked: false,
      verified: false,
      phoneNumberVerified: false,

      isChecked: false,
      filled: false,
      filledForBadge: false,
      step1: false,
      step2: false,
      CheckPhoneToken: '',

      showSuccessUpload: false,
      showFailedUpload: false,

      showSuccessSendVerif: false,
      showFailedSendVerif: false,

      walletAdress: '',
      
    }
  },
  mounted(){
    this.checkToken();
  },
  computed:{
    isValidPassword() {
      const lengthRegex = /^.{8,}$/;
      const uppercaseRegex = /[A-Z]/;
      const lowercaseRegex = /[a-z]/;
      const numberRegex = /[0-9]/;
      const symbolRegex = /[!@#$%^&*]/;

      return (
        lengthRegex.test(this.newPassword) &&
        uppercaseRegex.test(this.newPassword) &&
        lowercaseRegex.test(this.newPassword) &&
        numberRegex.test(this.newPassword) &&
        symbolRegex.test(this.newPassword)
      );
    },
    isPasswordMatch() {
      return this.checkNewPassword === this.newPassword;
    },
    allInputsFilled() {
      return this.newPassword && this.checkNewPassword && this.oldPassword   ;
    },
  },



  methods:{
    logout(){
         localStorage.removeItem('token');
         this.$router.push('/login');
      },
    EditPhone(){
      this.loadingResponse4 = !this.loadingResponse4
  // POST request using axios with error handling
        const url = URL.baseUrl + `users/send-phone-validation-sms`;
        axios.post(url,
        { 
           
            
          phoneNumber: this.phoneNumber,
            
           
            
            },
            {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.loadingResponse4 = !this.loadingResponse4;
            this.showFailedEdit = false;
            this.showSuccessEdit = true;
            this.step1 = false;
            this.step2 = true;
            
          })
          
          
          .catch(error => {
            this.errorMessage = error.message;
            
            this.loadingResponse4 = !this.loadingResponse4;
            this.showSuccessEdit = false;
            this.showFailedEdit = true;
            });
    },
    checkTokenPhone(){
      this.loadingResponse5 = !this.loadingResponse5
  // POST request using axios with error handling
        const url = URL.baseUrl + `users/confirm-phone`;
        axios.post(url,
        { 
           
            
          phoneNumber: this.phoneNumber,
          token: this.CheckPhoneToken,
            
           
            
            },
            {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.loadingResponse5 = !this.loadingResponse5;
            this.showFailedToken = false;
            this.showSuccessToken = true;
            
            
          })
          
          
          .catch(error => {
            this.errorMessage = error.message;
            
            this.loadingResponse5 = !this.loadingResponse5;
            this.showSuccessToken = false;
            this.showFailedToken = true;
            });
    },
    deleteDocs(){
      this.imageUrl1 = null;
      this.imageUrl2 = null;
      this.imageUrl3 = null;
      this.base64Data1 = null;
      this.base64Data2 = null;
      this.base64Data3 = null;
      this.nationalCode = null;
      
      this.filled = false;
    },
    sendDocs() {
            this.loadingResponse3 = false;
  // POST request using axios with error handling
        const url = URL.baseUrl + `users/add-validation`;
        axios.post(url,
        { 
           
            
            nationCode: this.nationalCode,
            nationalCardImageBase64: this.base64Data1,
            nationalCardBackImageBase64: this.base64Data2,
            personallyImageBase64: this.base64Data3,
            
            },
            {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
        }
            
        
        )
        .then(response => {
            // Handle successful response here
           
            this.loadingResponse3 = true;
            this.showFailedSendVerif = false;
            this.showSuccessSendVerif = true;
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            this.loadingResponse3 = true;
            this.showSuccessSendVerif = false;
            this.showFailedSendVerif = true;
            
            });
},
    uploadImageNational(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.file = file;
        this.base64Data1 = reader.result.split(',')[1];
        this.imageUrl1 = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    uploadImageBackNational(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.file = file;
        this.base64Data2 = reader.result.split(',')[1];
        this.imageUrl2 = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    uploadImageUserWithNational(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        this.file = file;
        this.base64Data3 = reader.result.split(',')[1];
        this.imageUrl3 = reader.result;
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    changeEdit(){
      this.isEditing = !this.isEditing;
    },
    checkToken(){
      const url = URL.baseUrl + `users/whoami-with-validations`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         this.userData = response.data;
         this.persianFirstName = this.userData.persianFirstName;
         this.persianLastName = this.userData.persianLastName;
         this.englishFirstName = this.userData.englishFirstName;
         this.englishLastName = this.userData.englishLastName;
         this.phoneNumber = this.userData.phoneNumber;
         this.nationalCode = this.userData.nationCode;
         this.base64Data1 = this.userData.nationalCardImageBase64;
         this.base64Data2 = this.userData.nationalCardBackImageBase64;
         this.base64Data3 = this.userData.personallyImageBase64;
         this.verificationChecked = this.userData.verificationChecked;
         this.verified = this.userData.verified;
          this.phoneNumberVerified = this.userData.phoneNumberConfirmed;
          this.walletAdress = this.userData.walletCode;
        this.detailLoaded = true;

        if (this.userData.nationalCardImageBase64) {
          this.imageUrl1 = this.userData.nationalCardImageBase64;
        }
        if (this.userData.nationalCardBackImageBase64) {
          this.imageUrl2 = this.userData.nationalCardBackImageBase64;
        }
        if (this.userData.personallyImageBase64) {
          this.imageUrl3 = this.userData.personallyImageBase64;
        }
        if (this.nationalCode){
          this.filled = true;
          this.filledForBadge = true;
        }
        if (!this.phoneNumberVerified){
          this.step1 = true;
        }
          
          
        })
        .catch(error => {
          this.detailLoaded = true;


        });
      },
      formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    UpdateUser(){
      this.loadingResponse2 = !this.loadingResponse2
  // POST request using axios with error handling
        const url = URL.baseUrl + `users/update`;
        axios.put(url,
        { 
           
            
            nationCode: this.nationalCode,
            walletCode: this.walletAdress,
            persianFirstName: this.persianFirstName,
            persianLastName: this.persianLastName,
            englishFirstName: this.englishFirstName,
            englishLastName: this.englishLastName,
            
            },
            {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.loadingResponse2 = !this.loadingResponse2;
            this.showFailedChange = false;
            this.showSuccessChange = true;
            this.isEditing = false;
            
          })
          
          
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.loadingResponse2 = !this.loadingResponse2;
            this.showSuccessChange = false;
            this.showFailedChange = true;
            });
    },
    changePassword(){
      this.loadingResponse1 = !this.loadingResponse1
  // POST request using axios with error handling
        const url = URL.baseUrl + `users/password`;
        axios.put(url,
        { 
           
            
              oldPassword: this.oldPassword,
              newPassword: this.newPassword,
           
            },
            {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.loadingResponse1 = !this.loadingResponse1;
            this.showFailedChangePass = false;
            this.showSuccessChangePass = true;
            
          })
          
          
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.loadingResponse1 = !this.loadingResponse1;
            this.showSuccessChangePass = false;
            this.showFailedChangePass = true;
            });
    }
    
  }
}
</script>