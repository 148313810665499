<template>
  
  <router-view/>
  </template>

<script>
export default {
  data() {
    return {
      showSidebar: true,
    };
  },
  methods: {
    hideSidebar() {
      if (window.innerWidth < 640) {
        this.showSidebar = false;
      }
    },
  },
};
</script>

<style>
@media screen and (max-width: 767px) {
  main {
    height: 100vh;
    overflow-x: hidden;
  }

  .w-full {
    width: 100%;
  }

  .hidden-sidebar {
    display: none;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .pt-10 {
    padding-top: 4rem;
  }
  
  .pb-6 {
    padding-bottom: 2.5rem;
  }
  
  .pt-14 {
    padding-top: 5rem;
  }
}
</style>