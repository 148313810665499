<template>
  
    
    
                    <div v-if="showTokenError"   class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-center justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2 w-full   text-left shadow-xl transition-all sm:my-2 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1  rounded-lg shadow sm:p-2 ">
                                
                                  <ul class="grid grid-cols-1">
                                    <li class="text-right text-gray-200" >توکن نامعتبر</li>
                                    <li class="text-right text-gray-200" >لطفا صفحه را رفرش کنید.</li>
                                    <a href="/Login" class="text-center self-center bg-TFL-base1 mt-4  text-white px-2 py-1 rounded-xl">اینجا کلیک کنید</a>
                                  </ul>
                                            
                                                
                                </div>
                                                
                            
                            
                        </div>
                        </div> 
                    </div>
                    </div>    
                    <div v-if="showModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-center justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2 w-full   text-left shadow-xl transition-all sm:my-2 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1  rounded-lg shadow sm:p-2 ">
                                
                                  <ul>
                                  <li class="text-center text-gray-200">کاربر گرامی </li>
                                  <li class="text-center text-gray-200">نام کاربری و یا رمز عبور اشتباه وارده شده است </li>
                                </ul>
                                            
                                                
                                </div>
                                                
                            <div class="bg-TFL-card2 px-4 my-2  sm:flex sm:flex-row-reverse sm:px-6">
                                <button @click="closeModal" type="button" class="inline-flex w-full py-1  justify-center rounded-md bg-TFL-base1 px-3  text-sm font-semibold text-white shadow-sm hover:bg-TFL-base1 sm:ml-3 sm:w-auto">بستن</button>
                                
                            </div>
                            
                        </div>
                        </div> 
                    </div>
                    </div>    




    
  <div class="min-h-screen bg-TFL-background">
    <!-- <div class=" bg-TFL-background grid grid-cols-3 ">
  <img src="../assets/images/TFL-Logo.svg" class="h-20 my-10 col-span-3 justify-self-center mx-4 ">
  
  <div class="border-red-500 bg-TFL-background lg:col-span-1 lg:col-start-2 lg:mx-0  col-span-3 mx-2  ">
    <div class="   " style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg,#849BFF 10%, rgba(33, 150, 243, 0) 50%);">
    <div class="bg-gray-800 p-5 flex rounded-2xl shadow-lg   col-span-3 lg:col-span-1 lg:col-start-2 grid grid-cols-3"  style="border-radius: 53px;">
      <div class=" px-5 col-span-3">
        <h2 class="sm:text-2xl text-xl font-bold font-iranSans text-white text-center">ورود و عضویت در سایت</h2>
       
        <form class="mt-6">
            <div>
              <label class="block text-white text-right sm:text-base text-sm font-iranSans">آدرس ایمیل / نام کاربری</label>
              <input dir="ltr"  v-model="email" placeholder="آدرس ایمیل خود را وارد کنید" class="font-iranSans text-xs w-full px-4 py-3 text-left rounded-lg bg-gray-700 mt-2 border focus:border-TFL-base1 focus:bg-gray-600 " autofocus autocomplete required />
            </div>

            <div class="mt-4">
              <label class="block text-white text-right sm:text-base text-sm font-iranSans">رمز عبور</label>
              <input dir="ltr" type="password" v-model="password" minlength="6" placeholder="رمز عبور خود را وارد کنید" class="font-iranSans text-xs w-full px-4 py-3 text-left rounded-lg bg-gray-700 mt-2 border focus:border-TFL-base1 focus:bg-gray-600 " required />
            </div>

            <div class="text-left mt-2">
              <a href="/forgetPass" class="text-sm font-semibold font-iranSans text-white hover:text-blue-700 focus:text-blue-700">فراموشی رمز عبور؟</a>
            </div>

            <button @click="login" v-if="loadingResponse" class="font-iranSans w-full block bg-TFL-base1   text-white font-semibold text-sm rounded-lg px-4 py-3 mt-6">وارد شوید</button>
            <button @click="login" v-if="!loadingResponse" class="font-iranSans w-full block bg-TFL-base1   text-white font-semibold rounded-lg px-4 py-3 mt-6">
              <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
            </button>
          </form>


        <div class="mt-3 grid grid-cols-3 items-center text-gray-500">
          <hr class=" col-span-3 border-gray-500" />
          
          
        </div>

       
        <div class="text-sm flex justify-between items-center mt-1">
          
             <a href="/Signup" class="font-iranSans text-center w-full block bg-TFL-base1   text-white font-semibold rounded-lg
                px-4 py-3 mt-2" >عضویت</a>
        </div>
        <div class="text-sm flex justify-between items-center mt-1">
          
          <a href="/" class="font-iranSans w-full block bg-gray-650 hover:bg-gray-700 focus:bg-gray-700 text-white text-center font-semibold text-sm rounded-lg px-4 py-3 mt-2"> بازگشت به صفحه اصلی</a>
            
        </div>
      </div>

      

    </div>
  </div>
  </div>
</div> -->

<div class="flex flex-col items-center justify-center h-screen dark mx-2">
  <img src="../assets/images/TFL-Logo.svg" class="h-20 mb-10 col-span-3 justify-self-center mx-4 ">
  <div class="  rounded-lg w-full max-w-md " style=" padding: 0.1rem; background: linear-gradient(180deg,#849BFF 10%, rgba(33, 150, 243, 0) 50%);">
  <div class="w-full max-w-md bg-TFL-card1 rounded-lg shadow-md p-6">
    <h2 class="text-xl font-bold text-gray-200 mb-4">ورود به سایت</h2>
    <form class="flex flex-col">
      <input dir="ltr" placeholder="ایمیل / نام‌ کاربری"  v-model="email" class="text-sm bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-TFL-base1 transition ease-in-out duration-150" type="email">
      <input dir="ltr" placeholder="Password" v-model="password" class="bg-gray-700 text-sm text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-TFL-base1 transition ease-in-out duration-150" type="password">
      <div class="flex items-center justify-between flex-wrap">
        
        <a href="/forgetPass" class="text-sm font-semibold font-iranSans text-TFL-base1 ">فراموشی رمز عبور؟</a>
        <p class="text-white text-sm text-left mt-4"> حساب کاربری ندارید؟ <a class="text-sm text-blue-500 -200 hover:underline mt-4" href="/Signup">ثبت نام</a></p>
      </div>
      <button  @click="login" v-if="loadingResponse" class="bg-gradient-to-r from-indigo-500 to-TFL-base1 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-TFL-base1 hover:to-TFL-base2 transition ease-in-out duration-150" type="submit">ورود</button>
      <button   v-if="!loadingResponse" class="bg-gradient-to-r from-indigo-500 to-TFL-base1 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-TFL-base1 hover:to-TFL-base2 transition ease-in-out duration-150" type="submit">
        <div role="status justify-self-center">
            <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
      </button>
    </form>
  </div>
</div>
</div>



</div>


</template>
<script>
import axios from 'axios';
import URL from "../URL";
import siteKEY from "../siteKEY";
export default {
  data() {
    return {
      email: '',
      password: '',
      loadingResponse: true,
      captchaToken: '',
      showModal:false,
      showTokenError:false,
    };
  },
  mounted(){
    this.createScript();
  },
  
  methods: {
    createScript(){
      

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${siteKEY.key}`;
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
  
      
        
       
      
    },
    closeTokenModal(){
      this.showTokenError = false;
    },
    closeModal(){
      this.showModal = false;


    },
    login() {
  this.loadingResponse = false;
  setTimeout(() => {
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(siteKEY.key, { action: 'auth' });
      this.captchaToken = token;
      const url = URL.baseUrl + `users/auth`;
      const callback = () => {
        axios.post(url, { key: this.email, password: this.password, captchaToken: this.captchaToken }, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then(response => {
            const token = response.data.token;
            localStorage.setItem('token', token);
            this.whoami();
          })
          .catch(error => {
            this.loadingResponse = true;
            
            if (error.response  && error.response.data == 'Invalid reCAPTCHA response.') {
            this.showTokenError = true;
          }
          else{
            this.showModal = true;
          }
          });
      };
      callback();
    });
  }, 1000);
},
   
whoami() {
  const url = URL.baseUrl + `users/roles`;
  axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    const data = response.data;
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect');
    if (redirectUrl) {
      this.$router.push(redirectUrl);
    } else {
      if (Array.isArray(data) && data.length === 0) {
        this.$router.push('/Dashboard/Home');
      } else if (Array.isArray(data) && data.includes('Admin')) {
        this.$router.push('/AdminDashboard/ATicket');
      } else {
        // Handle invalid response
      }
    }
  })
  .catch(error => {
    // Handle error
  });
}
  },
};
</script>