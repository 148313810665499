<template>
    <div class="grid grid-cols-12">
      <div class="grid grid-cols-12 col-span-12" >
                         <button @click="tabButtonClick('All')" :class="{ 'bg-gray-200 text-indigo-900': this.selectedTab !== 'All','bg-green-100  text-white': this.selectedTab === 'All' }" class="col-span-4 py-3 px-4 m-1 rounded">همه</button>
                         <button @click="tabButtonClick('Waiting')" :class="{ 'bg-gray-200 text-indigo-900': this.selectedTab !== 'Waiting','bg-green-100  text-white': this.selectedTab === 'Waiting' }" class="col-span-4 py-3 px-4 m-1 rounded">منتظر تائید</button>
                         <button @click="tabButtonClick('Accepted')" :class="{ 'bg-gray-200 text-indigo-900': this.selectedTab !== 'Accepted','bg-green-100  text-white': this.selectedTab === 'Accepted' }" class="col-span-4 py-3 px-4 m-1 rounded">منتظر پرداخت</button>
                         
                     </div>
        <div v-if="this.selectedTab === 'Waiting'" class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>درخواست برداشت </p>
    </div>
        
    <!-- waiting Filter -->
<div v-if="this.selectedTab === 'Waiting'" class="col-span-12 grid grid-cols-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
  <div  class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         اندازه   
      </label>
      <div class="relative mt-3 text-right">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
          <select v-model="waitingPageSize" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
              <option value=10>10</option>
              <option value=20>20</option>
              <option value=40>40</option>
              <option value=50>50</option>
              
              
              
          </select>
      </div>
  </div>
  <div class="col-span-12 lg:col-span-1 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         تاریخ 
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="waitingDate"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>

      
  </div>
  
  <div class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         شماره حساب  
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="waitingAccountId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>
      
  </div>
  <div class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         شماره حساب آیدی 
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="waitingTradingAccountId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>
      
  </div>
  <div class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         آیدی یوزر   
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="waitingUserId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>
      
  </div>
  
  <div class="col-span-12 lg:col-span-1 m-3 mt-10">
    <button @click.prevent="fetchWithdrawalWaitingList" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                          فیلتر
                         
                     </button>
  </div>
  </div>
        <div v-if="this.selectedTab === 'Waiting'" class="col-span-12 border rounded-lg border-surface-border bg-white p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px]">
            <table class="w-full  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto">
            <thead  class="text-sm text-gray-700 uppercase bg-gray-50 round-10 ">
              <tr>
                 <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                 <th scope="col" class="px-6 py-3 font-iranSans">تاریخ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">شماره حساب</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">پسورد </th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> مبلغ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> یوزر</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">تائید</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">رد</th> 
                 
              </tr>
            </thead> 
            <tbody  class="bg-white border ">
              <tr v-for="(item, index) in withdrawalWaitingList" :key="index" class="text-gray-900  hover:bg-gray-200">
                <td class="px-6 py-4">{{ index }}</td>
                <td class="px-6 py-4">{{ item.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) }}</td>
                <td class="px-6 py-4">{{ item.accountId }}</td>
                <td class="px-6 py-4">{{ item.investorPassword }}</td>
                <td class="px-6 py-4">{{ item.amountToPay }}</td>
                <td class="px-6 py-4"><router-link :to="{params: {id:item.userId }, name:'DAdminUserDetails' }" target="_blank"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              اطلاعات یوزر
                             
              </router-link></td>
                <td class="px-6 py-4">
                    <button  @click.prevent="acceptWithdraw(index)" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-3 my-2">
                             قبول
                            
                    </button>
                </td>
                <td class="px-6 py-4">
                    <button  @click.prevent="rejectWithdraw(index)" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-3 my-2">
                             رد  
                            
                    </button>
                </td>
                
                
              </tr>
            </tbody>
          </table>
          <!-- Waiting Pagination -->
          <div class="col-span-12  m-3 mt-10 grid grid-cols-9">
              <button @click.prevent="waitingNextPage" class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                                    بعدی

              </button>
              <div class=" col-span-1 col-start-5 font-iranSans  duration-300 text-center w-full block border-2 border-green-100 bg-gray-100 focus:bg-green-100 text-indigo-900 text-indigo-900 font-semibold rounded-lg px-4 py-2 mt-2">
                                    {{ this.waitingPage }}

              </div>
              <button v-if="this.waitingPage > 1" @click.prevent="waitingPrePage" class=" col-span-1 col-start-6  font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                                    قبلی

              </button>

      </div>

        </div>
        
        <div v-if="this.selectedTab === 'Accepted'" class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>تائید شدگان  </p>
    </div>
    <!-- Accepted Filter -->
<div v-if="this.selectedTab === 'Accepted'" class="col-span-12 grid grid-cols-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
  <div  class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         اندازه   
      </label>
      <div class="relative mt-3 text-right">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
          <select v-model="acceptedPageSize" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
              <option value=10>10</option>
              <option value=20>20</option>
              <option value=40>40</option>
              <option value=50>50</option>
              
              
              
          </select>
      </div>
  </div>
  <div class="col-span-12 lg:col-span-1 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         تاریخ 
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="acceptedDate"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>

      
  </div>
  
  <div class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         شماره حساب  
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="acceptedAccountId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>
      
  </div>
  <div class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         شماره حساب آیدی 
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="acceptedTradingAccountId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>
      
  </div>
  <div class="col-span-12 lg:col-span-2 m-3">
      <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
         آیدی یوزر   
      </label>
      <div class="grid grid-cols-12 mt-3" >
          <input  v-model="acceptedUserId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
           
       </div>
      
  </div>
  
  <div class="col-span-12 lg:col-span-1 m-3 mt-10">
    <button @click.prevent="fetchWithdrawalAcceptedList" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                          فیلتر
                         
                     </button>
  </div>
  </div>
        
        <div v-if="this.selectedTab === 'Accepted'" class="col-span-12 border rounded-lg border-surface-border bg-white p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px]">
            <table class="w-full  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto">
            <thead  class="text-sm text-gray-700 uppercase bg-gray-50 round-10 ">
              <tr>
                 <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                 <th scope="col" class="px-6 py-3 font-iranSans">تاریخ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">شماره حساب</th> 
                 
                 <th scope="col" class="px-6 py-3 font-iranSans"> مبلغ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> ولت</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> یوزر</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">هش</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">پرداخت</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">پرداخت</th> 
                 
              </tr>
            </thead> 
            <tbody  class="bg-white border ">
              <tr v-for="(item, index) in withdrawalAcceptedList" :key="index" class="text-gray-900  hover:bg-gray-200">
                <td class="px-6 py-4">{{ index }}</td>
                <td class="px-6 py-4">{{ item.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) }}</td>
                <td class="px-6 py-4">{{ item.accountId }}</td>
                
                <td class="px-6 py-4">{{ item.amountToPay }}</td>
                <td class="px-6 py-4">{{ item.payAddress }}</td>
                <td class="px-6 py-4"><router-link :to="{params: {id:item.userId }, name:'DAdminUserDetails' }" target="_blank"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              اطلاعات یوزر
                             
              </router-link></td>
                <td class="px-6 py-4"><input v-model="item.enteredHash"   minlength="6" class="font-iranSans text-xs w-full px-4 py-3 text-right rounded-lg bg-gray-50 mt-2 border-2 focus:border-green-100 focus:bg-white focus:outline-none" required></td>
                
                <td class="px-6 py-4">
                    <button  @click.prevent="finishWithdraw(index)" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-3 my-2">
                             پرداخت شد  
                            
                    </button>
                </td>
                               
              </tr>
            </tbody>
          </table>
          <!-- Accepted Pagination -->
          <div class="col-span-12  m-3 mt-10 grid grid-cols-9">
              <button @click.prevent="acceptedNextPage" class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                                    بعدی

              </button>
              <div class=" col-span-1 col-start-5 font-iranSans  duration-300 text-center w-full block border-2 border-green-100 bg-gray-100 focus:bg-green-100 text-indigo-900 text-indigo-900 font-semibold rounded-lg px-4 py-2 mt-2">
                                    {{ this.acceptedPage }}

              </div>
              <button v-if="this.acceptedPage > 1" @click.prevent="acceptedPrePage" class=" col-span-1 col-start-6  font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                                    قبلی

              </button>

      </div>

        </div>
        <div v-if="this.selectedTab === 'All'" class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>لیست کلی   </p>
    </div>
    <!-- All Filter -->
    <div v-if="this.selectedTab === 'All'" class="col-span-12 grid grid-cols-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <div  class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             اندازه   
          </label>
          <div class="relative mt-3 text-right">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
              <select v-model="allPageSize" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                  <option value=10>10</option>
                  <option value=20>20</option>
                  <option value=40>40</option>
                  <option value=50>50</option>
                  
                  
                  
              </select>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-1 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             تاریخ 
          </label>
          <div class="grid grid-cols-12 mt-3" >
              <input  v-model="allDate"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
               
           </div>

          
      </div>
      <div  class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             وضعیت   
          </label>
          <div class="relative mt-3 text-right">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
              <select v-model="allStatus" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                  <option value='Waiting'>Waiting</option>
                  <option value='Accepted'>Accepted</option>
                  <option value='Finished'>Finished</option>
                  <option value='Rejected'>Rejected</option>
                  <option value='CantBeProcessed'>CantBeProcessed</option>
                  <option value=''>--</option>
                  
              </select>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             شماره حساب  
          </label>
          <div class="grid grid-cols-12 mt-3" >
              <input  v-model="allAccountId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
               
           </div>
          
      </div>
      <div class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             شماره حساب آیدی 
          </label>
          <div class="grid grid-cols-12 mt-3" >
              <input  v-model="allTradingAccountId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
               
           </div>
          
      </div>
      <div class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             آیدی یوزر   
          </label>
          <div class="grid grid-cols-12 mt-3" >
              <input  v-model="allUserId"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
               
           </div>
          
      </div>
      
      <div class="col-span-12 lg:col-span-1 m-3 mt-10">
        <button @click.prevent="fetchWithdrawalList" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              فیلتر
                             
                         </button>
      </div>
      </div>
        
        <div v-if="this.selectedTab === 'All'" class="col-span-12 border rounded-lg border-surface-border bg-white p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px]">
            <table class="w-full  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto">
            <thead  class="text-sm text-gray-700 uppercase bg-gray-50 round-10 ">
              <tr>
                 <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                 <th scope="col" class="px-6 py-3 font-iranSans">تاریخ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">شماره حساب</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> مبلغ</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> وضعیت</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> توضیحات</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans"> یوزر</th> 
                 
              </tr>
            </thead> 
            <tbody  class="bg-white border ">
              <tr v-for="(item, index) in withdrawalList" :key="index" class="text-gray-900  hover:bg-gray-200">
                <td class="px-6 py-4">{{ index }}</td>
                <td class="px-6 py-4">{{ item.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) }}</td>
                <td class="px-6 py-4">{{ item.accountId }}</td>
                <td class="px-6 py-4">{{ item.amountToPay }}</td>
                <td class="px-6 py-4">{{ item.status }}</td>
                <td class="px-6 py-4">{{ item.status }}</td>
                <td class="px-6 py-4"><router-link :to="{params: {id:item.userId }, name:'DAdminUserDetails' }" target="_blank"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              اطلاعات یوزر
                             
              </router-link></td>
                
                
                
              </tr>
            </tbody>
          </table>
          <!-- all Pagination -->
          <div class="col-span-12  m-3 mt-10 grid grid-cols-9">
              <button @click.prevent="allNextPage" class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                                    بعدی

              </button>
              <div class=" col-span-1 col-start-5 font-iranSans  duration-300 text-center w-full block border-2 border-green-100 bg-gray-100 focus:bg-green-100 text-indigo-900 text-indigo-900 font-semibold rounded-lg px-4 py-2 mt-2">
                                    {{ this.allPage }}

              </div>
              <button v-if="this.allPage > 1" @click.prevent="allPrePage" class=" col-span-1 col-start-6  font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                                    قبلی

              </button>

      </div>

        </div>
    </div>
    <!-- modal success payment -->
    <div v-if="this.showPaymentSuccess" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                  
                              
                              <div class="flex items-center text-green-800 text-center space-x-4 my-2 ">با موفقیت پرداخت تکمیل شد</div>
                                  
                  </div>
                                  
              <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="paymentSuccess" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    </div>
    <!-- modal error Payment -->
    <div v-if="this.showPaymentError" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                  
                              <div class="flex items-center text-red-800 text-center space-x-4 my-2 ">خطا در پرداخت</div>
                              
                                  
                  </div>
                                  
              <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="paymentError" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    
    
    </div>
    <!-- modal success Accept -->
    <div v-if="this.showAcceptSuccess" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                  
                              
                              <div class="flex items-center text-green-800 text-center space-x-4 my-2 ">با موفقیت تائید  شد</div>
                                  
                  </div>
                                  
              <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="AcceptSuccess" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    </div>
    <!-- modal error Accept -->
    <div v-if="this.showAcceptError" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                  
                              <div class="flex items-center text-red-800 text-center space-x-4 my-2 ">خطا در تائید</div>
                              
                                  
                  </div>
                                  
              <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="AcceptError" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    
    
    </div>
    <!-- modal success Reject -->
    <div v-if="this.showRejectSuccess" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                  
                              
                              <div class="flex items-center text-green-800 text-center space-x-4 my-2 ">با موفقیت پرداخت تکمیل شد</div>
                                  
                  </div>
                                  
              <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="RejectSuccess" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    </div>
    <!-- modal error Reject -->
    <div v-if="this.showRejectError" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center  sm:items-center sm:p-0">
              
              <div class="relative transform overflow-hidden rounded-lg bg-white  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-gray-50 border border-gray-200 rounded-lg shadow sm:p-8 ">
                  
                              <div class="flex items-center text-red-800 text-center space-x-4 my-2 ">خطا در پرداخت</div>
                              
                                  
                  </div>
                                  
              <div class="bg-gray-50 px-4 py-3  sm:flex sm:flex-row-reverse sm:px-6">
                  <button @click="RejectError" type="button" class="inline-flex w-full  justify-center rounded-md bg-green-100 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-200 sm:ml-3 sm:w-auto">بستن</button>
                  
              </div>
              
          </div>
          </div> 
      </div>
    
    
    </div>
    
</template>

<script>
import axios from 'axios';
import URL from "../URL";
export default {
    data(){
        return{
            wallet: '',
            selectedAccount: '',
            accounts: [],
            isChecked: false,
            withdrawalList: [],
            withdrawalWaitingList: [],
            withdrawalAcceptedList: [],

            allDate:'',
            allStatus: '',
            allAccountId: '',
            allTradingAccountId: '',
            allUserId: '',
            allPage: 1,
            allPageSize:10,

            waitingDate:'',
            waitingAccountId: '',
            waitingTradingAccountId: '',
            waitingUserId: '',
            waitingPage: 1,
            waitingPageSize:10,

            acceptedDate:'',
            acceptedAccountId: '',
            acceptedTradingAccountId: '',
            acceptedUserId: '',
            acceptedPage: 1,
            acceptedPageSize:10,

            AcceptedStatus: 'Accepted',
            WaitingStatus: 'Waiting',

            showPaymentError: false,
            showPaymentSuccess: false,
            showAcceptError: false,
            showAcceptSuccess: false,
            showRejectError: false,
            showRejectSuccess: false,

            selectedTab: 'All'



        }
    },
    mounted() {
    // Fetch the accounts data from the API and populate the accounts array
    
    this.fetchWithdrawalList();
    this.fetchWithdrawalWaitingList();
    this.fetchWithdrawalAcceptedList();
    },
    methods: {
        
        acceptWithdraw(index){
          const url = URL.baseUrl + `Admin/withdraw/${this.withdrawalWaitingList[index].uId}/Accept`;
                axios.put(url, {}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then(response => {
          // Handle successful response here
          console.log(response.data);
          this.fetchWithdrawalList();
          
          this.fetchWithdrawalWaitingList();
          
          this.fetchWithdrawalAcceptedList();
          this.AcceptSuccess();

        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.AcceptError();
        });
        },
        rejectWithdraw(index){
          const url = URL.baseUrl + `Admin/withdraw/${this.withdrawalWaitingList[index].uId}/Reject`
                axios.put(url, {}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then(response => {
          // Handle successful response here
          console.log(response.data);
          this.fetchWithdrawalList();
          
          this.fetchWithdrawalWaitingList();
          
          this.fetchWithdrawalAcceptedList();
          this.RejectSuccess();

        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.RejectError();
        });
        },
        fetchWithdrawalList(){
          const url = URL.baseUrl + `Admin/Withdraw?Date=${this.allDate}&status=${this.allStatus}&AccountId=${this.allAccountId}&TradingAccountId=${this.allTradingAccountId}&UserId=${this.allUserId}&Page=${this.allPage}&PageSize=${this.allPageSize}`
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.withdrawalList = response.data;
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });

        },
        fetchWithdrawalWaitingList(){
          const url = URL.baseUrl + `Admin/Withdraw?Date=${this.waitingDate}&status=${this.WaitingStatus}&AccountId=${this.waitingAccountId}&TradingAccountId=${this.waitingTradingAccountId}&UserId=${this.waitingUserId}&Page=${this.waitingPage}&PageSize=${this.waitingPageSize}`
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.withdrawalWaitingList = response.data;
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });

        },
        fetchWithdrawalAcceptedList(){
          const url = URL.baseUrl + `Admin/Withdraw?Date=${this.acceptedDate}&status=${this.AcceptedStatus}&AccountId=${this.acceptedAccountId}&TradingAccountId=${this.acceptedTradingAccountId}&UserId=${this.acceptedUserId}&Page=${this.acceptedPage}&PageSize=${this.acceptedPageSize}`
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.withdrawalAcceptedList = response.data.map(withdraw => {
                return {
                  ...withdraw,
                  enteredHash:'',
                }
              })
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });

        },
        finishWithdraw(index) {
   // POST request using axios with error handling
   const url = URL.baseUrl + `Admin/withdraw/Finish`
         axios.put(url,
         { 
            
             
             broker: this.broker,
             withdrawRequestId: this.withdrawalAcceptedList[index].uId,
             payHash: this.withdrawalAcceptedList[index].enteredHash,
             },
             {
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   }
             
         
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
              this.fetchWithdrawalList();
              this.fetchWithdrawalWaitingList();
              this.fetchWithdrawalAcceptedList();
              this.paymentSuccess();

             
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             this.paymentError();
              
             });
 },
  paymentError(){
    this.showPaymentError = !this.showPaymentError

   
  },
  paymentSuccess(){
    this.showPaymentSuccess = !this.showPaymentSuccess;

   
  },
  AcceptError(){
    this.showAcceptError = !this.showAcceptError;
    console.log('accept Erorrrrr')

   
  },
  AcceptSuccess(){
    this.showAcceptSuccess = !this.showAcceptSuccess;
    console.log('accept Succsessss')

   
  },
  RejectError(){
    this.showRejectError = !this.showRejectError;
    console.log('Reject Erorrrrr')

   
  },
  RejectSuccess(){
    this.showRejectSuccess = !this.showRejectSuccess;
    console.log('accept Succsessss')

   
  },
  tabButtonClick(value){
             this.selectedTab = value;
  },
  allNextPage(){
        this.allPage = this.allPage + 1;
        this.fetchWithdrawalList();
        
  },
  allPrePage(){
        this.allPage = this.allPage - 1;
        this.fetchWithdrawalList();
  },
  waitingNextPage(){
        this.waitingPage = this.waitingPage + 1;
        this.fetchWithdrawalWaitingList();
    
        
  },
  waitingPrePage(){
        this.waitingPage = this.waitingPage - 1;
        this.fetchWithdrawalWaitingList();
  },
    
  acceptedNextPage(){
        this.acceptedPage = this.acceptedPage + 1;
        this.fetchWithdrawalAcceptedList();
        
        
  },
  acceptedPrePage(){
        this.acceptedPage = this.acceptedPage - 1;
        this.fetchWithdrawalAcceptedList();
        
  },
  
  


    },
    
}
</script>