<template>
  <canvas ref="chartCanvas"></canvas>
</template>

<script>
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,LineController, PointElement,LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,LineController, PointElement,LineElement)


export default {
  name: 'BarChart',
  props: {
    tradeHistory: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.createChartData(this.tradeHistory);
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    createChartData(tradeHistory) {
      const profitData = [];
      const drawdownData = [];

      for (let i = 0; i < tradeHistory.length; i++) {
        if (i !=0){

          const trade = tradeHistory[i];
          profitData.push(trade.totalProfitInPercent);
          drawdownData.push(-trade.maxDrawDownInPercent);
        }
      }

      this.renderChart(profitData, drawdownData);
    },
    renderChart(profitData, drawdownData) {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new ChartJS(ctx, {
        type: 'bar',
        data: {
          labels: this.getLabelArray(),
          datasets: [
            {
              label: 'Profit (%)',
              data: profitData,
              backgroundColor: '#84FFAB',
              borderColor: '#84FFAB',
              borderWidth: 1,
              borderRadius: 10,
            },
            {
              label: 'Drawdown (%)',
              data: drawdownData,
              backgroundColor: "#FF7070",
              borderColor: "#FF7070",
              borderWidth: 1,
              borderRadius: 10,
            },
          ],
        },
        options: {
          
          aspectRatio: 1.6,
          scales: {
      y: {
        axisBorder: {
        show: true, 
        color: '#d1d1cf', // Change this to the color you want for y-axis line
      },
        ticks: { color: '#d1d1cf', beginAtZero: true }
      },
      x: {
        axisBorder: {
        show: true,
        color: '#d1d1cf', // Change this to the color you want for y-axis line
      },
        ticks: { color: '#d1d1cf', beginAtZero: true }
      }
    }
        },
      });
    },
    getLabelArray() {
      const labels = [];
      for (let i = 1; i < this.tradeHistory.length; i++) {
        labels.push(i);
      }
      return labels;
    },
    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
  watch: {
    tradeHistory(newValue) {
      this.createChartData(newValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>
    
        