<template>
    <div class="grid grid-cols-12 m-2">
      <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>لیست تیکت  </p>
    </div>
    <div class="col-span-12 grid grid-cols-12 bg-surface-card border border-surface-border p-4 sm:m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      
      <div class="sm:col-span-4 col-span-12 mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> Page</label>
        <input v-model="Page" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12 mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> LastModifiedDate</label>
        <input v-model="LastModifiedDate" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12 mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> CreateDate</label>
        <input v-model="CreateDate" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12 mx-1">
        <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 "> UserId</label>
        <input v-model="UserId" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
      </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">State</label>
          <select v-model="State" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
            
            <option value=''>null</option>
            <option value="Open">Open</option>
            <option value="Closed">Close</option>
            
          </select>
        </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">Priority</label>
          <select v-model="Priority" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
            
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
            <option value="Urgent">Urgent</option>
            
          </select>
        </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">SeenByUser</label>
          <select v-model="SeenByUser" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
            
            <option value=true>true</option>
            <option value=false>false</option>
  
            <option value=null>null</option>
           
            
          </select>
        </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">SeenByAdmin</label>
          <select v-model="SeenByAdmin" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
            
            <option value=true>true</option>
            <option value=false>false</option>
  
            <option value=null>null</option>
           
            
          </select>
        </div>
      <div class="sm:col-span-4 col-span-12  mx-1 mt-1">
          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">PageSize</label>
          <select v-model="SeenByAdmin" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
            
            <option value=10>10</option>
            <option value=20>20</option>
  
            <option value=40>40</option>
           
            
          </select>
        </div>
        <div class="sm:col-span-4 col-span-12  mx-1 mt-6">
          
          <button @click="fetchTicketList" class="block w-full p-1  text-white border border-gray-300 rounded-lg bg-green-100 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">فیلتر</button>
        </div>
    </div>
        
        
        <div class=" col-span-12 border rounded-lg border-surface-border  bg-white my-1 sm:p-4 p-2  sm:m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] ">
            <div class="w-full  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto">
            <div  class="text-xs  text-gray-700 uppercase bg-gray-50 round-10 ">
                
              <div class="grid grid-cols-12">
                 <p scope="col" class="hidden sm:block py-3 col-span-1 font-iranSans">#</p>
                 <p scope="col" class=" py-3 col-span-1 font-iranSans">O</p>
                 <p scope="col" class="hidden sm:block py-3 col-span-2 font-iranSans">تاریخ</p> 
                 <p scope="col" class="hidden sm:block py-3 col-span-1 font-iranSans">یوزرنیم</p> 
                 <p scope="col" class="py-3 col-span-3 font-iranSans">عنوان</p> 
                 <!-- <p scope="col" class="hidden sm:block py-3 col-span-1 font-iranSans">اهمیت</p>  -->
                 <p scope="col" class="hidden sm:block py-3 col-span-1 font-iranSans">وضعیت</p> 
                 <p scope="col" class="py-3 sm:col-span-1 col-span-6 font-iranSans">یوزر</p> 
                 
              </div>
            </div> 
            <div  class="bg-white border-b text-xs  ">
              <div  v-for="(tick, index) in ticketList" :key="index" class="text-gray-900 grid grid-cols-12  hover:bg-gray-200">
                <p class="hidden sm:block py-1 col-span-1">{{ index }}</p>
                <p class=" py-1 col-span-1"><span v-if="tick.seenByAdmin
                 == false" class="relative justify-self-center mx-2 flex h-3 w-3 mr-auto">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75 mr-auto"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-600 mr-auto"></span>
                  </span></p>
                <p class="hidden sm:block py-1 col-span-2">{{ tick.createDateTime.replace("T", " در ").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,'') }}</p>
                <p class="hidden sm:block py-1 col-span-1">{{ tick.userName }}</p>
                <p class=" py-1 col-span-3">{{ tick.title }}</p>
                <!-- <p class="hidden sm:block py-1 col-span-1">{{ tick.priority }}</p> -->
                <p v-if="tick.state == 'Open'" class="hidden sm:block py-2 bg-green-800 bg-opacity-80 text-white text-center my-1 mt-2 mx-2 rounded-lg col-span-1">باز</p>
                <p v-if="tick.state == 'Closed'" class="hidden sm:block py-2 bg-red-800 bg-opacity-80 text-white text-center my-1 mt-2 mx-2 rounded-lg col-span-1">بسته</p>
                <p class=" py-1 sm:col-span-1 col-span-3"><router-link :to="{params: {id:tick.userId }, name:'DAdminUserDetails' }"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-1 py-1 mt-2">
                            یوزر
                             
                </router-link></p>
                <p class=" py-1 mx-1 sm:col-span-1 col-span-3"><Button @click="changeShowChat(index)"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-1 py-1 mt-2">
                            چت
                             
                </Button></p>
                <p class=" py-1 mx-1 sm:col-span-1 col-span-3">
                  <Button v-if="tick.state=='Open'" @click="Closeticket(tick.uId,'Closed')"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-red-800   text-white font-semibold rounded-full  py-1 mt-2">
                            Close   
                  </Button>
                  <Button v-if="tick.state=='Closed'" @click="Closeticket(tick.uId,'Open')"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-green-800   text-white font-semibold rounded-full  py-1 mt-2">
                            Open    
                  </Button>
              </p>
                <div v-if="tick.showChat" class="col-span-12 bg-gray-100"> 

                    <div class="col-span-8 my-4 flex flex-col  items-center justify-center  text-gray-800 ">

<!-- Component Start -->
            <div  class="flex flex-col flex-grow w-full h-96  bg-white shadow-xl rounded-lg  overflow-hidden" id="myDiv">
                <div  class="flex flex-col flex-grow h-0 p-4 pl-2  overflow-auto">
                    
                   <div v-for="(msg, index2) in ticketChat" :key="index2" >
                       <div v-if="thisIsMyUId != msg.userId" class="flex w-full mt-2  space-x-3 mr-auto justify-end  max-w-lg">
                           <div>
                               <div class="bg-gray-300 p-3  max-w-lg  rounded-xl ml-2">
                                   <p class=" text-ellipsis overflow-hidden text-xs " v-html="formatMessage(msg.message)" ></p>
                               </div>
                               <span class="text-xs text-gray-500 leading-none">{{( msg.createDateTime.replace("T", "در").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,''))}}</span>
                           </div>
                           <div class="hidden lg:block flex-shrink-0 h-10 w-10  rounded-full bg-gray-300"></div>
                       </div>
   
                       <div v-if="thisIsMyUId == msg.userId" class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-start">
                           <div class=" hidden lg:block flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                           <div>
                               <div class="bg-astro-400 text-white p-3 rounded-2xl mr-2 ">
                                   <p class=" text-ellipsis overflow-hidden text-xs" v-html="formatMessage(msg.message)"></p>
                               </div>
                               <span class="text-xs text-gray-500 leading-none">{{( msg.createDateTime.replace("T", "در").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,''))}}</span>
                           </div>
                       </div>

                   </div>                      

                </div> 
                

                    
                    
                    
                
                
                <div class=" grid grid-cols-12 p-2">
                    <form class="col-span-12">
                        <div class="grid grid-cols-12  ">
                            <textarea v-if="ticketChat[0]" v-model="messageText" id="chat" rows="1" class="block mx-1 sm:col-span-10 col-span-12  p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>
                            <select v-model="ticketList[index].state" id="small" class="block w-full p-1 sm:col-span-1 col-span-6   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs  ">
                            <option value="Open">Open</option>
                            <option value="Closed">Closed</option>
                            
                          </select>
                                <button v-if="!sending" @click.prevent="SendMessage(index)" class="inline-flex sm:col-span-1 col-span-6 justify-center p-2 text-green-100 rounded-full cursor-pointer hover:bg-green-100 hover:text-white  ">
                                    <svg  v-if="ticketChat[0]" class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                                <button v-if="sending"  class="bg-blue-700 inline-flex sm:col-span-1 col-span-6 justify-center p-2 text-blue-100 rounded-full cursor-pointer hover:text-white  ">
                                    <svg  v-if="ticketChat[0]" class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                        </div>
                    </form>
                </div>
              </div>
              
              <!-- Component End  -->
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12  m-3  grid grid-cols-9">
  <button @click.prevent="nextPage" class="sm:col-span-1 col-span-3 sm:col-start-4 font-iranSans text-xs  hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-2 py-1 mt-2">
                      بعدی
                     
  </button>
  <div class=" sm:col-span-1 col-span-3 sm:col-start-5 font-iranSans text-xs  duration-300 text-center w-full block border-2 border-green-100 bg-gray-100 focus:bg-green-100 text-indigo-900 text-indigo-900 font-semibold rounded-lg px-2 py-1 mt-2">
                      {{ this.Page }}
                     
  </div>
  <button v-if="this.Page > 1" @click.prevent="prePage" class=" sm:col-span-1 col-span-3 sm:col-start-6 text-xs  font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-2 py-1 mt-2">
                      قبلی
                     
  </button>
  
  </div>
  </div>
    </div>
</template>


<script>
import axios from 'axios';
import URL from "../URL";
export default {
    data(){
        return{
            ticketList:[],
            ticketChat:[],

            messageText: '',
            thisIsMyUId: '',
            

            sending: false,
            
            LastModifiedDate: '',
            CreateDate: '',
            State: 'Open',
            Category: null,
            Priority: null,
            SeenByUser: null,
            SeenByAdmin: false,
            UserId: '',
            SortBy: null,
            Page: 1,
            PageSize:10,
        }
    },
    mounted(){
        this.fetchTicketList();
        this.checkToken();
    },
    methods:{
        Closeticket(uid,state){
          const url = URL.baseUrl + `Admin/ticket/${uid}/state`
        axios.put(url, state,
        
             {
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   }
             
         
         )
         .then(response => {
             // Handle successful response here
             this.fetchTicketChat();
         })
             
 
             .catch(error => {
              this.errorMessage = error.message;
             });

        },
        formatMessage(message) {
          return message.replace(/\n/g, '<br>');
        },
        changeShowChat(index){
            
            this.fetchTicketChat(index);

        },

        fetchTicketList(){
            let url = URL.baseUrl + `Admin/ticket?Page=${this.Page}&PageSize=${this.PageSize}`;
            
            if (this.CreateDate){
              url = url + `&CreateDate=${this.CreateDate}`
            }
            if (this.LastModifiedDate){
              url = url + `&LastModifiedDate=${this.LastModifiedDate}`
            }
            if (this.State){
              url = url + `&State=${this.State}`
            }
            if (this.Category){
              url = url + `&Category=${this.Category}`
            }
            if (this.Priority){
              url = url + `&Priority=${this.Priority}`
            }
            if (this.SeenByUser){
              url = url + `&SeenByUser=${this.SeenByUser}`
            }
            if (this.SeenByAdmin){
              url = url + `&SeenByAdmin=${this.SeenByAdmin}`
            }
            if (this.UserId){
              url = url + `&UserId=${this.UserId}`
            }
            if (this.SortBy){
              url = url + `&SortBy=${this.SortBy}`
            }
            
    
            axios.get(url,
             
                 {
                 headers: {
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + localStorage.getItem('token')
                 }
               }        
             )
             .then(response => {
                 // Handle successful response here
                 
                  
                  this.ticketList = response.data.map(ticket => {
                    return {
                        ...ticket,
                        showChat: false,
                        
                        
                    };
        });
            
             })
                 
     
                 .catch(error => {
                 this.errorMessage = error.message;
                 this.ticketList = [],
                 console.error("There was an error!", error);
                 });
    
        },
        fetchTicketChat(index){
            const url = URL.baseUrl + `Admin/ticket/${this.ticketList[index].uId}`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.ticketChat = response.data;
              this.ticketList[index].showChat = !this.ticketList[index].showChat;
              
            
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            
            });
        },
        checkToken(){
         const url = URL.baseUrl + `users/whoami`;
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            this.thisIsMyUId = response.data.uId;
          
          
          // age az accountList omade bud
        })
        .catch(error => {
          console.error(error);
          


        });
      },
      SendMessage(index) {
            
            this.sending = true;
            const url = URL.baseUrl + `Admin/ticket/message`;
            axios.post(url,
            { 
               
                
                ticketThreadId: this.ticketList[index].uId,
                message: this.messageText,
                state: this.ticketList[index].state,
                
                },
                {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
               }
             }
                
            
            )
            .then(response => {
                // Handle successful response here
                console.log(response.data);
                this.messageText = '';
                
                this.fetchTicketChat(index);
                this.sending = false;
            })
                
    
                .catch(error => {
                this.errorMessage = error.message;
                
                this.sending = false;

                
                });
    },
    nextPage(){
        this.Page = this.Page + 1;
        this. fetchTicketList();
        
      },
      prePage(){
        this.Page = this.Page - 1;
        this. fetchTicketList();
      },
    }
    
}
</script>