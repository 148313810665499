

<template>
  <body>

    <header class=" w-full">
      <nav class="bg-TFL-background border-gray-200 py-2.5   z-50">
        <div class="flex justify-between max-w-screen-xl px-4 mx-auto justify-self-center">
          <div class=" flex items-stretch ">

            <a v-if="SignInButton" href="/Login" class="text-white bg-TFL-base1 self-center  self-center focus:ring-4  font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0  focus:outline-none font-iranSans lg:w-40 text-center"> ورود</a>
            <a v-if="!SignInButton && UserDashboardButton" href="/Dashboard/Home" class="text-white bg-TFL-base1  self-center  focus:ring-4  font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0  focus:outline-none font-iranSans lg:w-40 text-center"> داشبورد</a>
            <a v-if="!SignInButton && !UserDashboardButton" href="/AdminDashboard/Home" class="text-white bg-TFL-base1 self-center  focus:ring-4  font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0  focus:outline-none font-iranSans lg:w-40 text-center">   داشبورد</a>
           
          </div>
          
              <div class=" flex items-stretch    ">
                
                  
                  
                    <div class=" self-center grid grid-cols-3   font-medium ">
                      <div class="col-span-1 text-left">
                        <router-link :class="{ 'text-gray-300': $route.path === '/', 'hover-text-TFL-base1': $route.path !== '/' }" class="block self-center sm:text-base text-sm font-iranSans mx-2 text-left  text-gray-300 rounded lg:bg-transparent hover:text-TFL-base1 lg:text-gray-300 lg:p-0 " aria-current="page" to="/">خانه</router-link>
                      </div>
                      
                      <div class="col-span-1 text-right" >
                        <router-link :class="{ 'text-gray-300': $route.path === '/Rules', 'hover-text-TFL-base1': $route.path !== '/Rules' }" class="block sm:text-base text-sm self-center font-iranSans mx-2 text-right  text-gray-300 rounded lg:bg-transparent hover:text-TFL-base1 lg:text-gray-300 lg:p-0 " aria-current="page" to="/Rules">قوانین</router-link>
                      </div>
                      <div class="col-span-1 text-right" >
                        <router-link :class="{ 'text-gray-300': $route.path === '/Brokers', 'hover-text-TFL-base1': $route.path !== '/Brokers' }" class="block sm:text-base text-sm self-center font-iranSans mx-2 text-right  text-gray-300 rounded lg:bg-transparent hover:text-TFL-base1 lg:text-gray-300 lg:p-0 " aria-current="page" to="/Brokers">بروکرها</router-link>
                      </div>
                    </div>
                  
                
              </div>
              <div class=" flex">
                
                <span class="inline-block align-bottom font-Montserrat text-white hidden sm:block text-2xl my-3 mx-2"><span class="text-TFL-base1">TFL</span>.fund</span>
                <a href="#" class="justify-self-end flex items-center">
                  <img src="../assets/images/TFL-Logo.svg" class="h-12">
                </a>
              </div>
         </div>
        </nav>
                </header>
                <router-view ></router-view>
              </body>
              </template>

<script>
import axios from 'axios';
import URL from "../URL";
import siteKEY from '../siteKEY';

export default {
   data(){     
      return{
         SignInButton: true,
         UserDashboardButton: true,


      }
   },
   mounted(){
      this.checkToken();

      
   },
   methods: {
      openProfileIcon(){
         this.isOpen = !this.isOpen;
      },
      logout(){
         localStorage.removeItem('token');
         window.location.href = '/Login';
      },
      checkToken(){
        const url = URL.baseUrl + `users/whoami`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         
         this.whoami();

         this.SignInButton = false;

          
          
          
        })
        .catch(error => {
          
          this.SignInButton = true;


        });
      },
      whoami(){
        const url = URL.baseUrl + `users/roles`
      axios.get(url, {
                headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
              const data = response.data
              if (Array.isArray(data) && data.length === 0) {
                this.UserDashboardButton =true;
              } else if (Array.isArray(data) && data.includes('Admin')) {
                this.UserDashboardButton =false;
              } else {
                // Handle invalid response
              }
            })
            .catch(error => {
                
            });
    },

   }
}
</script>





<style lang="scss" scoped></style>
