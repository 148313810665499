<template>
    <div id="appl">
      <div id="chart" v-if="series.length > 0">
        <apexchart width="100%" type="line" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue3-apexcharts';
  import axios from 'axios';
  import URL from "../URL";
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        accountuId: this.$route.params.id,
        EquityData: [],
        EquityDataMax: [],
        options: {
          chart: {
            id: 'vuechart-example',
            animations: {
          enabled: false,
        },
          },
          xaxis: {
            type: 'datetime',
            labels: {
              style: {
                colors: '#d1d1cf', // Change this to the color you want for x-axis labels
              },
            },
          },
          yaxis: {
          labels: {
            offsetX: -50,
            style: {
                colors: '#d1d1cf', // Change this to the color you want for x-axis labels
              },
          },
        },
        stroke: {
          curve: 'smooth',
        },

          legend: {
            show: true,
            showForSingleSeries: true,
          },
          grid: {
    borderColor: '#666666', // Change this to the color you want for grid lines
  },
          colors: ['#84FFAB','#FF7070'],
        },
        series: [],
      };
    },
    methods: {
      fetchEquityData() {
        const accountId = this.accountuId;
        const timeFrame = 'D1';
        const indicator = 'Min';
        const parameter = 'Equity';
  
        const url = URL.baseUrl + `Meta/account/history?AccountId=${accountId}&TimeFrame=${timeFrame}&Indicator=${indicator}&Parameter=${parameter}`;
  
        axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then(response => {
            this.EquityData = response.data;
            this.$emit('equityloaded', this.EquityData);
  
            console.log(this.EquityData);
            console.log("Equity Data Loaded");
  
            this.updateSeries();
          })
          .catch(error => {
            console.error(error);
          });
      },
      fetchEquityDataMax() {
        const accountId = this.accountuId;
        const timeFrame = 'D1';
        const indicator = 'Max';
        const parameter = 'Equity';
  
        const url = URL.baseUrl + `Meta/account/history?AccountId=${accountId}&TimeFrame=${timeFrame}&Indicator=${indicator}&Parameter=${parameter}`;
  
        axios.get(url, {
            headers: {
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
          })
          .then(response => {
            this.EquityDataMax = response.data;
            this.$emit('equityloaded', this.EquityDataMax);
  
            console.log(this.EquityDataMax);
            console.log("Equity Data Max Loaded");
  
            this.updateSeries();
          })
          .catch(error => {
            console.error(error);
          });
      },
      updateSeries() {
        if (this.EquityData.length > 0 && this.EquityDataMax.length > 0) {
          this.EquityData.forEach((item, index) => {
            item.valueMax = this.EquityDataMax[index].value;
          });
  
          const seriesData = this.EquityData.map((item) => [item.label, item.value.toFixed(1)]);
          const seriesDataMax = this.EquityData.map((item) => [item.label, item.valueMax.toFixed(1)]);
  
          this.series = [
            {
              name: 'Maximum Daily Equity',
              data: seriesDataMax,
            },
            {
              name: 'Minimum Daily Equity',
              data: seriesData,
            },
          ];
        }
      },
    },
    mounted() {
      this.fetchEquityData();
      this.fetchEquityDataMax();
    },
  };
  </script>


