<template>
    <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
    />
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
  
  export default {
    name: 'BarChart',
    components: { Bar },
    data() {
      return {
        chartData: {
          labels: [ '1', '2', '3'  ],
          datasets: [ { label: 'Profit / Loss', backgroundColor: '#6ebd44', data: [0.8 , -1.5 , 5.3] },{ label: 'Daily DrawDown', backgroundColor: '#9344BD',data: [-0.5 , -2.2 , -0.3] } ]
        },
        chartOptions: {
          responsive: true
        }
      }
    }
  }
  </script>