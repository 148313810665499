import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DAnalysis from '../views/DAnalysis.vue'
import DProfile from '../views/DProfile.vue'
import DSupport from '../views/DSupport.vue'
import DWhitdrawal from '../views/DWhitdrawal.vue'
import DAccountList from '../views/DAccountList.vue'
import NewChallenge from '../views/NewChallenge.vue'
import DHome from '../views/DHome.vue'
import DTickets from '../views/DTickets.vue'
import DReferral from '../views/DReferral.vue'

import LandingPage from '../views/LandingPage.vue'
import Dashboard from '../views/Dashboard.vue'


import AppLayout from '../layout/AppLayout.vue'
import AppAdminLayout from '../layout/AppAdminLayout.vue'
import AppLanding from '../layout/AppLanding.vue'
//Landing
import Home from '../views/Home.vue'
import Rules from '../views/Rules.vue'
import Brokers from '../views/Brokers.vue'
import FAQ from '../views/FAQ.vue'
import Login from '../views/Login.vue'
import confirmEmail from '../views/confirmEmail.vue'
import Signup from '../views/Signup.vue'
import forgetPass from '../views/forgetPass.vue'
import resetPassword from '../views/resetPassword.vue'

//import Rules from '../views/Rules.vue'
//import FAQ from '../views/FAQ.vue'

//Admin
import AdminLogin from '../views/AdminLogin.vue'
import DAHome from '../views/DAHome.vue'
import DAdminWithdrawal from '../views/DAdminWithdrawal.vue'
import DAdminUsers from '../views/DAdminUsers.vue'
import DAdminUserDetails from '../views/DAdminUserDetails.vue'
import DAdminTradingAccounts from '../views/DAdminTradingAccounts.vue'
import DiscountCode from '../views/DiscountCode.vue'
import DSuccessPayment from '../views/DSuccessPayment.vue'
import ATicket from '../views/ATicket.vue'
import DAdminAnalysis from '../views/DAdminAnalysis.vue'
import AVerification from '../views/AVerification.vue'

import page404 from '../views/page404.vue'
import LandingHome from '@/views/LandingHome.vue'




const routes =  [
  {
    path: '/Dashboard',
    component: AppLayout,
    children: [ 
      
      {
        path: '/Dashboard/Home',
        name: 'DHome',
        component: DHome
      },
      {
        path: '/Dashboard/Analysis/:id',
        name: 'DAnalysis',
        component: DAnalysis
      },
      {
        path: '/Dashboard/Profile',
        name: 'DProfile',
        component: DProfile
      },
      {
        path: '/Dashboard/DSupport',
        name: 'DSupport',
        component: DSupport
      },
      {
        path: '/Dashboard/Dwithdrawal',
        name: 'DWhitdrawal',
        component: DWhitdrawal
      },
      {
        path: '/Dashboard/DAccountList',
        name: 'DAccountList',
        component: DAccountList
      },
      {
        path: '/Dashboard/NewChallenge',
        name: 'NewChallenge',
        component: NewChallenge
      },
      {
        path: '/Dashboard/Tickets',
        name: 'DTickets',
        component: DTickets
      },
      {
        path: '/Dashboard/Profile',
        name: 'DProfile',
        component: DProfile
      },
      {
        path: '/Dashboard/Referral',
        name: 'DReferral',
        component: DReferral
      },
      
    ]
  },
  {
    path: '/',
    component: AppLanding,
    children: [
      {
        path: '/',
        name: 'Home',
        component: LandingHome
      },
      {
        path: '/Rules',
        name: 'Rules',
        component: Rules
      },
      {
        path: '/Brokers',
        name: 'Brokers',
        component: Brokers
      },
      {
        path: '/FAQ',
        name: 'FAQ',
        component: FAQ
      },
      
    ]
    
  },
  {
    path: '/SuccessPayment',
    name: 'DSuccessPayment',
    component: DSuccessPayment,
    props: (route) => ({
      Authority: route.query.Authority,
      Status: route.query.Status
    })
  },
  {
    path: '/Login',
    name:'Login',
    component: Login
     

  },
  {
    path: '/confirmEmail',
    name:'confirmEmail',
    component: confirmEmail
     

  },
  {
    path: '/forgetPass',
    name:'forgetPass',
    component: forgetPass
     

  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: resetPassword,
    props: (route) => ({
      email: route.query.email,
      token: route.query.token
    })
  },

  
  {
    path: '/Signup',
    name:'Signup',
    component: Signup
     

  },
  {
    path: '/404',
    name:'page404',
    component: page404
     

  },
  {
    path: '/:catchAll(.*)',
    redirect: (to) => {
      if (to.path === '/blog') {
        return '/blog';
      } else {
        return '/404';
      }
    }
  },
  // {
  //   path: '/Adminlogin',
  //   name:'AdminLogin',
  //   component: AdminLogin

  // },
  {
    path: '/AdminDashboard',
    component: AppAdminLayout,
    children: [ 
      
      {
        path: '/AdminDashboard/Home',
        name: 'DSHome',
        component: DAHome
      },
      {
        path: '/AdminDashboard/Withdrawal',
        name: 'DAdminWithdrawal',
        component: DAdminWithdrawal
      },
      {
        path: '/AdminDashboard/TradingAccounts',
        name: 'DAdminTradingAccounts',
        component: DAdminTradingAccounts
      },
      {
        path: '/AdminDashboard/UserDetails/:id',
        name: 'DAdminUserDetails',
        component: DAdminUserDetails
      },
      {
        path: '/AdminDashboard/Users',
        name: 'DAdminUsers',
        component: DAdminUsers
      },
      {
        path: '/AdminDashboard/DiscountCode',
        name: 'DiscountCode',
        component: DiscountCode
      },
      {
        path: '/AdminDashboard/ATicket',
        name: 'ATicket',
        component: ATicket
      },
      {
        path: '/AdminDashboard/DAdminAnalysis/:id',
        name: 'DAdminAnalysis',
        component: DAdminAnalysis
      },
      {
        path: '/AdminDashboard/AVerification',
        name: 'AvVerification',
        component: AVerification
      },
     
    ]
  },

  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router