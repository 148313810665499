<template>
  <canvas ref="chartCanvas"></canvas>
</template>

<script>
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,LineController, PointElement,LineElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,LineController, PointElement,LineElement)



export default {
  name: 'BarChart',
  props: {
    EquityData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.createChartData(this.EquityData);
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    createChartData(EquityData) {
      const xEquityData = [];
      const yEquityData = [];

      for (let i = 0 ; i < EquityData.length; i++) {
        const EData = EquityData[i];
    const timestamp = EData.label.slice(0,5);
    const date = timestamp // Extracting characters 7 to 10 (inclusive)

    xEquityData.push(date);
    yEquityData.push(EData.value);
      }  

      this.renderChart(xEquityData, yEquityData);
    },
    renderChart(xEquityData, yEquityData) {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new ChartJS(ctx, {
        type: 'line',
        data: {
          labels: xEquityData,
          datasets: [
            {
              label: 'Min D1 Equity',
              data: yEquityData,
              borderColor: 'rgba(110, 189, 68, 1)',
              borderWidth: 3,
              fill: false,
              tension: 0.4
            },
            
          ],
        },
        options: {
          aspectRatio: 2,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
  watch: {
    EquityData(newValue) {
      this.createChartData(newValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>