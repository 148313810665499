<template>
   <div class="grid grid-cols-12 ">
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>کد تخفیف  </p>
    </div>
    
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p> ایجاد کد تخفیف  </p>
      <div class="grid grid-cols-10">
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">کد</label>
          <input v-model="code" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">درصد تخفیف</label>
          <input v-model="percent" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">حداکثر مبلغ تخفیف</label>
          <input v-model="maxAmount" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">حداکثر تعداد تخفیف</label>
          <input v-model="useLimit" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">انقضا</label>
          <input v-model="expireTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-4 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">کامنت</label>
          <input v-model="comment" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class=" col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">پلن</label>
          <div v-for="option in plansOptions" :key="option.text">
            <input type="checkbox" :value="option.text" v-model="plans">
            {{ option.text }}
          </div>
          
        </div>
        <div class=" col-span-12  mx-1">
          
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">دیپازیت</label>
          <div v-for="option1 in DepositsOptions" :key="option1.text">
            <input type="checkbox" :value="option1.text" v-model="deposits">
            {{ option1.text }}
          </div>
         
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
            <button v-if="createDiscountResponse" @click.prevent="createDiscountCode" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-5">
                          ایجاد تخفیف
                         
            </button>
            <button v-if="!createDiscountResponse"  class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-5">
              <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                         
            </button>
      </div>
      </div>
    </div>
    <div class="col-span-12 bg-surface-card border border-surface-border p-2 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>  لیست کد تخفیف   </p>

    
    <div class="grid grid-cols-12">
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Uid</label>
          <input v-model="Uid" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Id</label>
          <input v-model="Id" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Code</label>
          <input v-model="Code2" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <!-- <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Comment</label>
          <input v-model="Comment2" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">Percent</label>
          <input v-model="Percent2" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">maxAmount</label>
          <input v-model="maxAmount2" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">UserId</label>
          <input v-model="UserId2" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">UseLimit</label>
          <input v-model="UseLimit2" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">UsedCount</label>
          <input v-model="UsedCount" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">FromExpireTime</label>
          <input v-model="FromExpireTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ToExpireTime</label>
          <input v-model="ToExpireTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">FromLastUsedDateTime</label>
          <input v-model="FromLastUsedDateTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1">
          <label for="small-input" class="block mb-1 text-sm font-medium text-gray-900 ">ToLastUsedDateTime</label>
          <input v-model="ToLastUsedDateTime" type="text" id="small-input" class="block w-full p-1  text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
        </div>
        <div class="md:col-span-2 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">QueryUserId</label>
        <select v-model="QueryUserId" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value=true>True</option>
          <option value=false>False</option>
          
        </select>
      </div>
        <div class="md:col-span-2 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">QueryPercent</label>
        <select v-model="QueryPercent" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value=true>True</option>
          <option value=false>False</option>
          
        </select>
      </div>
        <div class="md:col-span-2 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">QueryMaxAmount</label>
        <select v-model="QueryMaxAmount" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value=true>True</option>
          <option value=false>False</option>
          
        </select>
      </div> -->
        <div class="md:col-span-2 col-span-12  mx-1 mt-1">
        <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">Expired</label>
        <select v-model="Expired" id="small" class="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 ">
          <option selected>--</option>
          <option value=true>True</option>
          <option value=false>False</option>
          
        </select>
      </div>
      <div class="md:col-span-2 col-span-12  mx-1">
            <button @click.prevent="fetchDiscountCodeList" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-5">
                          فیلتر 
                         
            </button>
      </div>
     
    </div>
    <div class="col-span-12 overflow-auto bg-surface-card border border-surface-border mt-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <table class="w-full overflow-auto  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto" >
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 round-10 ">
              <tr >
                <th scope="col" class="px-6 py-3  font-iranSans">id</th>
                <th scope="col" class="px-6 py-3  font-iranSans">code</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">User</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">referral</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">maxAmount</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">useLimit</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">usedCount</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">expireTime</th> 
                <th scope="col" class="px-6 py-3  font-iranSans">expired</th> 
                
                <th scope="col" class="px-6 py-3  font-iranSans">createDateTime</th> 
                
                
                <th scope="col" class="px-6 py-3  font-iranSans">delete</th>
                 
              </tr>
            </thead> 
            <tbody  class="bg-white border-b   ">
              <tr v-for="(discount, index) in Discounts" :key="index" class="text-gray-900  items-center hover:bg-gray-200">
                
                <td class="px-6 py-2">{{ discount.id }}</td>
                <td class="px-6 py-2">{{ discount.code }}</td>
                <td v-if="discount.userId" class="px-6 py-2"><router-link :to="{params: {id:discount.userId }, name:'DAdminUserDetails' }"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-1 py-1">
                              user
                             
        </router-link></td>
        <td v-if="!discount.userId" class="px-6 py-2">All user</td>
                <td v-if="!discount.referralUserId" class="px-6 py-2">no</td>
                <td v-if="discount.referralUserId" class="px-6 py-2"><router-link :to="{params: {id:discount.referralUserId }, name:'DAdminUserDetails' }"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-1 py-1">
                              user
                             
        </router-link></td>
                
                <td class="px-6 py-2">{{ discount.maxAmount }}</td>
                <td class="px-6 py-2">{{ discount.useLimit }}</td>
                <td class="px-6 py-2">{{ discount.usedCount }}</td>
                <td v-if=" discount.expireTime" class="px-6 py-2">{{ discount.expireTime.replace("T", " - ").substring(0, 10) }}</td>
                <td v-if=" !discount.expireTime" class="px-6 py-2">-</td>
                <td class="px-6 py-2">{{ discount.expired}}</td>
                <!-- <td v-if=" discount.lastUsedDateTime" class="px-6 py-2">{{ discount.lastUsedDateTime.replace("T", " - ").substring(0, 10) }}</td> -->
                
                <td  class="px-6 py-2">{{ discount.createDateTime.replace("T", " - ").substring(0, 10) }}</td>
                
                <td class="px-6 py-2 flex"> <button @click.prevent="expireDiscount(discount.uId)" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-1 py-1">
                          delete 
                         
            </button> </td>
                
                
              </tr>
            </tbody>
          </table>
    </div>
  </div>

      
    
  </div>
</template>

<script>
import axios from 'axios';
import URL from "../URL";
export default {
  data(){
        return{
          plansOptions: [
              { text: 'Limited', value: 'Limited' },
              { text: 'Limited6', value: 'Limited6' },
              { text: 'NoLimit', value: 'NoLimit' },
              { text: 'NoLimit6', value: 'NoLimit6' },
              { text: 'Rapid', value: 'Rapid' },
              
            ],
            selectedPlansOptions: [],
          DepositsOptions: [
              { text: 'K1', value: 'K1' },
              { text: 'K5', value: 'K5' },
              { text: 'K10', value: 'K10' },
              { text: 'K25', value: 'K25' },
              { text: 'K50', value: 'K50' },
              { text: 'K100', value: 'K100' }
            ],
            selecteDDepositsOptions: [],

            code: '',
            comment: '',
            percent: null,
            maxAmount: null,
            userId: '',
            useLimit: null,
            expireTime: null,
            plans:[],
            deposits:[],


            Uid: '',
            Id: '',
            Code2: '',
            Comment2: '',
            Percent2: '',
            maxAmount2: null,
            UserId2: '',
            QueryUserId: false,
            QueryPercent: false,
            QueryMaxAmount: false,
            UseLimit2: '',
            UsedCount: '',
            FromExpireTime: '',
            ToExpireTime: '',
            FromLastUsedDateTime: '',
            ToLastUsedDateTime: '',
            Expired: null,

            discountDetails:[],


            Discounts: [],
            createDiscountResponse :true,



        }
    },
    
    mounted() {
      this.fetchDiscountCodeList();
    },
   
    methods: {
      createDiscountCode() {
        this.createDiscountResponse = false;
  const url = URL.baseUrl + `Admin/discount`;

  const data = {
    code: this.code,
    comment: this.comment,
    percent: this.percent,
    maxAmount: this.maxAmount,
    useLimit: this.useLimit,
    expireTime: this.expireTime,
    plans: this.plans,
    deposits: this.deposits,
  };

  // Remove properties with null values
  const filteredData = Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
  }, {});

  axios.post(url, filteredData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
  .then(response => {
    // Handle successful response here
    console.log(response.data);
    this.fetchDiscountCodeList();
    this.createDiscountResponse = true;
  })
  .catch(error => {
    this.errorMessage = error.message;
    console.error("There was an error!", error);
    this.createDiscountResponse = true;
  });
},
      
      fetchDiscountCodeList(){
        let url = URL.baseUrl + `Admin/discounts?UId=${this.Uid}&Id=${this.Id}&Code=${this.Code2}&Comment=${this.Comment2}&Percent=${this.Percent2}&UserId=${this.UserId2}&QueryUserId=${this.QueryUserId}&QueryPercent=${this.QueryPercent}&QueryMaxAmount=${this.QueryMaxAmount}&UseLimit=${this.UseLimit2}&UsedCount=${this.UsedCount}&FromExpireTime=${this.FromExpireTime}&ToExpireTime=${this.ToExpireTime}&FromLastUsedDateTime=${this.FromLastUsedDateTime}&ToLastUsedDateTime=${this.ToLastUsedDateTime}`;
        
        if (this.maxAmount2){
          url = url + `&maxAmount=${this.maxAmount2}`
        }
        if (this.Expired){
          url = url + `&Expired=${this.Expired}`
        }

        axios.get(url,
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
             // Handle successful response here
             
              
              this.Discounts = response.data;
        
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });

      },
      expireDiscount(discountUid){
        
        const url = URL.baseUrl + `Admin/discount/${discountUid}/expire`;
        axios.put(url, {},
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
             this.fetchDiscountCodeList();
              
              
       
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });

      }
  }
}
</script>