<template>
    <div dir="ltr" id="appl">
      <div id="chart" v-if="series.length > 0">
        <apexchart width="100%" type="rangeBar" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue3-apexcharts';
//   import ApexCharts from 'apexcharts'
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      tradeHistory: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        options: {
          series: [
            {
              data: [],
            },
          ],
          
          chart: {
            height: 350,
            type: 'rangeBar',
            zoom: {
              enabled: true,
            },
        
          },
          
          plotOptions: {
            bar: {
              isDumbbell: true,
              columnWidth: 6,
              dumbbellColors: [['#008FFB', '#6ebd44']],
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: 'top',
            horizontalAlign: 'left',
            customLegendItems: [ 'Maximum Position Drawdown %' ,'Profit / Loss %',],
          },
          fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical',
              gradientToColors: ['#6ebd44'],
              inverseColors: true,
              stops: [0, 100],
            },
          },
          
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            tickPlacement: 'on',
            type: 'numeric',

          },
        },
        series: [],
      };
    },
    created() {
    this.updateSeries();
  },
  methods: {
    updateSeries() {
      if (this.tradeHistory && this.tradeHistory.length > 1) {
        const seriesData = this.tradeHistory.slice(1).map((item, index) => ({
          index: index + 1,
          x: '' + (index + 1),
          y: [-item.maxDrawDownInPercent.toFixed(3), item.totalProfitInPercent.toFixed(3)],
        }));

        this.series = [
          {
            data: seriesData,
          },
        ];
      }
    },
  },
};
  </script>
  
  <style scoped>
  #appl {
    max-width: 800px;
    margin: 0 auto;
  }
  </style>