<template>
    <div class="grid grid-cols-12 ">
        
 
         
             <div class=" col-span-12 ">

                 
                 <div class="col-span-2  ">
                     <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
                         مبلغ پرداختی 
                     </label>
                     <div class="grid grid-cols-12" >
                        <input type="email" v-model="pricePaid"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 m-1 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
                         
                     </div>
 
                     <!-- <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="deposit" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                             <option value="DemoPhase2" >5000</option>
                             <option value="K10">10000</option>
                             <option value="K25">25000</option>
                         </select>
                     </div> -->
                 </div>
                 <div class="col-span-2  ">
                     <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
                         سهم سود  
                     </label>
                     <div class="grid grid-cols-12" >
                        <input :disabled="this.type !== 'Real'"  type="number" v-model="sharedPercent"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 m-1 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
                         
                     </div>
 
                     <!-- <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="deposit" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                             <option value="DemoPhase2" >5000</option>
                             <option value="K10">10000</option>
                             <option value="K25">25000</option>
                         </select>
                     </div> -->
                 </div>
                 <div class="col-span-2  ">
                     <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
                         currentBalance
                     </label>
                     <div class="grid grid-cols-12" >
                        <input type="email" v-model="currentBalance"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 m-1 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
                         
                     </div>
 
                     
                 </div>
                 <div class="col-span-2  ">
                     <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
                         actualDeposit 
                     </label>
                     <div class="grid grid-cols-12" >
                        <input type="email" v-model="actualDeposit"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 m-1 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
                         
                     </div>
 
                    
                 </div>
                 <div class="col-span-2  ">
                    <label class="block text-indigo-900  text-right font-iranSans" >
                       نوع پلن
                    </label>
                    <div class="grid grid-cols-12" >
                        <div class="grid grid-cols-12 col-span-12 text-xs" >
                        <button @click="choosePlan('Limited')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'Limited','bg-TFL-base1  text-white': this.plan === 'Limited' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">چالش دارای محدودیت زمانی و تارگت سود 8 و 4</button>
                        <button @click="choosePlan('Limited6')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'Limited6','bg-TFL-base1  text-white': this.plan === 'Limited6' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">چالش دارای محدودیت زمانی و تارگت سود 6 و 6</button>
                        <button @click="choosePlan('NoLimit')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'NoLimit','bg-TFL-base1  text-white': this.plan === 'NoLimit' }" class="col-span-12 text-sm py-2 px-4 m-1 rounded-lg">چالش بدون محدودیت زمانی و تارگت سود 8 و 4</button>
                        <button @click="choosePlan('NoLimit6')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'NoLimit6','bg-TFL-base1  text-white': this.plan === 'NoLimit6' }" class="col-span-12 text-sm py-2 px-4 m-1 rounded-lg">چالش بدون محدودیت زمانی و تارگت سود 6 و 6</button>
                        <button @click="choosePlan('Rapid')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'Rapid','bg-TFL-base1  text-white': this.plan === 'Rapid' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">پلن مستقیم  </button>
                        <button @click="choosePlan('Multiple')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'Multiple','bg-TFL-base1  text-white': this.plan === 'Multiple' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">5X</button>
                        <button @click="choosePlan('Competition')" :class="{ 'bg-gray-600  text-TFL-dashText2': this.plan !== 'Competition','bg-TFL-base1  text-white': this.plan === 'Competition' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">مسابقه</button>
                        
                        
                    </div>
                        
                        
                    </div>

                    
                </div>
                 <div class="col-span-2  ">
                     <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
                        نوع حساب 
                     </label>
                     <div class="grid grid-cols-12" >
                         <button @click="typeButtonClick('DemoPhase1')" :class="{ 'bg-gray-200 text-indigo-900': this.type !== 'DemoPhase1','bg-green-100  text-white': this.type === 'DemoPhase1' }" :disabled="this.plan === 'Rapid'" class="col-span-4 py-3 px-4 m-1 rounded">DemoPhase1</button>
                         <button @click="typeButtonClick('DemoPhase2')" :class="{ 'bg-gray-200 text-indigo-900': this.type !== 'DemoPhase2','bg-green-100  text-white': this.type === 'DemoPhase2' }" :disabled="this.plan === 'Rapid' || this.plan === 'Multiple' " class="col-span-4 py-3 px-4 m-1 rounded">DemoPhase2</button>
                         <button @click="typeButtonClick('Real')" :class="{ 'bg-gray-200 text-indigo-900': this.type !== 'Real','bg-green-100  text-white': this.type === 'Real' }" :disabled=" this.plan === 'Multiple'" class="col-span-4 py-3 px-4 m-1 rounded">Real</button>
                         
                     </div>
 
                     <!-- <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="deposit" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                             <option value="DemoPhase2" >5000</option>
                             <option value="K10">10000</option>
                             <option value="K25">25000</option>
                         </select>
                     </div> -->
                 </div>
                 <div class="col-span-2  ">
                     <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
                        بالانس حساب
                     </label>
                     <div class="grid grid-cols-12" >
                         <button @click="depositButtonClick('K1')" :class="{ 'bg-gray-200 text-indigo-900': this.deposit !== 'K1','bg-green-100  text-white': this.deposit === 'K1' }" :disabled=" this.plan === 'Multiple'" class="col-span-4 py-3 px-4 m-1 rounded">1000</button>
                         <button @click="depositButtonClick('K5')" :class="{ 'bg-gray-200 text-indigo-900': this.deposit !== 'K5','bg-green-100  text-white': this.deposit === 'K5' }" :disabled=" this.plan === 'Multiple'" class="col-span-4 py-3 px-4 m-1 rounded">5000</button>
                         <button @click="depositButtonClick('K10')" :class="{ 'bg-gray-200 text-indigo-900': this.deposit !== 'K10','bg-green-100  text-white': this.deposit === 'K10' }" :disabled=" this.plan === 'Multiple'" class="col-span-4 py-3 px-4 m-1 rounded">10000</button>
                         <button @click="depositButtonClick('K25')" :class="{ 'bg-gray-200 text-indigo-900': this.deposit !== 'K25','bg-green-100  text-white': this.deposit === 'K25' }" :disabled=" this.plan === 'Multiple'"  class="col-span-4 py-3 px-4 m-1 rounded">25000</button>
                         <button @click="depositButtonClick('K50')" :class="{ 'bg-gray-200 text-indigo-900': this.deposit !== 'K50','bg-green-100  text-white': this.deposit === 'K50' }"  class="col-span-4 py-3 px-4 m-1 rounded">50000</button>
                         <button @click="depositButtonClick('K100')" :class="{ 'bg-gray-200 text-indigo-900': this.deposit !== 'K100','bg-green-100  text-white': this.deposit === 'K100' }" :disabled="this.plan !== 'Rapid' && this.plan !== 'Multiple'" class="col-span-4 py-3 px-4 m-1 rounded">100000</button>
                         
                     </div>
 
                     <!-- <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="deposit" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                             <option value="K5" >5000</option>
                             <option value="K10">10000</option>
                             <option value="K25">25000</option>
                         </select>
                     </div> -->
                 </div>
 
                 <div class="col-span-2 mt-3">
                     <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
                        انتخاب بروکر 
                     </label>
                     <div class="grid grid-cols-12" >
                        <button @click="brokerButtonClick('Roboforex')" :class="{ 'bg-gray-200 text-indigo-900': selectedBroker !== 'Roboforex','bg-green-100  text-white': this.broker === 'Roboforex' }" class="py-2 px-4 m-1 rounded-lg col-span-6 text-sm ">Roboforex</button>
                        <button @click="brokerButtonClick('Fxtm')" :class="{ 'bg-gray-200  text-indigo-900': this.broker !== 'Fxtm','bg-green-100 text-white': this.broker === 'Fxtm' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Fxtm</button>
                        <button @click="brokerButtonClick('ICMarkets')" :class="{ 'bg-gray-200  text-indigo-900': this.broker !== 'ICMarkets','bg-green-100 text-white': this.broker === 'ICMarkets' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">ICMarkets</button>
                        <button @click="brokerButtonClick('Tickmill')" :class="{ 'bg-gray-200  text-indigo-900': this.broker !== 'Tickmill','bg-green-100 text-white': this.broker === 'Tickmill' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Tickmill</button>
                        <button @click="brokerButtonClick('Alpari')" :class="{ 'bg-gray-200  text-indigo-900': this.broker !== 'Alpari','bg-green-100 text-white': this.broker === 'Alpari' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Alpari</button>
                        <button @click="brokerButtonClick('Exness')" :class="{ 'bg-gray-200  text-indigo-900': this.broker !== 'Exness','bg-green-100 text-white': this.broker === 'Exness' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Exness</button>
                                                 
                     </div>
 
                     <!-- <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="broker" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                             <option value="Roboforex">Roboforex</option>
                             <option value="Fxtm">Fxtm</option>
                         </select>
                     </div> -->
 
                 </div>
 
                 <div class="col-span-2 mt-3">
                     <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
                        انتخاب سرور 
                     </label>
 
 
                     <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="serverName"   class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-100 text-gray-700 py-2 px-4 pr-8 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                            <option  v-for="(serverNameL, index) in serverNameList" :key="index" >{{serverNameL.name}}</option>
                        </select>
                     </div>
                 </div>
 
                 
                 <!-- <div class="col-span-2 mt-3">
                     <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
                        انتخاب پلتفرم 
                     </label>
                     <div>
                         <button @click="platformButtonClick('MT4')">MT4</button>
                                                                         
                     </div>
 
                     <div class="relative mt-3 text-right">
                         <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                             <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                         </div>
                         <select v-model="platform" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                             <option value="MT4">MT4</option>
                             <option value="MT5">MT5</option>
                         </select>
                     </div>
                 </div> -->
 
             </div>
             <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-card rounded-lg grid grid-cols-2">
             <div class="col-span-1" >
                         <button @click.prevent="created" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-3 mt-2">
                              بساز
                             
                         </button>
                        
                     </div>
                     <p v-if="this.showSuccessCreate">حساب ایحاد شد</p>
                     <p v-if="this.showFailedCreate">غیر موفق  </p>
             
          </div>
 
         
 
 
    </div>
 </template>
 
 <script>
 import axios from 'axios';
 import URL from "../URL";
 
 
 
 
 export default {
    
     data(){
         return {
            
             broker: 'Roboforex',
             platform: 'MT4',
             serverName: '',
             leverage: 100,
             deposit: 'K5',
             type: 'DemoPhase1',
             plan:'Limited',
             serverNameList :[],
             userId: this.$route.params.id,
             pricePaid: 0,
            actualDeposit: 0,
            currentBalance: 0,
            sharedPercent: 0,
            expireTime: null, 
            firstTradeDateTime: null,
            lastDatEquityOrBalance: 0,

            showFailedCreate: false,
            showSuccessCreate: false,
            
 
             
 
         }
     },
     mounted(){
        
        this.getServer();
        

    },
     
     watch: {
        broker() {
      // Call the API whenever the variable changes
      this.getServer();
    },
},
     methods: {
         typeButtonClick(value){
             this.type = value;
             if (value !== 'Real'){
                 this.sharedPercent = 0;
             }
             if (value === 'Real' && this.plan === 'Rapid'){
                 this.sharedPercent = 10;
             }
             if (value === 'Real' && this.plan !== 'Rapid'){
                 this.sharedPercent = 75;
             }
         },
         depositButtonClick(value){
             this.deposit = value;
         },
         brokerButtonClick(value){
             this.broker = value;
         },
         leverageButtonClick(value){
             this.leverage = value;
         },
         platformButtonClick(value){
             this.platform = value;
         },
         choosePlan(value){
            if (value === 'Rapid'){
                this.type = 'Real';
                this.deposit = 'K1';
                this.sharedPercent = 10;
            }
            if (value !== 'Rapid' && value !== 'Multiple' ){
               
                this.deposit = 'K1';
                this.sharedPercent = 0;
                if(this.type === 'Real'){

                    this.sharedPercent = 75;
                }
            }
            if ( value === 'Multiple' ){
                this.deposit = 'K50';
                this.type = 'DemoPhase1';
                this.sharedPercent = 0;
            }
           this.plan = value;
          
        },
 
 
         
         getServer() {
  // POST request using axios with error handling
        this.serverNameList = '';
        this.serverName = null;
        const url = URL.baseUrl + `Meta/Broker/${this.broker}`;
        axios.get(url,
       
            {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
            
        
        )
        .then(response => {
            // Handle successful response here
            
            this.serverNameList = response.data;
            
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            });
},
         created() {
            this.showSuccessCreate = false;
            this.showFailedCreate = false;
   // POST request using axios with error handling
   const url = URL.baseUrl + `Admin/account/test`;
         axios.post(url,
         { 
            
             
             broker: this.broker,
             platform: this.platform,
             serverName: this.serverName,
             leverage: this.leverage,
             deposit: this.deposit,
             type: this.type,
             userId: this.userId,
             pricePaid: this.pricePaid,
             actualDeposit: this.actualDeposit,
             currentBalance: this.currentBalance, 
             plan: this.plan,
             sharedPercent : this.sharedPercent,
             expireTime : this.expireTime,
             firstTradeDateTime : this.firstTradeDateTime,
             lastDatEquityOrBalance : this.lastDatEquityOrBalance,
             },
             {
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   }
             
         
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
             this.showSuccessCreate = true;
             
         })
             
 
             .catch(error => {
                this.showFailedCreate = true;
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
 },
         freeCreated() {
   // POST request using axios with error handling
    const url = URL.baseUrl + `Admin/account/test`;
         axios.post(url,
         { 
            
             
             broker: this.broker,
             platform: this.platform,
             serverName: this.serverName,
             leverage: this.leverage,
             deposit: this.deposit
             },
             {
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   }
             
         
         )
        
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
 }
     }
 }
 </script>
 