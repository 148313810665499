<template>
    <div class="bg-TFL-background min-h-screen select-none scroll-smooth">
        
        <div class="grid grid-cols-2 max-w-screen-xl  mx-auto">
            <div class="flex lg:col-span-1 col-span-2  justify-center   ">
                <img alt="banner image" class=" justify-self-center w-4/5" width="400" height="380" src="../assets/images/TFL-Banner1.png" loading="lazy" decoding="async">
            </div>
            <div class="justify-self-center lg:col-span-1 col-span-2  mx-auto lg:ml-20 my-auto  ">
                <p class="text-white text-center lg:text-right lg:text-2xl text-xl font-IRANSansBold my-1">پراپ فرم ترید برای زندگی</p>
                <p class="bg-gradient-to-r from-TFL-base2 font-Montserrat to-TFL-base1  text-transparent bg-clip-text lg:text-5xl text-3xl text-center lg:text-left font-IRANSansBold">TRADE FOR LIVING</p>
                <p class="text-white lg:text-xl text-lg text-center lg:text-left font-Montserrat my-1">PROPRIETARY FIRM</p>
                <p class="text-white text-center lg:text-right lg:text-lg text-sm my-6 font-IRANSansBold">  تامین سرمایه معامله‌گران مستعد و توانمند فارکس</p>
                <div class="flex justify-between">

                    <div  class="relative inline-flex my-4 mx-4   group">
                        <div
                            class="my-2  absolute transitiona-all duration-1000 opacity-70 -inset-px bg-gradient-to-r from-TFL-base1 via-[#FF44EC] to-TFL-base1 rounded-[36px] blur-lg group-hover:opacity-100 group-hover:-inset-1 group-hover:duration-200 animate-tilt">
                        </div>
                        <a href="/Signup" title="Get quote now"
                            class="relative border-2 border-TFL-base1 lg:mx-2 mx-auto  text-TFL-base1 inline-flex items-center justify-center px-8 py-1 text-lg font-bold text-white transition-all duration-200 bg-TFL-background font-pj rounded-3xl "
                            role="button">  ثبت نام
                        </a>
                        
                    </div>
                    <div class="flex items-center gap-6 mx-4">
    <!-- <a class="text-gray-700 hover:text-orange-600" aria-label="Visit TrendyMinds LinkedIn" href="" target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-8">
            <path fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
            </path>
        </svg>
    </a> -->
    <a class="text-gray-400 hover:text-TFL-base1" aria-label="Visit TrendyMinds YouTube" href="https://www.youtube.com/@TFLfund" target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="h-8">
            <path fill="currentColor"
                d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z">
            </path>
        </svg>
    </a>
    <!-- <a class="text-gray-400 hover:text-TFL-base1" aria-label="Visit TrendyMinds Facebook" href="" target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="h-8">
            <path fill="currentColor"
                d="m279.14 288 14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z">
            </path>
        </svg>
    </a> -->
    <a class="text-gray-400 hover:text-TFL-base1" aria-label="Visit TrendyMinds Instagram" href="https://www.instagram.com/tflfund/" target="_blank"><svg
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="h-8">
            <path fill="currentColor"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
            </path>
        </svg>
    </a>
    <a class="text-gray-400 hover:text-TFL-base1" aria-label="Visit TrendyMinds Instagram" href="https://t.me/TFLfund" target="_blank"><svg  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xml:space="preserve" class="h-8">
<circle fill="currentColor" cx="256" cy="256" r="256"/>

<path  d="M164.689,311.141L82.127,269.86c-2.263-1.132-2.285-4.353-0.038-5.516L395.75,102.105
	c2.304-1.192,4.964,0.811,4.456,3.355l-54.004,270.017c-0.385,1.927-2.446,3.011-4.253,2.237l-73.393-31.453
	c-0.879-0.377-1.884-0.326-2.721,0.139l-94.839,52.688c-2.062,1.145-4.597-0.345-4.597-2.705v-82.474
	C166.4,312.736,165.738,311.665,164.689,311.141z"/>
<path fill="currentColor" d="M200.31,338.967l-0.513-82.428c-0.003-0.528,0.27-1.018,0.72-1.293l133.899-81.798
	c1.518-0.927,3.106,1.083,1.852,2.345l-101.9,102.624c-0.112,0.114-0.207,0.244-0.278,0.387l-17.43,34.858l-13.509,25.988
	C202.426,341.045,200.32,340.538,200.31,338.967z"/>
</svg>
    </a>
    <!-- <a class="text-gray-400 hover:text-TFL-base1" aria-label="Visit TrendyMinds Twitter" href="" target="_blank"><svg
            class="h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path fill="currentColor"
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z">
            </path>
        </svg>
    </a> -->
</div>
                </div>
                
            </div>
            

        </div>
       

        <div class="flex justify-between px-4 max-w-screen-xl  my-2 mx-auto">
            <div class="grid grid-cols-3 my-4 hover:scale-105 transition duration-700 ease-in-out">
                                <img alt="banner image" class=" justify-self-center object-scale-down col-span-1 h-24 my-auto"  src="../assets/images/TFL-AccountTime.png" loading="lazy" decoding="async">
                                <div class=" col-span-2 rounded-lg   my-auto " >

                                    <div class="flex "  id="wrapper">
                                       <number
                                             class="bold transition text-TFL-base1 font-IRANSansBold sm:text-4xl text-2xl"
                                             :class="{scaleBig: scaleClass}"
                                             ref="number1"
                                             :from="numberFromBuyTime"
                                             :format="theFormat"
                                             animationPaused
                                    	        :to="numberToBuyTime"
                                    	        :duration="duration"
                                             easing="Power4.easeOut"
                                             @complete="completed"/>
                                             <div class=" text-right font-IRANSansBold sm:text-2xl text-base self-center text-white">دقیقه</div>

                                     </div> 
                                    <p class="  rounded-lg text-gray-300 text-sm my-auto font-IRANSansBold ">مدت زمان تحویل اکانت   </p>
                                </div>
                            </div>
            <div class="grid grid-cols-3 my-4 hover:scale-105 transition duration-700 ease-in-out">
                                <img alt="banner image" class=" justify-self-center object-scale-down col-span-1 h-24 my-auto"  src="../assets/images/TFL-WhitdrawalTime.png" loading="lazy" decoding="async">
                                <div class=" col-span-2 rounded-lg   my-auto " >

                                    <div class="flex "  id="wrapper">
                                       <number
                                             class="bold transition text-TFL-base1 font-IRANSansBold sm:text-4xl text-2xl"
                                             :class="{scaleBig: scaleClass}"
                                             ref="number2"
                                             :from="numberFromWithdrawTime"
                                              :format="theFormat"
                                              animationPaused
	                                            :to="numberToWithdrawTime"
	                                            :duration="duration"
                                             easing="Power4.easeOut"
                                             @complete="completed"/>
                                             <div class=" text-right font-IRANSansBold sm:text-2xl text-base self-center text-white">ساعت</div>

                                     </div> 
                                    <p class="  rounded-lg text-gray-300 text-sm my-auto font-IRANSansBold "> میانگین زمان واریز سود </p>
                                </div>
                            </div>
            <div class="grid grid-cols-3 my-4 hover:scale-105 transition duration-700 ease-in-out">
                                <img alt="banner image" class=" justify-self-center object-scale-down col-span-1 h-24 my-auto"  src="../assets/images/TFL-StartPrice.png" loading="lazy" decoding="async">
                                <div class=" col-span-2 rounded-lg   my-auto " >

                                    <div class="flex "  id="wrapper">
                                       <number
                                             class="bold transition text-TFL-base1 font-IRANSansBold sm:text-4xl text-2xl"
                                             :class="{scaleBig: scaleClass}"
                                             ref="number3"
                                             :from="numberFromPrice"
                                             :format="theFormat"
                                             animationPaused
	                                           :to="numberToPrice"
	                                           :duration="duration"
                                             easing="Power4.easeOut"
                                             @complete="completed"/>
                                             <div class=" text-right font-IRANSansBold sm:text-2xl text-base self-center text-white">دلار</div>

                                     </div> 
                                    <p class="  rounded-lg text-gray-300 text-sm my-auto font-IRANSansBold ">قیمت شروع تعرفه  </p>
                                </div>
                            </div>

        </div>
        <div class="md:block hidden grid grid-cols-2 max-w-screen-xl  my-2 mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">پلن‌های متنوع دریافت سرمایه</p>
        </div>
        <div class=" max-w-screen-xl px-4 my-2 mt-10 mx-auto flex justify-between ">
            <div class="md:block hidden w-1/4 h-48 self-end">
                <img alt="banner image" class=" justify-self-center object-scale-down  "   src="../assets/images/TFL-2plan.png" loading="lazy" decoding="async">
                
            </div>
            <div class="md:block hidden w-2/4 h-96 self-end">
                <img alt="banner image" class=" justify-self-center object-scale-down  "   src="../assets/images/TFL-5Xplan.png" loading="lazy" decoding="async">
                
            </div>
            <div class="md:block hidden w-1/4 h-48 self-end">
                <img alt="banner image" class=" justify-self-center object-scale-down  "   src="../assets/images/TFL-0plan.png" loading="lazy" decoding="async">
            </div>
        </div>
        
        <div class=" max-w-screen-xl px-4 my-2 mt-4 mx-auto flex justify-between gap-x-2 ">
            <div ref="clickableDiv" @click="scrollToDiv1" class="md:block hidden cursor-pointer w-1/4 self-end rounded rounded-[36px] hover:bg-gray-700 lg:mx-4 my-4 hover:scale-105 transition duration-700 ease-in-out bg-gray-800 bg-opacity-80">
                <p class=" p-2 py-2 mt-4 font-IRANSansBold text-xl text-center mx-4 text-white "> پلن چالش دو مرحله‌ای </p>
                <ul role="list" class="mb-8  mt-4 space-y-2 justify-center lg:text-left text-center">
                            
                            <li class="flex items-center justify-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">امکان انتخاب میزان تارگت سود</span>
                            </li>
                            <li class="flex items-center justify-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">بدون هیچگونه محدودیت زمانی </span>
                            </li>
                            
                        </ul>
            </div>
            <div ref="clickableDiv" @click="scrollToDiv2" class="md:block hidden cursor-pointer w-2/4 self-end rounded rounded-[36px] hover:bg-gray-700 lg:mx-4 my-4 hover:scale-105 hover:shadow transition duration-700 ease-in-out bg-gray-800 bg-opacity-80">
                <p class=" p-2 py-4 font-IRANSansBold text-3xl text-center mx-4 text-white "> پلن 5X، برای اولین بار </p>
                <ul role="list" class="mb-8  mt-4 space-y-2 justify-center lg:text-left text-center">
                            
                            <li class="flex items-center justify-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-lg">تمرین در شرایط پراپ‌تریدینگ</span>
                            </li>
                            <li class="flex items-center justify-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-lg">به همراه جایزه 5 برابری </span>
                            </li>
                            
                        </ul>
                
            </div>
            <div ref="clickableDiv" @click="scrollToDiv3" class="md:block hidden cursor-pointer w-1/4 self-end rounded rounded-[36px] hover:bg-gray-700 lg:mx-4 my-4 hover:scale-105 transition duration-700 ease-in-out bg-gray-800 bg-opacity-80">
                <p class=" p-2 py-2 mt-4 font-IRANSansBold text-xl text-center mx-4 text-white "> پلن مستقیم، بدون چالش </p>
                <ul role="list" class="mb-8  mt-4 space-y-2 justify-center  text-center">
                            
                            <li class="flex items-center justify-center space-x-3 mx-auto sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">دریافت حساب ریل از ابتدا</span>
                            </li>
                            <li class="flex items-center justify-center space-x-3 sm:text-base text-sm text-center ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">بدون تارگت سود </span>
                            </li>
                            
                        </ul>
            </div>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-2 mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">چرا پراپ‌‌ تریدینگ؟</p>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-2 mx-auto">
            <div class="flex-inline col-span-2   justify-center lg:ml-20  lg:mr-10 mx-auto p-4    ">
                <div   class="relative my-6 w-full ">
                    <div class="absolute -inset-1  bg-gradient-to-bl from-TFL-base1 from-10% to-TFL-background to-30% rounded-[36px] blur opacity-75"></div>
                        <div   class=" relative grid grid-cols-3  rounded-[36px] bg-TFL-background  my-2 ">
                            
                            <div class="rounded rounded-lg col-span-3 lg:col-span-1 hover:bg-gray-700 lg:mx-4 my-4 hover:scale-105 transition duration-700 ease-in-out">

                                <p class=" p-2 py-4 font-IRANSansBold text-xl mx-4 text-white ">  کاهش ریسک</p>
                                <p class=" p-2 py-4 font-IRANSansBold text-sm text-justify mx-4 text-gray-300 ">یکی از مزایای پراپ‌تریدینگ کاهش ریسک معامله‌گران نسبت به حساب شخصی است. زمانی که معامله‌گر بر روی حساب شخصی خود معامله‌‌ می‌کند، در صورت زیان، سرمایه‌ خود را از دست می‌دهد. با این حال، در پراپ تریدینگ، معامله‌گر فقط در صورت سود از معامله، سود دریافت می‌کند و در صورت ضرر در حساب معاملاتی هیچ مسئولیتی نخواهند داشت. این می تواند به معامله‌گران کمک کند تا از سرمایه خود محافظت و روی بهبود مهارت‌های خود تمرکز نمایند.</p>
                            </div>
                            <div class="rounded rounded-lg col-span-3 lg:col-span-1 hover:bg-gray-700 lg:mx-4 my-4 hover:scale-105 transition duration-700 ease-in-out">
                            
                                <p class=" p-2 py-4 font-IRANSansBold text-xl mx-4 text-white ">سود بیشتر  </p>
                                <p class=" p-2 py-4 font-IRANSansBold text-sm text-justify mx-4 text-gray-300 ">پراپ تریدینگ می‌تواند به معامله‌گران کمک کند تا بدون ریسک بر سرمایه خود، سود بیشتری کسب کنند. با دسترسی به سرمایه بیشتر، معامله گران می توانند معاملات بزرگتری انجام دهند و درآمد خود را افزایش دهند. در این راستا، پراپ‌فرم ترید برای زندگی تا 85 درصد سود کسب شده را به معامه‌گر پرداخت خواهد کرد</p>
                            </div>
                            <div class="rounded rounded-lg col-span-3 lg:col-span-1 hover:bg-gray-700 lg:mx-4 my-4 hover:scale-105 transition duration-700 ease-in-out">

                                <p class=" p-2 py-4 font-IRANSansBold text-xl mx-4 text-white ">مدیریت سرمایه  </p>
                                <p class=" p-2 py-4 font-IRANSansBold text-sm text-justify mx-4 text-gray-300 ">مدیریت سرمایه یک مهارت ضروری برای معامله‌گران است که به آن‌ها کمک می‌کند تا ریسک خود را کنترل کرده و سودآوری خود را افزایش دهند. معامله‌گر در پراپ‌فرم ملزم به رعایت موازین مدیریت سرمایه بوده که این موضوع این امکان را می‌دهد تا اصول مدیریت ریسک را در شرایط واقعی بازار تمرین کنند. این امر به آن‌ها کمک می‌کند تا مهارت‌های خود را در شرایط مختلف بازار بهبود بخشند و به سودآوری مستمر دست‌یابند.
</p>
                            </div>
                        </div>
                    
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-2 mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">مزیت پراپ‌فرم ترید برای زندگی</p>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  mx-auto">
            <div class="  lg:col-span-1 col-span-2  justify-center my-auto p-4    ">
                
                <div   class="relative my-6 w-full  ">
                    <div class="absolute -inset-1    bg-gradient-to-tl from-TFL-base1 from-10% to-TFL-background to-30% rounded-[36px] blur opacity-75"></div>
                        <div  class=" relative    rounded-[36px] bg-TFL-background  my-2 p-4 ">
                            <div class="grid grid-cols-3 my-4 hover:scale-105 transition duration-700 ease-in-out">
                                <img alt="banner image" class=" justify-self-center object-scale-down col-span-1 hidden sm:block h-24 my-auto"  src="../assets/images/TFL-Panel.png" loading="lazy" decoding="async">
                                <div class=" sm:col-span-2 col-span-3 rounded-lg   my-auto " >

                                    <p class="  text-lg text-TFL-base1 font-IRANSansBold my-2   "> پنل آنالیز</p>
                                    <p class="  rounded-lg text-justify text-gray-300 text-sm my-auto font-IRANSansBold ">پنل آنالیز تامین سرمایه ترید برای زندگی، در راستای بررسی و تحلیل داده‌های حساب معاملاتی شما در هر لحظه در اختیار شما خواهد بود.  </p>
                                </div>
                            </div>
                            <div class="grid grid-cols-3 my-4 hover:scale-105 transition duration-700 ease-in-out">
                                <img alt="banner image" class=" justify-self-center object-scale-down col-span-1 hidden sm:block h-24 my-auto"  src="../assets/images/TFL-Automatic.png" loading="lazy" decoding="async">
                                <div class=" sm:col-span-2 col-span-3 rounded-lg   my-auto " >

                                    <p class="  text-lg text-TFL-base1 font-IRANSansBold my-2   "> رسیدگی خودکار </p>
                                    <p class=" rounded-lg text-justify text-gray-300 text-sm ">تامین سرمایه ترید برای زندگی با هدف صرفه‌جویی در زمان شما عزیزان، تمامی فرآیند‌های درخواست حساب فاز بعدی، درخواست ریست، درخواست اکستند و درخواست برداشت را به صورت خودکار طراحی کرده و در کم‌ترین زمان ممکن پس از ثبت درخواست، فرآیند مورد نظر انجام خواهد شد.</p>
                          
                                </div>
                            </div>
                            <div class="grid grid-cols-3 my-4 hover:scale-105 transition duration-700 ease-in-out">
                                <img alt="banner image" class=" justify-self-center object-scale-down col-span-1 hidden sm:block h-24 my-auto"  src="../assets/images/TFL-EmailPanel.png" loading="lazy" decoding="async">
                                <div class=" sm:col-span-2 col-span-3 rounded-lg   my-auto " >

                                    <p class="  text-lg text-TFL-base1 font-IRANSansBold my-2   "> پنل ایمیل  </p>
                                   
                                    <p class=" rounded-lg text-justify text-gray-300 text-sm "> توسط پنل ایمیل مهم‌ترین اطلاعات مورد استفاده شما شامل: اخبار، وضعیت فعلی حساب، حد مجاز ضرر روزانه و غیره  به صورت روزانه ارسال خواهد شد.</p>
                          
                                </div>
                            </div>
                        </div>
                        </div>



            </div>
            <div class="flex col-span-1  justify-center hidden lg:block    ">
                <img alt="banner image" class=" justify-self-center object-scale-down "   src="../assets/images/TFL-AnalysisPanel.png" loading="lazy" decoding="async">
            </div>
        </div>

        <div ref="targetDiv2" class="grid grid-cols-2 max-w-screen-xl  mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">در پلن 5X، تمرین کن و جایزه بگیر  </p>
            <p class="mb-3 col-span-2 font-light text-gray-300 sm:text-lg text-base sm:mx-20 mx-8 my-10 text-justify">برای اولین بار در صنعت پراپ‌تریدینگ، فرصتی بی‌نظیر ایجاد شده است تا معامله‌گران بتوانند علاوه بر تمرین در شرایط و چارچوب مدیریت ریسک، در انتهای مسیر جایزه‌ای ارزنده دریافت کنند. پلن 5X به نحوی طراحی شده‌است تا معامله‌گران بتوانند پس از ثبت نام به مدت زمان نامحدود، با تمرکز بر اجرای استراتژی خود به تارگت سود معین دست یابند تا از کارایی استراتژی و روش معاملاتی خود اطمینان حاصل کنند. علاوه بر آن بعد از دستیابی به تارگت سود، به پاس این موفقیت، جایزه‌ای ارزنده دریافت خواهند کرد.</p>
            
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-4 mt-10 mx-auto px-2">
            <div class="relative flex col-span-2  justify-center    ">
                <div class="absolute -inset-1  bg-gradient-to-t from-TFL-base1 via-TFL-background to-TFL-background from-5% via-30% rounded-full blur opacity-0 mx-24"></div>
                <div class="relative">
                    <img alt="banner image" class="justify-self-center object-scale-down rounded-lg relative" src="../assets/images/TFL-5X.png" loading="lazy" decoding="async">
                    <!-- <div class="absolute  inset-x-0 top-2/3 text-center lg:text-8xl text-4xl drop-shadow-[rgba(0,_0,_0,_0.8)_0px_6px_6px] font-Montserrat   text-transparent bg-clip-text bg-gradient-to-b from-TFL-base1  to-TFL-base2  ">
                          UP TO $100000
                    </div> -->
                    <div class="absolute grid grid-cols-2  inset-x-0 top-0 text-center lg:text-8xl text-4xl drop-shadow-[rgba(0,_0,_0,_1)_0px_8px_8px] font-Montserrat   text-gray-300  ">
                          <p class="animate-bounce bg-gradient-to-b from-TFL-base1  to-purple-800 from-10%  text-transparent bg-clip-text">$250</p>
                          <p class="animate-bounce bg-gradient-to-b from-gray-300  to-gray-600 from-20%  text-transparent bg-clip-text">10%</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  mt-20 mx-auto">
            <div class="lg:col-span-1 col-span-2   sm:mx-20 mx-2  ">
                
                <ul class="mb-6 space-y-4 text-gray-300 px-10 pt-4 sm:text-lg text-sm my-10">
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">بدون محدودیت زمانی حداقلی و حداکثری </span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">تارگت سود {{formatNumber(10)}} درصدی</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">حداکثر افت شناور {{formatNumber(2)}} درصدی</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">حداکثر افت روزانه {{formatNumber(5)}} درصدی</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">حداکثر افت کلی {{formatNumber(5)}} درصدی</span>
                </li>
                </ul>
                
            </div>
            <div class="lg:col-span-1 col-span-2 sm:mx-20 mx-2">
                <p  class=" p-2 py-4 col-span-2  font-IRANSansBold lg:text-lg text-base  bg-gradient-to-l from-gray-100  to-gray-600 from-1%   text-transparent bg-clip-text ">میزان جایزه </p>
                <div class="grid grid-cols-1">
                    <button @click="choosePrize('K125')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.Prize !== 'K125','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.Prize === 'K125' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">$125   </button>
                    <button @click="choosePrize('K250')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.Prize !== 'K250','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.Prize === 'K250' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">$250   </button>
                    
                    
                    
                    
                        
                </div>
                <p  class=" p-2 py-4 col-span-2 mx-2 font-IRANSansBold lg:text-lg text-base  bg-gradient-to-l from-gray-100  to-gray-600   text-transparent bg-clip-text ">تعرفه  </p>
                <div class="rounded-full bg-gray-800 bg-opacity-50 border-2 border-gray-700 p-2 flex justify-between ">
                    <button class="bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 px-4 py-2 font-IRANSansBold lg:text-lg text-sm rounded-full font-medium focus:ring ring-black ring-opacity-10 gradient element-to-rotate m-1"> شروع تمرین </button>
                    <!-- <div class="flex" v-if="Prize == 'K1'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">25%</div>
                        <div v-if="Prize == 'K1'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$12   </div>

                    </div> -->
                    <p v-if="Prize == 'K125'"  class="  p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$25 </p>
                    <!-- <div class="flex" v-if="Prize == 'K5'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">20%</div>
                        <div v-if="Prize == 'K5'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$49   </div>

                    </div> -->
                    <p v-if="Prize == 'K250'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$50  </p>
                    <!-- <div class="flex" v-if="Prize == 'K10'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">22%</div>
                        <div v-if="cap == 'K10'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$89   </div>

                    </div> -->
                    <p v-if="cap == 'K10'"  class="  p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$89  </p>
                    <!-- <div class="flex" v-if="cap == 'K25'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">22%</div>
                        <div v-if="cap == 'K25'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$229   </div>

                    </div> -->
                    <p v-if="cap == 'K25'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$229  </p>
                    <!-- <div class="flex" v-if="cap == 'K50'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">15%</div>
                        <div v-if="cap == 'K50'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$449   </div>

                    </div> -->
                    <p v-if="cap == 'K50'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$449  </p>
                    <!-- <div class="flex" v-if="cap == 'K100'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">16%</div>
                        <div v-if="cap == 'K100'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$899   </div>

                    </div> -->
                    <p v-if="cap == 'K100'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$899  </p>
            
                </div>
            </div>
        </div>
        








        <div ref="targetDiv1" class="grid grid-cols-2 max-w-screen-xl  mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text "> دریافت سرمایه در پلن چالش دو مرحله‌ای  </p>
            <p class="mb-3 col-span-2 font-light text-gray-300 sm:text-lg text-base sm:mx-20 mx-8 my-10 text-justify ">در پلن چالش دو مرحله‌ای به منظور بررسی توانایی معامله‌گر دو مرحله چالش طراحی شده است. فاز اول این چالش بر سودآوری و بازدهی استراتژی متمرکز بوده و معامله‌گر میبایست برای عبور از این مرحله به تارگت سود فاز اول دست یابد. فاز دوم چالش بر بررسی ثبات، مدیریت ریسک و استمرار عملکرد معامله‌گر متمرکز است. در صورت دستیابی به تارگت سود فاز دوم، پس از ورود به فاز ریل کسب درآمد معامله‌گر آغاز خواهد شد و می‌تواند تا سقف 90 درصد سود کسب شده را برداشت نماید. همچنین در هنگام ثبت‌نام، بدون تفاوت در قیمت، دو گزینه برای تارگتِ سودِ هر فاز برای انتخاب وجود دارد:  </p>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-2 mx-auto">
            <p class="p-2 py-2 col-span-2 text-center font-IRANSansBold lg:text-2xl text-xl mx-4 bg-gradient-to-b from-TFL-base1  to-gray-300 from-30%  text-transparent bg-clip-text "> تارگت سود فاز اول {{ formatNumber(8) }} درصد و فاز دوم {{ formatNumber(4) }} درصد </p>
            <p class="p-2 py-2 col-span-2 text-center font-IRANSansBold lg:text-2xl text-xl mx-4 bg-gradient-to-b from-TFL-base1  to-gray-300 from-30%  text-transparent bg-clip-text "> تارگت سود فاز اول {{ formatNumber(6) }} درصد و فاز دوم {{ formatNumber(6) }} درصد </p>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-2 mx-auto">
            <div class="flex lg:col-span-1 col-span-2  justify-center    ">
                <img alt="banner image" class=" justify-self-center object-scale-down "   src="../assets/images/TFL-Target.gif" loading="lazy" decoding="async">
            </div>
            <div class="flex-inline  lg:col-span-1 col-span-2   justify-center lg:ml-20 my-auto lg:mr-10 p-4    ">
                <button @click="changeWhichButton('Phase1')"  class="relative my-6 w-full transition duration-700 ease-in-out ">
                    <div class="absolute -inset-1  bg-gradient-to-r from-TFL-base1 to-purple-600 rounded-[36px] blur opacity-75"></div>
                    
                    <div   class=" relative grid grid-cols-1  rounded-[36px] bg-TFL-background  my-2 ">
                        <div class="flex items-center justify-between ">
                            <p class=" p-2 py-4 font-IRANSansBold sm:text-2xl mx-4 bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">فاز اول</p>
                            <p class=" p-2 py-4 font-IRANSansBold sm:text-xl text-sm mx-4 text-white ">توانایی کسب سود</p>

                        </div>
                        <div v-if="whichButton == 'Phase1' " class="transition duration-700 ease-in-out">
                            <ul role="list" class="mb-8 mx-8 mt-4 space-y-2 justify-center lg:text-left text-center">
                            
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">تارگت سود {{ formatNumber(6) }} یا {{ formatNumber(8) }} درصدی </span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">بدون حداقل روز معاملاتی</span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">حداکثر افت کلی {{ formatNumber(12) }} درصدی</span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                               
                                <span class="text-white text-sm">حداکثر افت روزانه {{ formatNumber(5) }} درصدی</span>
                            </li>
                            
                            
                            
                        </ul>

                        </div>
                    </div>
                    
                </button>
                <button @click="changeWhichButton('Phase2')" class="relative my-6 w-full transition duration-700 ease-in-out ">
                    <div class="absolute -inset-1  bg-gradient-to-r from-TFL-base1 to-purple-600 rounded-[36px] blur opacity-75"></div>
                    <div  class=" relative grid grid-cols-1  rounded-[36px] bg-TFL-background  my-2 ">
                        <div class="flex items-center justify-between ">
                            <p class=" p-2 py-4 font-IRANSansBold sm:text-2xl mx-4 bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">فاز دوم</p>
                            <p class=" p-2 py-4 font-IRANSansBold sm:text-xl text-sm mx-4 text-white ">توانایی مدیریت ریسک </p>

                        </div>
                        <div v-if="whichButton == 'Phase2' " class="transition duration-700 ease-in-out">
                            <ul role="list" class="mb-8 mx-8 mt-4 space-y-2 justify-center lg:text-left text-center">
                            
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">تارگت سود {{ formatNumber(6) }} یا {{ formatNumber(4) }} درصدی </span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">بدون حداقل روز معاملاتی</span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">حداکثر افت کلی {{ formatNumber(12) }} درصدی</span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                               
                                <span class="text-white text-sm">حداکثر افت روزانه {{ formatNumber(5) }} درصدی</span>
                            </li>
                           
                            
                            
                        </ul>

                        </div>
                    </div>
                    
                </button>
                <button @click="changeWhichButton('Real')" class="relative my-6 w-full transition duration-700 ease-in-out ">
                    <div class="absolute -inset-1  bg-gradient-to-r from-pink-600 to-purple-600 rounded-[36px] blur opacity-75"></div>
                    <div  class=" relative grid grid-cols-1  rounded-[36px] bg-TFL-background  my-2 ">
                        <div class="flex items-center justify-between ">
                            <p class=" p-2 py-4 font-IRANSansBold sm:text-2xl mx-4 bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">فاز ریل</p>
                            <p class=" p-2 py-4 font-IRANSansBold sm:text-xl text-sm mx-4 text-white ">  تقسیم سود</p>

                        </div>
                        <div v-if="whichButton == 'Real' " class="transition duration-700 ease-in-out">
                            <ul role="list" class="mb-8 mx-8 mt-4 space-y-2 justify-center lg:text-left text-center">
                            
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">تقسیم سود تا سقف  {{ formatNumber(90) }} درصد </span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="text-white text-sm">حداکثر افت کلی {{ formatNumber(12) }} درصدی</span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                               
                                <span class="text-white text-sm">حداکثر افت روزانه {{ formatNumber(5) }} درصدی</span>
                            </li>
                            <li class="flex items-center space-x-3 sm:text-base text-sm ">
                                
                                <svg class="flex-shrink-0 w-5 h-5 text-TFL-base1 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                               
                                <span class="text-white text-sm">حداکثر ریسک شناور {{ formatNumber(2) }} درصدی</span>
                            </li>
                            
                            
                        </ul>

                        </div>
                    </div>
                    
                </button>
                
                
                
            </div>
            
            

        </div>
        <div class="grid grid-cols-2 max-w-screen-xl mt-20 text-sm  mx-auto">
            <!-- <div class="col-span-2 mx-auto mb-2 text-center lg:mb-4">
                <h2 class="mb-6 sm:text-4xl text-2xl  font-extrabold tracking-tight text-white drop-shadow-md shadow-black ">آغاز مسیر معامله‌گری</h2>
                <p class="mb-3 font-light text-white sm:text-2xl text-lg  ">پلن‌های پراپ‌فرم ترید برای زندگی، جایگزینی مناسب برای شروع مسیر معامله‌گری  </p>
                <p class="mb-2 font-light text-gray-500 sm:text-xl text-sm ">کسب درآمد با ریسک و هزینه حداقلی نسبت به حساب شخصی، در چارچوب استانداردهای مدیریت ریسک </p>
            </div> -->
            <div class="col-span-2 mx-auto mb-2 text-center lg:mb-4">
               
                <p class="mb-3 col-span-2 font-light text-gray-300 sm:text-lg text-base sm:mx-20 mx-8 mb-10 text-justify"> پلن‌های چالش دو مرحله‌ای به دو صورت دارای محدودیت زمانی و بدون محدودیت زمانی طراحی شده است. تنها تفاوت میان این دو، زمان رسیدن به تارگت سود خواهد بود. در پلن‌های دارای محدودیت زمانی معامله‌گر ملزم است در کمتر از 30 روز در فاز اول و در کمتر از 60 روز در فاز دوم به تارگت سود دست یابد اما در پلن‌های بدون محدودیت زمانی، معامله‌گر هیچگونه محدودیت زمانی در رسیدن به تارگت سود نخواهد داشت. </p>
                
            </div>
         </div>
         <div class="grid grid-cols-12 mb-2 max-w-screen-md mx-auto" >
                        <button @click="choosePlan('limited')" :class="{ 'border-b-4 border-gray-500 text-gray-500': this.plan !== 'limited','border-b-4 border-TFL-base1  text-TFL-base1': this.plan === 'limited' }" class="col-span-4 col-start-3 inline-flex items-center justify-center py-2 px-4 m-1 ">دارای محدودیت زمانی </button>
                        <button @click="choosePlan('noLimited')" :class="{ 'border-b-4 border-gray-500 text-gray-500': this.plan !== 'noLimited','border-b-4 border-TFL-base1  text-TFL-base1': this.plan === 'noLimited' }" class="col-span-4 py-2 px-4 m-1 ">بدون محدودیت زمانی</button>
                        
                        
                    </div>
         <div class="grid grid-cols-2 max-w-screen-xl text-sm my-2 mx-auto">
            <div class="bg-[url('../assets/images/TFL-PricingCardBg.png')] bg-repeat-x bg-center bg-contain bg-opacity-10 col-span-2  sm:p-10 p-4">
    <div class="flex flex-wrap items-center justify-center max-w-6xl mx-auto gap-4 sm:gap-0">

        <div class="relative w-full p-6 bg-gray-700 border border-gray-700 rounded-[36px] lg:w-1/4 bg-opacity-50 sm:rounded-l-none sm:p-6">
            <div class="absolute  sm:-top-8 sm:-right-8 -top-4 -right-4 flex  justify-center    ">
                <img alt="banner image" class=" justify-self-center object-scale-down sm:h-24 h-12 "   src="../assets/images/TFL-PricingCard.png" loading="lazy" decoding="async">
            </div>
            <div class="mb-6">
                <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-4xl">$5000</h3>
            </div>
            <div class="mb-4 space-x-2 flex justify-between">
                <span v-if="plan == 'limited'" class="text-2xl font-Montserrat text-gray-100">$49</span>
                <!-- <div  v-if="plan == 'limited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">28%</div>
                    <span v-if="plan == 'limited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$49</span>
                </div> -->
                <span v-if="plan == 'noLimited'" class="text-2xl font-Montserrat text-gray-100">$69</span>
                <!-- <div  v-if="plan == 'noLimited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">44%</div>
                    <span v-if="plan == 'noLimited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$69</span>
                </div> -->
            </div>
            <ul class="mb-6 space-y-2 text-gray-300">
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(300)}} / {{formatNumber(400)}} دلاری در فاز اول</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(300)}} / {{formatNumber(200)}} دلاری در فاز دوم</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تقسیم سود تا سقف {{formatNumber(90)}} درصد</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span  v-if="plan == 'limited'">دارای محدودیت زمانی</span>
                    <span  v-if="plan == 'noLimited'">بدون محدودیت زمانی</span>
                </li>
            </ul>
            <a href="#"
                class="block px-8 py-3 text-sm font-semibold text-center text-gray-100 transition duration-100 bg-white rounded-lg outline-none bg-opacity-10 hover:bg-opacity-20 lg:text-base">شروع چالش</a>
        </div>

        <div class="w-full p-6 rounded-lg shadow-xl lg:w-1/4 bg-gradient-to-br from-TFL-base1 to-purple-800 sm:p-6">
            <div class="flex flex-col items-start justify-between gap-4 mb-6 lg:flex-row">
                <div>
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-white  to-gray-200  text-transparent bg-clip-text   sm:text-4xl">$1000</h3>
                </div>
                <span class="order-first inline-block px-3 py-1 text-xs font-semibold tracking-wider text-white uppercase bg-black rounded-full lg:order-none bg-opacity-20">منتخب</span>
            </div>
            
            <div class="mb-4 space-x-2 flex justify-between">
                <span v-if="plan == 'limited'" class="text-2xl font-Montserrat text-gray-100">$19</span>
                <!-- <div  v-if="plan == 'limited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">37%</div>
                    <span v-if="plan == 'limited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$19</span>
                </div> -->
                <span v-if="plan == 'noLimited'" class="text-2xl font-Montserrat text-gray-100">$25</span>
                <!-- <div  v-if="plan == 'noLimited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">40%</div>
                    <span v-if="plan == 'noLimited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$25</span>
                </div> -->
            </div>
            <ul class="mb-6 space-y-2 text-gray-300">
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(60)}} / {{formatNumber(80)}} دلاری در فاز اول</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(60)}} / {{formatNumber(40)}} دلاری در فاز دوم</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تقسیم سود تا سقف {{formatNumber(90)}} درصد</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span v-if="plan == 'limited'">دارای محدودیت زمانی</span>
                    <span v-if="plan == 'noLimited'">بدون محدودیت زمانی</span>
                </li>
            </ul>
            <a href="#"
                class="block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-white rounded-lg outline-none bg-opacity-20 hover:bg-opacity-30 lg:text-base">شروع چالش</a>
        </div>
        <div class="w-full p-6 bg-gray-700 border border-gray-700 rounded-lg lg:w-1/4 bg-opacity-50 sm:rounded-r-none sm:p-6">
            <div class="mb-6">
                <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-4xl">$10000</h3>
            </div>
            
            <div class="mb-4 space-x-2 flex justify-between">
                <span v-if="plan == 'limited'" class="text-2xl font-Montserrat text-gray-100">$79</span>
                <!-- <div  v-if="plan == 'limited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">13%</div>
                    <span v-if="plan == 'limited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$79</span>
                </div> -->
                <span v-if="plan == 'noLimited'" class="text-2xl font-Montserrat text-gray-100">$99</span>
                <!-- <div  v-if="plan == 'noLimited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">30%</div>
                    <span v-if="plan == 'noLimited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$99</span>
                </div> -->
            </div>
            <ul class="mb-6 space-y-2 text-gray-300">
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(600)}} / {{formatNumber(800)}} دلاری در فاز اول</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(600)}} / {{formatNumber(400)}} دلاری در فاز دوم</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تقسیم سود تا سقف {{formatNumber(90)}} درصد</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span v-if="plan == 'limited'">دارای محدودیت زمانی</span>
                    <span v-if="plan == 'noLimited'">بدون محدودیت زمانی</span>
                </li>
            </ul>
            <a href="#"
                class="block px-8 py-3 text-sm font-semibold text-center text-gray-100 transition duration-100 bg-white rounded-lg outline-none bg-opacity-10 hover:bg-opacity-20 lg:text-base">شروع چالش</a>
        </div>
        <div class="w-full p-6 bg-gray-700 border border-gray-700 rounded-[36px] lg:w-1/4 bg-opacity-50 sm:rounded-r-none sm:px-4 sm:py-6">
            <div class="mb-6">
                <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-4xl">$25000</h3>
            </div>
            
            <div class="mb-4 space-x-2 flex justify-between">
                <span v-if="plan == 'limited'" class="text-2xl font-Montserrat text-gray-100">$159</span>
                <!-- <div  v-if="plan == 'limited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">13%</div>
                    <span v-if="plan == 'limited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$79</span>
                </div> -->
                <span v-if="plan == 'noLimited'" class="text-2xl font-Montserrat text-gray-100">$169</span>
                <!-- <div  v-if="plan == 'noLimited'" class="flex">
                    <div class="bg-red-600 mx-1 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">30%</div>
                    <span v-if="plan == 'noLimited'" class="line-through decoration-red-600 text-2xl font-Montserrat text-gray-100">$99</span>
                </div> -->
            </div>
            <ul class="mb-6 space-y-2 text-gray-300">
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(1500)}} / {{formatNumber(2000)}} دلاری در فاز اول</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تارگت سود {{formatNumber(1500)}} / {{formatNumber(1000)}} دلاری در فاز دوم</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>تقسیم سود تا سقف {{formatNumber(90)}} درصد</span>
                </li>
                <li class="flex items-center gap-1.5">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span v-if="plan == 'limited'">دارای محدودیت زمانی</span>
                    <span v-if="plan == 'noLimited'">بدون محدودیت زمانی</span>
                </li>
            </ul>
            <a href="#"
                class="block px-8 py-3 text-sm font-semibold text-center text-gray-100 transition duration-100 bg-white rounded-lg outline-none bg-opacity-10 hover:bg-opacity-20 lg:text-base">شروع چالش</a>
        </div>
    </div>
</div>
        </div>
        <div ref="targetDiv3" class="grid grid-cols-2 max-w-screen-xl  mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">دریافت سرمایه بدون چالش در پلن مستقیم  </p>
            <p class="mb-3 col-span-2 font-light text-gray-300 sm:text-lg text-base sm:mx-20 mx-8 my-10 text-justify">در پلن مستقیم بدون نیاز به گذراندن چالش، از همان ابتدا حساب ریل تا سقف {{formatNumber(100000)}} دلار در اختیار معامله‌گر قرار خواهد گرفت. در این پلن هیچگونه تارگت سودی وجود نخواهد داشت و تنها استمرار و مدیریت ریسک معامله‌گر بررسی خواهد شد. این پلن با یکبار پرداخت، بدون محدودیت زمانی در اختیار معامله‌گر قرار خواهد گرفت و می‌تواند به دور از شرایط چالش، به کسب درآمد بپردازد.</p>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-4 mx-auto px-2">
            <div class="relative flex col-span-2  justify-center    ">
                <div class="absolute -inset-1  bg-gradient-to-t from-TFL-base1 via-TFL-background to-TFL-background from-5% via-30% rounded-full blur opacity-75 mx-24"></div>
                <div class="relative">
                    <img alt="banner image" class="justify-self-center object-scale-down rounded-full relative" src="../assets/images/TFL-noChallenge.png" loading="lazy" decoding="async">
                    <!-- <div class="absolute  inset-x-0 top-2/3 text-center lg:text-8xl text-4xl drop-shadow-[rgba(0,_0,_0,_0.8)_0px_6px_6px] font-Montserrat   text-transparent bg-clip-text bg-gradient-to-b from-TFL-base1  to-TFL-base2  ">
                          UP TO $100000
                    </div> -->
                    <div class="absolute  inset-x-0 top-2/3 text-center lg:text-8xl text-4xl drop-shadow-[rgba(0,_0,_0,_1)_0px_8px_8px] font-Montserrat   text-gray-300  ">
                          UP TO $100000
                    </div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  mt-20 mx-auto">
            <div class="lg:col-span-1 col-span-2 relative bg-gray-700 border border-gray-700 bg-opacity-50 rounded-[36px]  lg:mr-40 mx-2  ">
                <div class="absolute  sm:-top-8 sm:-right-8 -top-8 -right-4 flex  justify-center    ">
                <img alt="banner image" class=" justify-self-center object-scale-down h-24  "   src="../assets/images/TFL-planet.png" loading="lazy" decoding="async">
            </div>
                <ul class="mb-6 space-y-4 text-gray-300 px-10 pt-4 sm:text-lg text-sm my-10">
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">برداشت سود پس از {{formatNumber(12)}} روز معاملاتی </span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">سهم سود تریدر تا سقف {{formatNumber(14)}} درصد</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">حداکثر افت شناور {{formatNumber(2)}} درصدی</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">حداکثر افت روزانه {{formatNumber(5)}} درصدی</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="hover:bg-gradient-to-r hover:from-TFL-base2  hover:to-TFL-base1  hover:text-transparent hover:bg-clip-text bg-gradient-to-r from-gray-200  to-gray-300  text-transparent bg-clip-text">حداکثر افت کلی {{formatNumber(12)}} درصدی</span>
                </li>
                </ul>
                
            </div>
            <div class="lg:col-span-1 col-span-2 sm:mx-20 mx-2">
                <p  class=" p-2 py-4 col-span-2  font-IRANSansBold lg:text-lg text-base  bg-gradient-to-l from-gray-100  to-gray-600 from-1%   text-transparent bg-clip-text ">میزان سرمایه </p>
                <div class="grid grid-cols-3">
                    <button @click="chooseCap('K1')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.cap !== 'K1','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.cap === 'K1' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">1000   </button>
                    <button @click="chooseCap('K5')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.cap !== 'K5','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.cap === 'K5' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">5000   </button>
                    <button @click="chooseCap('K10')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.cap !== 'K10','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.cap === 'K10' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">10000   </button>
                    <button @click="chooseCap('K25')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.cap !== 'K25','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.cap === 'K25' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">25000   </button>
                    <button @click="chooseCap('K50')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.cap !== 'K50','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.cap === 'K50' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">50000   </button>
                    <button @click="chooseCap('K100')" :class="{ 'bg-gradient-to-r from-gray-300  to-gray-400 border-2 border-gray-600 border-opacity-50 rounded-full': this.cap !== 'K100','bg-gradient-to-r from-TFL-base2  to-TFL-base1 border-2 border-gray-400 border-opacity-70  rounded-full': this.cap === 'K100' }" class=" col-span-1 inline-flex items-center justify-center py-1 px-2 m-1 text-xl font-Montserrat text-transparent bg-clip-text     sm:text-3xl ">100000   </button>
                        
                </div>
                <p  class=" p-2 py-4 col-span-2 mx-2 font-IRANSansBold lg:text-lg text-base  bg-gradient-to-l from-gray-100  to-gray-600   text-transparent bg-clip-text ">تعرفه  </p>
                <div class="rounded-full bg-gray-800 bg-opacity-50 border-2 border-gray-700 p-2 flex justify-between ">
                    <button class="bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 px-4 py-2 font-IRANSansBold lg:text-lg text-sm rounded-full font-medium focus:ring ring-black ring-opacity-10 gradient element-to-rotate m-1"> دریافت سرمایه</button>
                    <!-- <div class="flex" v-if="cap == 'K1'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">25%</div>
                        <div v-if="cap == 'K1'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$12   </div>

                    </div> -->
                    <p v-if="cap == 'K1'"  class="  p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$12  </p>
                    <!-- <div class="flex" v-if="cap == 'K5'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">20%</div>
                        <div v-if="cap == 'K5'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$49   </div>

                    </div> -->
                    <p v-if="cap == 'K5'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$49  </p>
                    <!-- <div class="flex" v-if="cap == 'K10'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">22%</div>
                        <div v-if="cap == 'K10'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$89   </div>

                    </div> -->
                    <p v-if="cap == 'K10'"  class="  p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$89  </p>
                    <!-- <div class="flex" v-if="cap == 'K25'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">22%</div>
                        <div v-if="cap == 'K25'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$229   </div>

                    </div> -->
                    <p v-if="cap == 'K25'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$229  </p>
                    <!-- <div class="flex" v-if="cap == 'K50'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">15%</div>
                        <div v-if="cap == 'K50'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$449   </div>

                    </div> -->
                    <p v-if="cap == 'K50'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$449  </p>
                    <!-- <div class="flex" v-if="cap == 'K100'">
                        <div class="bg-red-600 my-auto px-2 text-white rounded rounded-lg font-Montserrat text-sm">16%</div>
                        <div v-if="cap == 'K100'"  class=" line-through decoration-red-600 py-2 mx-1 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$899   </div>

                    </div> -->
                    <p v-if="cap == 'K100'"  class=" p-2 mx-4 col-span-2  font-Montserrat lg:text-2xl text-xl  text-gray-200 ">$899  </p>
            
                </div>
            </div>
        </div>
        <div class="grid grid-cols-2 max-w-screen-xl  my-2 mt-20 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">مقایسه پلن‌های دریافت سرمایه</p>
        </div>
        <div class=" max-w-screen-xl  mx-auto ">
            <div class="relative overflow-x-auto shadow-md rounded-2xl sm:mx-20 mx-4">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500   ">
        <thead class="text-xs text-gray-700 uppercase  ">
            
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300" >
                    <h3 class="text-xs  font-IRANSansBold    sm:text-lg">تعرفه و شرایط </h3>
                </th>
                <td class="px-6 py-4 ">
                    <p class="  font-IRANSansBold sm:text-lg mx-2 bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">چالش دو مرحله‌ای </p>
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">دارای محدودیت زمانی</h3>
                </td>
                <td class="px-6 py-4 ">
                    <p class="  font-IRANSansBold sm:text-lg mx-2 bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">چالش دو مرحله‌ای </p>
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">بدون محدودیت زمانی</h3>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <p class="  font-IRANSansBold sm:text-lg mx-2 bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text"> پلن مستقیم  </p>
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">بدون چالش</h3>
                </td>
            </tr>
        </thead>
        <tbody>
            <tr class=" text-center ">
                <th scope="row" class="px-6 py-4 ">
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-2xl">$1000</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$19</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$25</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$12</h2>
                </td>
            </tr>
            <tr class=" text-center ">
                <th scope="row" class="px-6 py-4  ">
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-2xl">$5000</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$49</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$69</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$49</h2>
                </td>
            </tr>
            <tr class=" text-center ">
                <th scope="row" class="px-6 py-4  ">
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-2xl">$10000</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$79</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$99</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$89</h2>
                </td>
            </tr>
            <tr class=" text-center ">
                <th scope="row" class="px-6 py-4  ">
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-2xl">$25000</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$159</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$169</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$229</h2>
                </td>
            </tr>
            <tr class=" text-center   ">
                <th scope="row" class="px-6 py-4   ">
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-2xl">$50000</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">-</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">-</h2>
                </td>
                <td class="px-6 py-4 " >
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$449</h2>
                </td>
            </tr>
            <tr class=" text-center     ">
                <th scope="row" class="px-6 py-4   ">
                    <h3 class="text-2xl font-Montserrat bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text   sm:text-2xl">$100000</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">-</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">-</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-2xl font-Montserrat text-white   sm:text-xl">$899</h2>
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-IRANSansBold     sm:text-sm">حداقل روزهای معاملاتی</h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">0</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">0</h2>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg ">12</h2>
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300  ">
                    <h3 class="text-xs  font-IRANSansBold     sm:text-sm">مدت زمان</h3>
                </th>
                <td class="px-6 py-4 flex justify-center">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">30 , 60  </h2>
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 my-auto ">روز </h3>
                </td>
                <td class="px-6 py-4 ">
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">بدون محدودیت </h3>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2  ">بدون محدودیت </h3>
                
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-IRANSansBold    sm:text-sm">تارگت سود</h3>
                </th>
                <td class="px-6 py-4">
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">تارگت سود فاز اول 6 یا 8 درصد</h3>
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">تارگت سود فاز دوم 6 یا 4 درصد</h3>
                </td>
                <td class="px-6 py-4 ">
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">تارگت سود فاز اول 6 یا 8 درصد</h3>
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">تارگت سود فاز دوم 6 یا 4 درصد</h3>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h3 class="text-xs text-white font-IRANSansBold  mx-2 ">بدون محدودیت</h3>
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 " >
                    <h3 class="text-xs  font-IRANSansBold   sm:text-sm">حداکثر سهم تریدر   </h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">90%</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">90%</h2>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg ">14%</h2>
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 " >
                    <h3 class="text-xs  font-IRANSansBold      sm:text-sm"> دراودان روزانه </h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">5%</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">5%</h2>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">5%</h2>
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 " >
                    <h3 class="text-xs  font-IRANSansBold     sm:text-sm"> دراودان کلی </h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">12%</h2>
                </td>
                <td class="px-6 py-4 ">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">12%</h2>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">12%</h2>
                </td>
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300  " >
                    <h3 class="text-xs font-IRANSansBold     sm:text-sm"> پلتفرم معاملاتی  </h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">MT4</h2>
                </td>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">MT4</h2>
                </td>
                <td class="px-6 py-4  bg-gray-700">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">MT4</h2>
                </td>
                
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row"  class="px-6 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-IRANSansBold     sm:text-sm"> اهرم حساب   </h3>
                </th>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">1:100</h2>
                </td>
                <td class="px-6 py-4">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">1:100</h2>
                </td>
                <td class="px-6 py-4  bg-gray-700 bg-gray-700">
                    <h2 class="text-md font-Montserrat text-white   sm:text-lg">1:100</h2>
                </td>
               
                
            </tr>
            
           
           
            
        </tbody>
    </table>
        </div>
        </div>
         
        <footer >
            <div class=" max-w-screen-xl text-sm  mx-auto border-y-2 mt-20 my-4  border-TFL-base1 lg:px-8">
                <p class=" p-2 mt-8 mb-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">درباره مجموعه ترید برای زندگی  </p>
                <p class="   font-IRANSansBold text-sm text-justify lg:mx-20 mx-8 text-gray-300 " style="line-height: 2;">این مجموعه اولین بار در سال ۱۳۹۹ تحت عنوان آکادمی ترید برای زندگی با هدف انتقال دانش و آموزش معامله‌گران فعالیت خود را آغاز نمود. </p>
                <p class="   font-IRANSansBold text-sm text-justify lg:mx-20 mx-8 text-gray-300" style="line-height: 2;" >مجموعه ترید برای زندگی در راستای گسترش محدوده‌ی فعالیت، به ایجاد محیط شبیه‌سازی شده بازارهای مالی، با اهداف آموزش و تقویت مهارت معامله‌گران نوآموز و جذب معامله‌گران خبره، اقدام نموده است. در راستای تحقق این هدف تلاش شده است تا با ایجاد مجموعه‌ای متشکل از سرمایه‌گذاران، تیم فنی و کارشناسان با تجربه، یکی از بهترین زیر ساخت‌های حال حاضر خدمت شما عزیزان ارائه شود. </p>
                <p class="   font-IRANSansBold text-sm text-justify lg:mx-20 mx-8 mb-8 text-gray-300" style="line-height: 2;">پشتوانه اصلی این مجموعه تا به امروز اعتماد شما عزیزان بوده است. در ادامه مسیر نیز کوشش خواهد شد تا خدماتی شایسته، در شرایط تحریم‌های بین‌المللی به شما عزیزان ارائه شود تا با جلوگیری از خروج ارز از کشور و کاهش ریسک همکاری با پراپ‌های خارجی، بستری
حرفه‌ای در جهت آموزش، توانمندسازی، تامین سرمایه و درآمدزایی معامله‌گران مستعد ارائه شود.</p>

                

            </div>
            <p class=" p-2 py-4 font-IRANSansBold text-xs text-center  mx-auto text-gray-400 "> تمامی حقوق این وب‌سایت متعلق به مجموعه ترید برای زندگی است</p>
            
        </footer>
        <div class="max-w-screen-xl flex justify-center  mx-auto ">
                <a  referrerpolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=400502&Code=4hhZdlUEoo6J9woG2c0mAWUNthVtHdWO'><img referrerpolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=400502&Code=4hhZdlUEoo6J9woG2c0mAWUNthVtHdWO' alt='' style='cursor:pointer' Code='4hhZdlUEoo6J9woG2c0mAWUNthVtHdWO'></a>
              
            </div>
    
    </div>
</template>
<script>
import { digitsEnToFa } from 'persian-tools';

export default {

    data() {
    return {
      numberFromBuyTime: 60,
      numberFromWithdrawTime: 72,
      numberFromPrice: 500,
      numberFromScaleUp: 0,
      numberToBuyTime: 1,
      numberToWithdrawTime: 8,
      numberToPrice: 12,
      numberToScaleUp: 2,
      duration: 5,
      scaleClass: false,
      whichButton: 'Phase1',
      activeCard: 1,
      plan: 'limited',
      panel: 'Analysis',
      cap: 'K1',
      Prize: 'K250',
    };
  },
  mounted(){
    this.playAnimation();
  },
    methods: {
        scrollToDiv1() {
      this.$refs.targetDiv1.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
        scrollToDiv2() {
      this.$refs.targetDiv2.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
        scrollToDiv3() {
      this.$refs.targetDiv3.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
        changeWhichButton(value){
            this.whichButton = value;
            
        },
    chooseCap(value){
        this.cap = value;
    },
    choosePrize(value){
        this.Prize = value;
    },
    choosePlan(value){
        this.plan = value;
    },
    choosePanel(value){
        this.panel = value;
    },
    Phase1Button(){
        this.whichButton = 'Phase1';
    },
    Phase2Button(){
        this.whichButton = 'Phase2';
    },
    RealButton(){
        this.whichButton = 'Real';
    },
    theFormat(number) {
      return this.formatNumber(number.toFixed(0));
    },
    completed() {
      
      this.scaleClass = true;
    },
    playAnimation() {
      
      this.scaleClass = false;
      this.$refs.number1.play();
      this.$refs.number2.play();
      this.$refs.number3.play();
    //   this.$refs.number4.play();
    },
    setActiveCard(cardNumber) {
      this.activeCard = cardNumber;
    },
        formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
  }
}
</script>

<style>
.skew-line {
  position: relative;
  padding: 2px;
  
}

.skew-line::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 28px;
  width: 4px;
  background-color: #a90303;
  transform: skew(-45deg);
  transform-origin: left center;
}


</style>

