<template>
    <div id="appl">
      <div id="chart" v-if="series.length > 0">
        <apexchart width="100%" type="line" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </template>
  
  <script>
  import VueApexCharts from 'vue3-apexcharts';
  import axios from 'axios';
  import URL from "../URL";
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      tradeHistory: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        accountuId: this.$route.params.id,
        resizeTimeout: null,
        isResizing: false,
        options: {
          chart: {
            id: 'vuechart-example',
            animations: {
          enabled: false,
        },
          },
          grid: {
    show: true,
    strokeDashArray: 0,
    xaxis: {
      lines: {
        show: false
      }
    }, 
    yaxis: {
      lines: {
        show: false
      }
    },
    stroke: {
      colors: ['#d1d1cf'], // Change this to the color you want for the horizontal grid lines
      width: 1
    },
  },
          xaxis: {
            
         
          labels: {
            
            style: {
              colors: ['#d1d1cf'], // Change this to the color you want for x-axis numbers
            },
          },
          axisBorder: {
            show: true, // Set this to true to show the y-axis line
            color: '#666666', // Change this to the color you want for the y-axis line
                  
          },
        },
          yaxis: {
            axisBorder: {
              show: true, // Set this to true to show the y-axis line
              color: '#666666', // Change this to the color you want for the y-axis line
              offsetX: 30,
            },
            labels: {
              offsetX:-30,
              
              style: {
                colors: ['#d1d1cf'], // Change this to the color you want for x-axis numbers
              },
            },
          },
          stroke: {
            curve: 'smooth',
          },
          legend: {
            show: true,
            showForSingleSeries: true,
          },
          colors: ['#849BFF'],
          fill: {
          type: 'gradient',
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0,
            colorStops: [
              {
                offset: 0,
                color: '#849BFF', // Starting color (green)
                opacity: 0.4,
              },
              {
                offset: 100,
                color: '#849BFF', // Ending color (green)
                opacity: 0,
              },
            ],
          },
        },

        },
        series: [],
      };
    },
    methods: {
     
      updateSeries() {
        if (this.tradeHistory && this.tradeHistory.length > 0) {
    let cumulativeProfit = 0;
    const seriesData = this.tradeHistory.map((item, index) => {
      cumulativeProfit += item.totalProfit;
      return [index, cumulativeProfit.toFixed(1)];
    });
  
          this.series = [
            {
            type: 'area',
              name: 'Balance',
              data: seriesData,
            },
          ];
        }
      },
      handleResize() {
    if (!this.isResizing) {
      this.isResizing = true;
      this.updateSeries(); // Update series immediately
      setTimeout(() => {
        this.isResizing = false;
      }, 300); // Adjust the delay as needed
    }
  },
    },
    created() {
    this.updateSeries();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  };
  </script>

  <style>
 
  </style>