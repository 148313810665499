

<template>
<div v-if="loading" class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-[#0a0018] flex items-center justify-center">
   <img src="../assets/gif/TFL-Loading1.gif" alt="TFL Loading" class="h-80 w-80 mx-auto" :key="gifKey">
</div>
<!-- <div  class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-[#0a0018] flex items-center justify-center ">
   <div class="mx-4 lg:mx-40">
      <div    class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div class="fixed inset-0  transition-opacity"></div>

                    <div class="fixed inset-0 z-10 overflow-y-auto">
                        <div class="flex min-h-full items-center justify-center p-4 text-center  sm:items-center sm:p-0">
                            
                            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2 w-full   text-right shadow-xl transition-all sm:my-2 sm:w-full sm:max-w-lg">
                                <div class="col-span-3 lg:col-span-2   m-2 p-4 bg-TFL-card1  rounded-lg shadow sm:p-2 ">
                                 <p class="text-white text-justify">کاربر گرامی، بدینوسیله به استحضار می‌رساند به دلیل انجام فرآیند به‌روزرسانی فنی، از تاریخ 29 ژانویه 2024، به مدت یک هفته، تمامی فعالیت‌های مجموعه متوقف گردیده‌است. </p>
      <p class="text-white text-justify">به منظور ارائه حفظ کیفیت خدمات مجموعه، در پلن چالش دو مرحله‌ای، تعداد حداقل روزهای معاملاتی به 1 روز کاهش یافته و مبلغ ریفاند حساب،  100 درصد افزایش خواهد داشت. همچنین در حساب‌های پلن مستقیم نیز 2 درصد به تقسیم سود در مرحله فعلی افزوده خواهد شد.</p>
      <p class="text-white text-justify">لازم به ذکر است در این مدت از انجام هرگونه معامله خودداری نمایید.</p>
      <p class="text-white text-justify">امید است بتوانیم در ادامه مسیر خدماتی شایسته خدمت شما عزیزان ارائه دهیم.</p>
      <p class="text-white text-justify">باتشکر از صبوری شما</p>
                                  
                                            
                                                
                                </div>
                                                
                            
                            
                        </div>
                        </div> 
                    </div>
                    </div>    
     
   </div>
</div> -->



   <nav class="relative px-4 py-4 flex justify-between items-center  bg-TFL-cardDashboard">
		<a class="text-3xl font-bold leading-none" href="#">
			<img src="../assets/images/TFL-Logo.svg" class="h-10 mr-3  ">
		</a>
		<div class="lg:hidden">
			<button @click="openSidebar" data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200   ">
            <span class="sr-only">Open sidebar</span>
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
               <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
         </button>
		</div>
		
		
		<div  class="relative inline-block ">
    
    <button @click="openProfileIcon" class="relative z-10 block p-2 text-gray-700 bg-TFL-cardDashboard border border-transparent rounded-md  focus:border-blue-500 focus:ring-opacity-40  focus:ring-blue-300  focus:ring  focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
            <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
        </svg>
    </button>

    
    <div v-if="isOpen"
        
        x-transition:enter="transition ease-out duration-100"
        x-transition:enter-start="opacity-0 scale-90"
        x-transition:enter-end="opacity-100 scale-100"
        x-transition:leave="transition ease-in duration-100"
        x-transition:leave-start="opacity-100 scale-100"
        x-transition:leave-end="opacity-0 scale-90"
        class="absolute left-0 z-20 w-48 py-2 mt-2 origin-top-left bg-TFL-cardDashboard rounded-md shadow-xl "
    >
        <router-link to="/Dashboard/Profile" class="flex items-center px-3 py-3 text-sm text-gray-300 capitalize transition-colors duration-300 transform  hover:bg-gray-600 ">
            <svg class="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 8C7 5.23858 9.23858 3 12 3C14.7614 3 17 5.23858 17 8C17 10.7614 14.7614 13 12 13C9.23858 13 7 10.7614 7 8ZM12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z" fill="currentColor"></path>
                <path d="M6.34315 16.3431C4.84285 17.8434 4 19.8783 4 22H6C6 20.4087 6.63214 18.8826 7.75736 17.7574C8.88258 16.6321 10.4087 16 12 16C13.5913 16 15.1174 16.6321 16.2426 17.7574C17.3679 18.8826 18 20.4087 18 22H20C20 19.8783 19.1571 17.8434 17.6569 16.3431C16.1566 14.8429 14.1217 14 12 14C9.87827 14 7.84344 14.8429 6.34315 16.3431Z" fill="currentColor"></path>
            </svg>

            <span class="mx-1">
                پروفایل 
            </span>
        </router-link>

        

              
        <hr class="border-gray-500  ">

        
        <button @click="logout" class="flex items-center p-3 text-sm text-gray-300 capitalize transition-colors duration-300 transform  hover:bg-gray-600 ">
            <svg class="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z" fill="currentColor"></path>
            </svg>

            <span  class="mx-1">
                 خروج
            </span>
         </button>
    </div>
</div>

	</nav>
    
    <div class="flex flex-row bg-ali-800  ">
      
       


<aside v-if="fullLoad" id="default-sidebar"  class="  lg:block hidden sidebar lg:w-1/8 bg-TFL-background2 min-h-full transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
   <div class="h-full px-3 py-4 overflow-y-auto    p-4 m-4 ml-0 bg-TFL-cardDashboard   shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      
      <ul class="space-y-2 font-medium">
        
         <li>
            <router-link to="/Dashboard/Home" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M256,319.841c-35.346,0-64,28.654-64,64v128h128v-128C320,348.495,291.346,319.841,256,319.841z"/>
	<g>
		<path d="M362.667,383.841v128H448c35.346,0,64-28.654,64-64V253.26c0.005-11.083-4.302-21.733-12.011-29.696l-181.29-195.99    c-31.988-34.61-85.976-36.735-120.586-4.747c-1.644,1.52-3.228,3.103-4.747,4.747L12.395,223.5    C4.453,231.496-0.003,242.31,0,253.58v194.261c0,35.346,28.654,64,64,64h85.333v-128c0.399-58.172,47.366-105.676,104.073-107.044    C312.01,275.383,362.22,323.696,362.667,383.841z"/>
		<path d="M256,319.841c-35.346,0-64,28.654-64,64v128h128v-128C320,348.495,291.346,319.841,256,319.841z"/>
	</g>
               </svg>
               <span class=" ml-3  px-3 "> خانه</span>
               
            </router-link>
         </li>
         <li>
            <router-link to="/Dashboard/DAccountList" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M42.667,373.333H96c23.564,0,42.667,19.102,42.667,42.667v53.333C138.667,492.898,119.564,512,96,512H42.667   C19.103,512,0,492.898,0,469.333V416C0,392.436,19.103,373.333,42.667,373.333z"/>
	<path d="M493.184,380.629c-7.039-4.768-15.349-7.31-23.851-7.296H416c-23.564,0-42.667,19.102-42.667,42.667v53.333   C373.333,492.898,392.436,512,416,512h53.333C492.898,512,512,492.898,512,469.333V416   C511.998,401.815,504.946,388.559,493.184,380.629z"/>
	<path d="M42.667,186.667H96c23.564,0,42.667,19.103,42.667,42.667v53.333c0,23.564-19.103,42.667-42.667,42.667H42.667   C19.103,325.333,0,306.231,0,282.667v-53.333C0,205.769,19.103,186.667,42.667,186.667z"/>
	<path d="M493.184,193.963c-7.039-4.768-15.349-7.31-23.851-7.296H416c-23.564,0-42.667,19.103-42.667,42.667v53.333   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.103,42.667-42.667v-53.333   C511.998,215.148,504.946,201.892,493.184,193.963z"/>
	<path d="M42.667,0H96c23.564,0,42.667,19.103,42.667,42.667V96c0,23.564-19.103,42.667-42.667,42.667H42.667   C19.103,138.667,0,119.564,0,96V42.667C0,19.103,19.103,0,42.667,0z"/>
	<path d="M306.517,380.629c-7.039-4.768-15.349-7.31-23.851-7.296h-53.333c-23.564,0-42.667,19.102-42.667,42.667v53.333   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.102,42.667-42.667V416   C325.331,401.815,318.279,388.559,306.517,380.629z"/>
	<path d="M306.517,193.963c-7.039-4.768-15.349-7.31-23.851-7.296h-53.333c-23.564,0-42.667,19.103-42.667,42.667v53.333   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.103,42.667-42.667v-53.333   C325.331,215.148,318.279,201.892,306.517,193.963z"/>
	<path d="M306.517,7.296c-7.039-4.768-15.349-7.31-23.851-7.296h-53.333c-23.564,0-42.667,19.103-42.667,42.667V96   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.103,42.667-42.667V42.667   C325.331,28.482,318.279,15.225,306.517,7.296z"/>
	<path d="M504.704,18.816C496.775,7.054,483.518,0.002,469.333,0H416c-23.564,0-42.667,19.103-42.667,42.667V96   c0,23.564,19.103,42.667,42.667,42.667h53.333C492.898,138.667,512,119.564,512,96V42.667   C512.014,34.165,509.472,25.855,504.704,18.816z"/>

               </svg>
               <span class=" ml-3  px-3 "> لیست حساب‌</span>
               
            </router-link>
         </li>
         <li>
            <router-link to="/Dashboard/NewChallenge" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M2.751,15H0V14A7.634,7.634,0,0,1,4.065,7.169a10.975,10.975,0,0,1,3.9-1.09q-.889,1.1-1.783,2.338A40.582,40.582,0,0,0,2.751,15Zm12.832,2.813A40.582,40.582,0,0,1,9,21.249V24h1a7.634,7.634,0,0,0,6.831-4.065,10.975,10.975,0,0,0,1.09-3.9Q16.826,16.92,15.583,17.813ZM24,2.991c-.133,4.353-3.267,8.67-9.582,13.2A34.995,34.995,0,0,1,9,19.063V18.5A3.517,3.517,0,0,0,5.5,15H4.937A34.912,34.912,0,0,1,7.813,9.583C12.332,3.278,16.642.144,20.988,0,23.154,0,24,.885,24,2.991ZM18,8.5a2.5,2.5,0,0,0-5,0A2.5,2.5,0,0,0,18,8.5ZM1.374,23.785c1.126-.2,3.841-.758,4.748-1.664h0a3,3,0,0,0-4.243-4.243C.973,18.785.414,21.5.215,22.626l-.247,1.406Z"/>
               </svg>
               <span class="ml-3 px-3">چالش جدید </span>
               
            </router-link>
         </li>
         <li>
            <router-link to="/Dashboard/Dwithdrawal" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M9,4c0-2.209,3.358-4,7.5-4s7.5,1.791,7.5,4-3.358,4-7.5,4-7.5-1.791-7.5-4Zm7.5,6c-1.027,0-2.001-.115-2.891-.315-1.359-1.019-3.586-1.685-6.109-1.685-4.142,0-7.5,1.791-7.5,4s3.358,4,7.5,4,7.5-1.791,7.5-4c0-.029-.007-.057-.008-.086h.008v2.086c0,2.209-3.358,4-7.5,4S0,16.209,0,14v2c0,2.209,3.358,4,7.5,4s7.5-1.791,7.5-4v2c0,2.209-3.358,4-7.5,4S0,20.209,0,18v2c0,2.209,3.358,4,7.5,4s7.5-1.791,7.5-4v-.08c.485,.052,.986,.08,1.5,.08,4.142,0,7.5-1.791,7.5-4v-2c0,2.119-3.092,3.849-7,3.987v-2c3.908-.138,7-1.867,7-3.987v-2c0,2.119-3.092,3.849-7,3.987v-2c3.908-.138,7-1.867,7-3.987v-2c0,2.209-3.358,4-7.5,4Z"/>
               </svg>
               <span class="ml-3 px-3">برداشت سود  </span>
            </router-link>
         </li>
         <li>
            <router-link to="/Dashboard/Profile" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M16.043,14H7.957A4.963,4.963,0,0,0,3,18.957V24H21V18.957A4.963,4.963,0,0,0,16.043,14Z"/><circle cx="12" cy="6" r="6"/>
               </svg>
               <span class="ml-3 px-3">پروفایل </span>
            </router-link>
         </li>
         <li>
            <router-link to="/Dashboard/Tickets" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24,11.309V24H12.018A12,12,0,1,1,24,11.246ZM13,7H7V9h6Zm4,4H7v2H17Zm0,4H7v2H17Z"/>
               </svg>
               
               <span class="ml-3 px-3">پشتیبانی </span>
               <span v-if="newTicket == true" class="relative justify-self-center mx-2 flex h-3 w-3 mr-auto">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75 mr-auto"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-600 mr-auto"></span>
                  </span>
            </router-link>
         </li>
         <li>
            <router-link to="/Dashboard/Referral" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m10,10c0,.378.271.698.644.76l3.041.507c1.342.223,2.315,1.373,2.315,2.733,0,1.654-1.346,3-3,3v2h-2v-2c-1.654,0-3-1.346-3-3h2c0,.551.448,1,1,1h2c.552,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.041-.507c-1.342-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-2h2v2c1.654,0,3,1.346,3,3h-2c0-.551-.448-1-1-1h-2c-.552,0-1,.449-1,1Zm7.519-2.105l2.188-2.188c.392.188.83.293,1.293.293,1.654,0,3-1.346,3-3s-1.346-3-3-3-3,1.346-3,3c0,.463.105.901.293,1.293l-1.986,1.986c.506.451.921.995,1.212,1.616ZM6,3c0-1.654-1.346-3-3-3S0,1.346,0,3s1.346,3,3,3c.463,0,.901-.105,1.293-.293l2.188,2.188c.291-.621.706-1.165,1.212-1.616l-1.986-1.986c.188-.392.293-.83.293-1.293Zm15,15c-.463,0-.901.105-1.293.293l-2.188-2.188c-.291.621-.706,1.165-1.212,1.616l1.986,1.986c-.188.392-.293.83-.293,1.293,0,1.654,1.346,3,3,3s3-1.346,3-3-1.346-3-3-3Zm-14.519-1.895l-2.188,2.188c-.392-.188-.83-.293-1.293-.293-1.654,0-3,1.346-3,3s1.346,3,3,3,3-1.346,3-3c0-.463-.105-.901-.293-1.293l1.986-1.986c-.506-.451-.921-.995-1.212-1.616Z"/>
               </svg>
               
               <span class="ml-3 px-3">رفرال </span>
               
            </router-link>
         </li>
         
      </ul>
   </div>
</aside>
<div  class="router-view-container flex-1 min-h-screen bg-TFL-background2  ">
    <router-view></router-view>

      </div>



</div>
<div v-if="showMenu && fullLoad" class="fixed top-20 left-0 w-full h-full bg-TFL-cardDashboard z-50 ">
         <ul class="space-y-2 font-medium">
         
         <li>
            <router-link @click="openSidebar" to="/Dashboard/Home" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M256,319.841c-35.346,0-64,28.654-64,64v128h128v-128C320,348.495,291.346,319.841,256,319.841z"/>
	<g>
		<path d="M362.667,383.841v128H448c35.346,0,64-28.654,64-64V253.26c0.005-11.083-4.302-21.733-12.011-29.696l-181.29-195.99    c-31.988-34.61-85.976-36.735-120.586-4.747c-1.644,1.52-3.228,3.103-4.747,4.747L12.395,223.5    C4.453,231.496-0.003,242.31,0,253.58v194.261c0,35.346,28.654,64,64,64h85.333v-128c0.399-58.172,47.366-105.676,104.073-107.044    C312.01,275.383,362.22,323.696,362.667,383.841z"/>
		<path d="M256,319.841c-35.346,0-64,28.654-64,64v128h128v-128C320,348.495,291.346,319.841,256,319.841z"/>
	</g>
               </svg>
               <span class=" ml-3  px-3 "> خانه</span>
               
            </router-link>
         </li>
         <li>
            <router-link @click="openSidebar" to="/Dashboard/DAccountList" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 512 512">
                  <path d="M42.667,373.333H96c23.564,0,42.667,19.102,42.667,42.667v53.333C138.667,492.898,119.564,512,96,512H42.667   C19.103,512,0,492.898,0,469.333V416C0,392.436,19.103,373.333,42.667,373.333z"/>
	<path d="M493.184,380.629c-7.039-4.768-15.349-7.31-23.851-7.296H416c-23.564,0-42.667,19.102-42.667,42.667v53.333   C373.333,492.898,392.436,512,416,512h53.333C492.898,512,512,492.898,512,469.333V416   C511.998,401.815,504.946,388.559,493.184,380.629z"/>
	<path d="M42.667,186.667H96c23.564,0,42.667,19.103,42.667,42.667v53.333c0,23.564-19.103,42.667-42.667,42.667H42.667   C19.103,325.333,0,306.231,0,282.667v-53.333C0,205.769,19.103,186.667,42.667,186.667z"/>
	<path d="M493.184,193.963c-7.039-4.768-15.349-7.31-23.851-7.296H416c-23.564,0-42.667,19.103-42.667,42.667v53.333   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.103,42.667-42.667v-53.333   C511.998,215.148,504.946,201.892,493.184,193.963z"/>
	<path d="M42.667,0H96c23.564,0,42.667,19.103,42.667,42.667V96c0,23.564-19.103,42.667-42.667,42.667H42.667   C19.103,138.667,0,119.564,0,96V42.667C0,19.103,19.103,0,42.667,0z"/>
	<path d="M306.517,380.629c-7.039-4.768-15.349-7.31-23.851-7.296h-53.333c-23.564,0-42.667,19.102-42.667,42.667v53.333   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.102,42.667-42.667V416   C325.331,401.815,318.279,388.559,306.517,380.629z"/>
	<path d="M306.517,193.963c-7.039-4.768-15.349-7.31-23.851-7.296h-53.333c-23.564,0-42.667,19.103-42.667,42.667v53.333   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.103,42.667-42.667v-53.333   C325.331,215.148,318.279,201.892,306.517,193.963z"/>
	<path d="M306.517,7.296c-7.039-4.768-15.349-7.31-23.851-7.296h-53.333c-23.564,0-42.667,19.103-42.667,42.667V96   c0,23.564,19.103,42.667,42.667,42.667h53.333c23.564,0,42.667-19.103,42.667-42.667V42.667   C325.331,28.482,318.279,15.225,306.517,7.296z"/>
	<path d="M504.704,18.816C496.775,7.054,483.518,0.002,469.333,0H416c-23.564,0-42.667,19.103-42.667,42.667V96   c0,23.564,19.103,42.667,42.667,42.667h53.333C492.898,138.667,512,119.564,512,96V42.667   C512.014,34.165,509.472,25.855,504.704,18.816z"/>

               </svg>
               <span class=" ml-3  px-3 "> لیست حساب‌</span>
               
            </router-link>
         </li>
         <li>
            <router-link @click="openSidebar" to="/Dashboard/NewChallenge" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M2.751,15H0V14A7.634,7.634,0,0,1,4.065,7.169a10.975,10.975,0,0,1,3.9-1.09q-.889,1.1-1.783,2.338A40.582,40.582,0,0,0,2.751,15Zm12.832,2.813A40.582,40.582,0,0,1,9,21.249V24h1a7.634,7.634,0,0,0,6.831-4.065,10.975,10.975,0,0,0,1.09-3.9Q16.826,16.92,15.583,17.813ZM24,2.991c-.133,4.353-3.267,8.67-9.582,13.2A34.995,34.995,0,0,1,9,19.063V18.5A3.517,3.517,0,0,0,5.5,15H4.937A34.912,34.912,0,0,1,7.813,9.583C12.332,3.278,16.642.144,20.988,0,23.154,0,24,.885,24,2.991ZM18,8.5a2.5,2.5,0,0,0-5,0A2.5,2.5,0,0,0,18,8.5ZM1.374,23.785c1.126-.2,3.841-.758,4.748-1.664h0a3,3,0,0,0-4.243-4.243C.973,18.785.414,21.5.215,22.626l-.247,1.406Z"/>
               </svg>
               <span class="ml-3 px-3">چالش جدید </span>
               
            </router-link>
         </li>
         <li>
            <router-link @click="openSidebar" to="/Dashboard/Dwithdrawal" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M9,4c0-2.209,3.358-4,7.5-4s7.5,1.791,7.5,4-3.358,4-7.5,4-7.5-1.791-7.5-4Zm7.5,6c-1.027,0-2.001-.115-2.891-.315-1.359-1.019-3.586-1.685-6.109-1.685-4.142,0-7.5,1.791-7.5,4s3.358,4,7.5,4,7.5-1.791,7.5-4c0-.029-.007-.057-.008-.086h.008v2.086c0,2.209-3.358,4-7.5,4S0,16.209,0,14v2c0,2.209,3.358,4,7.5,4s7.5-1.791,7.5-4v2c0,2.209-3.358,4-7.5,4S0,20.209,0,18v2c0,2.209,3.358,4,7.5,4s7.5-1.791,7.5-4v-.08c.485,.052,.986,.08,1.5,.08,4.142,0,7.5-1.791,7.5-4v-2c0,2.119-3.092,3.849-7,3.987v-2c3.908-.138,7-1.867,7-3.987v-2c0,2.119-3.092,3.849-7,3.987v-2c3.908-.138,7-1.867,7-3.987v-2c0,2.209-3.358,4-7.5,4Z"/>
               </svg>
               <span class="ml-3 px-3">برداشت سود  </span>
            </router-link>
         </li>
         <li>
            <router-link @click="openSidebar" to="/Dashboard/Profile" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M16.043,14H7.957A4.963,4.963,0,0,0,3,18.957V24H21V18.957A4.963,4.963,0,0,0,16.043,14Z"/><circle cx="12" cy="6" r="6"/>
               </svg>
               <span class="ml-3 px-3">پروفایل </span>
            </router-link>
         </li>
         <li>
            <router-link @click="openSidebar" to="/Dashboard/Tickets" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24,11.309V24H12.018A12,12,0,1,1,24,11.246ZM13,7H7V9h6Zm4,4H7v2H17Zm0,4H7v2H17Z"/>
               </svg>
               <span class="ml-3 px-3">پشتیبانی </span>
               <span v-if="newTicket == true" class="relative justify-self-center mx-2 flex h-3 w-3 mr-auto">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75 mr-auto"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-600 mr-auto"></span>
               </span>
            </router-link>
         </li>
         <li>
            <router-link @click="openSidebar" to="/Dashboard/Referral" class="flex items-center p-2 text-gray-300 hover:bg-TFL-base1 hover:text-white rounded-lg group">
               <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-white " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                  <path d="m10,10c0,.378.271.698.644.76l3.041.507c1.342.223,2.315,1.373,2.315,2.733,0,1.654-1.346,3-3,3v2h-2v-2c-1.654,0-3-1.346-3-3h2c0,.551.448,1,1,1h2c.552,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.041-.507c-1.342-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-2h2v2c1.654,0,3,1.346,3,3h-2c0-.551-.448-1-1-1h-2c-.552,0-1,.449-1,1Zm7.519-2.105l2.188-2.188c.392.188.83.293,1.293.293,1.654,0,3-1.346,3-3s-1.346-3-3-3-3,1.346-3,3c0,.463.105.901.293,1.293l-1.986,1.986c.506.451.921.995,1.212,1.616ZM6,3c0-1.654-1.346-3-3-3S0,1.346,0,3s1.346,3,3,3c.463,0,.901-.105,1.293-.293l2.188,2.188c.291-.621.706-1.165,1.212-1.616l-1.986-1.986c.188-.392.293-.83.293-1.293Zm15,15c-.463,0-.901.105-1.293.293l-2.188-2.188c-.291.621-.706,1.165-1.212,1.616l1.986,1.986c-.188.392-.293.83-.293,1.293,0,1.654,1.346,3,3,3s3-1.346,3-3-1.346-3-3-3Zm-14.519-1.895l-2.188,2.188c-.392-.188-.83-.293-1.293-.293-1.654,0-3,1.346-3,3s1.346,3,3,3,3-1.346,3-3c0-.463-.105-.901-.293-1.293l1.986-1.986c-.506-.451-.921-.995-1.212-1.616Z"/>

               </svg>
               <span class="ml-3 px-3">رفرال </span>
            </router-link>
         </li>
         
      </ul>
   </div>
   
</template>

<script>
import axios from 'axios';
import URL from "../URL";

export default {
   data(){     
      return{
         isOpen: false,
         showMenu: false,
         fullLoad: false,
         loading: true,
         gifKey: 0,
         newTicket: false,
      }
   },
   computed: {
     gifKey(){
      return `../assets/gif/TFL-Loading.gif?${this.gifKey}`;
      
    },
   },
   mounted(){
      setTimeout(() => {
      this.loading = false;
    }, 8000);
      this.checkToken();
      this.checkTicket();
      setInterval(() => {
      this.checkTicket();
    }, 60000);
   },
   watch: {
    loading() {
      this.gifKey += 1;
    },
  },
   methods: {
      checkTicket(){
         this.newTicket = false;
         const url = URL.baseUrl + `Meta/ticket`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
            this.ticketList = response.data;
            const hasUnseenTicket = this.ticketList.some(ticket => !ticket.seenByUser);
            this.newTicket = hasUnseenTicket;
               
            })
            .catch(error => {
            this.errorMessage = error.message;
           
            
            });
        },
      
      openProfileIcon(){
         this.isOpen = !this.isOpen;
      },
      logout(){
         localStorage.removeItem('token');
         this.$router.push('/login');
      },
      checkToken(){
         const url = URL.baseUrl + `users/whoami`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         
         if(response.data.emailConfirmed == false){
            window.location.href = '/confirmEmail';

         }
         this.fullLoad = true;
          
          
        })
        .catch(error => {
          
         const currentUrl = window.location.pathname;
         window.location.href = `/login?redirect=${currentUrl}`;


        });
      },
      openSidebar(){
         this.showMenu = !this.showMenu;
      }

   }
}
</script>






<style lang="scss" scoped></style>
