<template>
  <canvas ref="chartCanvas"></canvas>
</template>

<script>
import axios from 'axios';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineController, PointElement,LineElement } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,LineController, PointElement,LineElement)


export default {
  name: 'BarChart',
  props: {
    tradeHistory: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.createChartData(this.tradeHistory);
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    createChartData(tradeHistory) {
      const xBalance = [];
      const yBalance = [];

      let cumulativeBalance = 0;
      for (let i = 0; i < tradeHistory.length; i++) {
        cumulativeBalance += tradeHistory[i].totalProfit;
        yBalance.push(cumulativeBalance);
        xBalance.push(  i);
      }

      //yBalance.reverse();
      console.log(xBalance);
      console.log(yBalance);

      this.renderChart(xBalance, yBalance);
    },
    renderChart(xBalance, yBalance) {
      const ctx = this.$refs.chartCanvas.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new ChartJS(ctx, {
        type: 'line',
        data: {
          labels: xBalance,
          datasets: [
            {
              label: 'Balance',
              data: yBalance,
              borderColor: 'rgba(110, 189, 68, 1)',
              borderWidth: 3,
              fill: false,
              tension: 0.4
            },
            
          ],
        },
        options: {
          aspectRatio: 2,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
  watch: {
    tradeHistory(newValue) {
      this.createChartData(newValue);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>