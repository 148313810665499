<template>
  <body class="flex flex-col h-screen justify-center items-center bg-gray-100">
    <div class="flex flex-col items-center">
        <h1 class="text-[120px] font-extrabold text-green-100">404</h1>
        <p class="text-2xl font-medium text-gray-600 mb-6">صفحه مورد نظر یافت نشد!</p>
        <a href="/" class="font-iranSans w-full block bg-gray-800 hover:bg-gray-600 focus:bg-gray-700 text-white text-center font-semibold text-sm rounded-lg px-4 py-3 mt-2"> بازگشت به صفحه اصلی</a>
    </div>
</body>
</template>

