<template>
   <div class="grid grid-cols-12 ">
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>لیست یوزر </p>
    </div>
    <div class="col-span-12 grid grid-cols-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <div class="col-span-12 lg:col-span-1 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             اندازه 
          </label>
          <div class="relative mt-3 text-right">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
              <select v-model="PageSize" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                  <option value=10>10</option>
                  <option value=20>20</option>
                  <option value=30>30</option>
                  <option value=40>40</option>
                  <option value=50>50</option>
              </select>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             وضعیت آپلود مدارک 
          </label>
          <div class="relative mt-3 text-right">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
              <select v-model="VerificationUploaded" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                  <option value=True>بله</option>
                  <option value=False>خیر</option>
                  <option value=''>خالی</option>
                  
              </select>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             وضعیت تائید 
          </label>
          <div class="relative mt-3 text-right">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
              <select v-model="IsConfirmed" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                  <option value=True>بله</option>
                  <option value=False>خیر</option>
                  <option value=''>خالی</option>
                  
              </select>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-2 m-3">
          <label class="block text-indigo-900 text-right font-iranSans" for="grid-state">
             وضعیت احراز 
          </label>
          <div class="relative mt-3 text-right">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-700">
                  <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
              <select v-model="IsVerified" class="block text-right appearance-none w-full bg-gray-200 border-2 border-green-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="grid-state">
                  <option value=True>بله</option>
                  <option value=False>خیر</option>
                  <option value=''>خالی</option>
                  
              </select>
          </div>
      </div>
      <div class="col-span-12 lg:col-span-4 m-3">
           <label class="block text-indigo-900  text-right font-iranSans" for="grid-state">
               اسم  
           </label>
           <div class="grid grid-cols-12 mt-3" >
              <input  v-model="Name"  class="col-span-12 font-iranSans text-xs w-full py-3 px-4 pr-8 rounded text-right rounded-lg bg-gray-200  border-2 focus:border-green-100 focus:bg-white focus:outline-none" autofocus autocomplete required />
               
           </div>
      </div>
      <div class="col-span-12 lg:col-span-1 m-3 mt-10">
        <button @click.prevent="fetchUserList" class="font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              فیلتر
                             
                         </button>
      </div>

      <div class="col-span-12 overflow-auto  mt-10 ">
        <table class="w-full overflow-auto  text-xs xl:text-xs text-right text-gray-500 round-10 table-auto">
            <thead  class="text-xs text-gray-700 uppercase bg-gray-50 round-10 ">
              <tr>
                 <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                 <th scope="col" class="px-6 py-3 font-iranSans">username</th> 
                 <th scope="col" class="px-6 py-3 font-iranSans">email</th> 
                 
              </tr>
            </thead> 
            <tbody  class="bg-white border-b  ">
              <tr v-for="(user, index) in userList" :key="index" class="text-gray-900  hover:bg-gray-200">
                <td class="px-6 py-2">{{ index }}</td>
                <td class="px-6 py-2">{{ user.userName }}</td>
                <td class="px-6 py-2">{{ user.email }}</td>
                <td class="px-6 py-2"><router-link :to="{params: {id:user.uId }, name:'DAdminUserDetails' }"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-2">
                              اطلاعات یوزر
                             
        </router-link></td>
                
              </tr>
            </tbody>
          </table>

      </div>
      <div class="col-span-12  m-3 mt-10 grid grid-cols-9">
        <button @click.prevent="nextPage" class="md:col-span-1 md:col-start-4 col-span-3 font-iranSans  hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              بعدی
                             
        </button>
        <div class=" md:col-span-1 md:col-start-5 col-span-3 font-iranSans  duration-300 text-center w-full block border-2 border-green-100 bg-gray-100 focus:bg-green-100 text-indigo-900 text-indigo-900 font-semibold rounded-lg px-4 py-2 mt-2">
                              {{ this.Page }}
                             
        </div>
        <button v-if="this.Page > 1" @click.prevent="prePage" class=" md:col-span-1 md:col-start-6 col-span-3  font-iranSans hover:scale-105 duration-300 text-center w-full block border-2 border-green-100 bg-green-100 focus:bg-green-100 text-indigo-900 text-white font-semibold rounded-lg px-4 py-2 mt-2">
                              قبلی
                             
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import URL from "../URL";
export default {
  data(){
        return{
            Page: 1,
            PageSize: 10,
            VerificationUploaded: '',
            IsConfirmed: '',
            IsVerified: '',
            Name: '',
            userList :[],
            selectedUserUID :'',
            selectedUser :[],

        }
    },
    mounted() {
    // Fetch the accounts data from the API and populate the accounts array
    
    this.fetchUserList();
    },
    methods: {
      fetchUserList(){
        const url = URL.baseUrl + `users?Page=${this.Page}&PageSize=${this.PageSize}&VerificationUploaded=${this.VerificationUploaded}&IsConfirmed=${this.IsConfirmed}&IsVerified=${this.IsVerified}&Name=${this.Name}`;
        axios.get(url,
         
             {
             headers: {
               'Content-Type': 'application/json',
               'Authorization': 'Bearer ' + localStorage.getItem('token')
             }
           }        
         )
         .then(response => {
             // Handle successful response here
             console.log(response.data);
              this.userList = response.data;
         })
             
 
             .catch(error => {
             this.errorMessage = error.message;
             console.error("There was an error!", error);
             });
      },
      nextPage(){
        this.Page = this.Page + 1;
        this.fetchUserList();
        
      },
      prePage(){
        this.Page = this.Page - 1;
        this.fetchUserList();
      },
      // selectedUsers(index){
      //   this.selectedUserUID = this.userList[index].uId;
      //   this.selectedUser = this.userList[index];
      //   // this.$store.dispatch('updateUserID', this.userList[index].uId);
      //   this.$router.push('/AdminDashboard/UserDetails');
      // },
  }
}
</script>