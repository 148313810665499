<template>
  <div class="grid grid-cols-12 ">
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>ساخت حساب</p>
    </div>
    <!-- <AdminNewChallenge class="col-span-6"/> -->
    <div class="col-span-12 bg-surface-card border border-surface-border p-4 m-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
      <p>فیل دستی</p>
    </div>
    <AdminFailAccount class="col-span-6"/>
    
  </div>
</template>

<!-- <script>
import AdminNewChallenge from '@/components/AdminNewChallenge.vue';
import AdminFailAccount from '@/components/AdminFailAccount.vue';
export default {
  name: 'DAHome',
    components: { AdminNewChallenge,AdminFailAccount },
  
}
</script> -->