<template>
    <div class="grid grid-cols-12 m-2 ">
        <div class="col-span-12 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg"> 
            <p class=" text-TFL-dashText1">همکاری با مجموعه ترید برای زندگی  </p>
            <p class="text-sm mt-4 text-gray-300 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>برای دریافت اطلاعات و نحوه همکاری با مجموعه ترید برای زندگی با پشتیبانی تلگرام در ارتباط باشید.</p>
        </div>
        <div v-if="referrals.length == 0 && refLoaded"  class="font-iranSans col-span-12 text-sm  m-2  p-4 text-right rounded-lg bg-blue-700 text-white bg-opacity-40 " >
           کاربر گرامی، در حال حاضر برای شما پلن همکاری تعریف نشده است.
        </div>
        <div v-if="!refLoaded" class=" col-span-12  "> 
            <div class="  grid grid-cols-2 ">
                <div class="account-section bg-TFL-cardDashboard overflow-auto   p-4 m-2  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg col-span-2 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px] flex justify-between   ">
                    <div role="status" class=" animate-pulse">
    <div class="h-2.5 bg-gray-600 bg-opacity-80 rounded-full  w-48 mt-4 mb-4"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  w-full mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full  mb-2.5"></div>
    <div class="h-2 bg-gray-600 bg-opacity-80 rounded-full w-full mb-2.5"></div>
                </div>
                </div>
            </div>
        </div>
        <div v-if="refLoaded" class=" col-span-12 min-w-48 "> 
            <div class="  grid grid-cols-2 ">
                <div v-for="(referral, index) in referrals" :key="referral.id" class="account-section bg-TFL-cardDashboard overflow-auto min-w-48  p-4 m-2  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg col-span-2 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px] flex justify-between   ">
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">#</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{ formatNumber(index+1) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">کد تخفیف</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{ referral.disCountCode }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">درصد کمیسیون</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralSharePercent) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">کل مبلغ خریداری شده</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralTotalPricePayed) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">مبلغ کمیسیون</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 ">{{  formatNumber(referral.referralWithdrawAmount) }}</p>
                    </div>
                    <div class=" m-2">
                        <p class="text-TFL-dashText1 font-iranSans text-sm ">وضعیت </p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 " v-if="referral.referralWithdrawAmount == 0 && referral.referralTotalPricePayed != 0">تسویه شده</p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 " v-if="referral.referralWithdrawAmount == 0 && referral.referralTotalPricePayed == 0"> - </p>
                        <p class="text-gray-300 font-iranSans text-sm mt-2 " v-if="referral.referralWithdrawAmount != 0 && referral.referralTotalPricePayed != 0"> در انتظار تسویه </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import URL from "../URL";
export default {
    data(){
        return{
            referrals:[],
            refLoaded: false
            
        }
    },
    computed: {
     
  },
    mounted(){
       this.fetchReferral();
    },
    methods: {
        formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },

    fetchReferral(){
            const url = URL.baseUrl + 'Meta/referral';
            axios.get(url, {
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => {
        if(response.data){

            this.referrals = response.data;
            
        }
        // Add the show variable to each account object
        this.refLoaded = true
    })
    .catch(error => {
        this.refLoaded = true 
    });

        },
        
    },

}
</script>